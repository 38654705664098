import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTable } from "../../../../../../slices/docsSlice";
import Input from "../../../../../../utils/Input";

const AddParaInTable = ({ SLI }) => {
  const dispatch = useDispatch();
  const { RRIP_S, RRIP_DOC } = useSelector((state) => state.docs);
  const [paraName, setParaName] = useState();
  return (
    <div className="flex flex-wrap gap-4 pb-4">
      <Input
        className="h-[40px] w-[250px]"
        value={paraName}
        change={(e) => setParaName(e.target.value)}
        placeholder="Enter Paragraph Name"
      />
      <button
        className="px-3 py-1 bg-cyan-950 text-light-500"
        onClick={() => {
          dispatch(
            addTable({
              special: true,
              doc: RRIP_DOC,
              path: RRIP_S,
              name: paraName,
              rows: 1,
              cols: 1,
            })
          );
        }}
      >
        Add Para in Cell
      </button>
      <button
        className="px-3 py-1 bg-cyan-950 text-light-500"
        onClick={() => {
          dispatch(
            addTable({
              special: true,
              doc: RRIP_DOC,
              path: RRIP_S,
              loc: "after",
              index: parseFloat(SLI[0]),
              name: paraName,
              rows: 1,
              cols: 1,
            })
          );
        }}
      >
        After
      </button>
      <button
        className="px-3 py-1 bg-cyan-950 text-light-500"
        onClick={() => {
          dispatch(
            addTable({
              special: true,
              doc: RRIP_DOC,
              path: RRIP_S,
              loc: "before",
              index: parseFloat(SLI[0]),
              name: paraName,
              rows: 1,
              cols: 1,
            })
          );
        }}
      >
        Before
      </button>
    </div>
  );
};

export default AddParaInTable;
