import React, { useEffect, useState } from "react";
import { useGetUserEntryFormsQuery } from "../../slices/entryFormApiSlice";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import Table from "../../utils/Table/Table";
import TableRow from "../../utils/Table/TableRow";
import TableCell from "../../utils/Table/TableCell";
import Loader from "../../components/Loader";

import { getForms } from "../../slices/formsSlice";
import { getListIDS } from "../../slices/internalDependencySlice";
import { useGetSingleAssignedDocMutation } from "../../slices/documentsApiSlice";
import { setDocument } from "../../slices/documentSlice";
import { useNavigate } from "react-router-dom";
import { setCategory, setName } from "../../slices/docsSlice";
import { setEFandFilledEF } from "../../slices/entryForm";
import { useParams } from "react-router-dom";
import Pagination from "../../utils/Table/Pagination";

const UserEntryForms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageNumber } = useParams();
  const { document } = useSelector((state) => state.document);
  const { forms, userForms } = useSelector((state) => state.forms);
  const {
    data: userEntryFormsData,
    isLoading: userEntryFormsLoading,
    refetch,
  } = useGetUserEntryFormsQuery({
    pageNumber: pageNumber || 1,
  });

  const [getSingleAssignedDoc, { isLoading: mainFileLoading }] =
    useGetSingleAssignedDocMutation();

  const getMainFile = async (docName, category) => {
    try {
      const res = await getSingleAssignedDoc({ docName, category }).unwrap();
      dispatch(setDocument(res));
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (document && forms && userForms) {
      navigate(`/Forms/EF/${document.category}$${document.name}_EF`);
    }
  }, [document, forms, userForms]);

  return (
    <div className="psm">
      <h2 className="font-mono pb-2">Records</h2>
      {userEntryFormsLoading ? (
        <Loader />
      ) : (
        <>
          <Table>
            <TableRow isHeader={true}>
              <TableCell isHeader={true}>S.No</TableCell>
              <TableCell isHeader={true}>Name</TableCell>
              <TableCell isHeader={true}>Category</TableCell>
              <TableCell isHeader={true}>Status</TableCell>
              <TableCell isHeader={true}>For</TableCell>
              <TableCell isHeader={true}>Select</TableCell>
            </TableRow>
            {userEntryFormsData.entryForms?.map((form, sno) => {
              return (
                <TableRow
                  key={sno}
                  className={(sno + 1) % 2 === 0 ? "bg-gray-100" : null}
                >
                  <TableCell>{sno + 1}</TableCell>
                  <TableCell>{form.docName}</TableCell>
                  <TableCell>{form.category}</TableCell>
                  <TableCell>{form.status}</TableCell>
                  <TableCell>{form.forName}</TableCell>
                  <TableCell
                    style={{
                      padding: "0px",
                      cursor: "pointer",
                      backgroundColor: "#083344",
                      color: "#f8f9fa",
                      textAlign: "center",
                    }}
                    click={() => {
                      console.log(form);
                      dispatch(
                        getForms({
                          userForms: [...form.userForms, { name: "Submit" }],
                          forms: form.forms,
                        })
                      );
                      dispatch(setCategory(form.category));
                      dispatch(setName(form.docName));
                      dispatch(getListIDS(form.listIDS));
                      dispatch(
                        setEFandFilledEF({
                          entryForm:
                            form.forms[`${form.category}$${form.docName}`],
                          filledEF:
                            form.forms[`${form.category}$${form.docName}_EF`],
                        })
                      );
                      getMainFile(form.docName, form.category);
                    }}
                  >
                    Select
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
          {userEntryFormsData ? (
            <Pagination
              currentPage={userEntryFormsData.page}
              totalPages={userEntryFormsData.pages}
              onPageChange={(value) => {
                navigate(`/Records/${value}`);
              }}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default UserEntryForms;
