import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../../utils/Input";
import { addTable } from "../../../../../../slices/docsSlice";

const AddTable = ({ SLI }) => {
  const dispatch = useDispatch();
  const { docs, RRIP_S } = useSelector((state) => state.docs);

  const [tableName, setTableName] = useState();
  const [rows, setRows] = useState();
  const [cols, setCols] = useState();

  return (
    <div className="flex flex-wrap gap-4">
      <div className="flex flex-wrap gap-4">
        <Input
          className="h-[40px] w-[80px]"
          value={rows}
          change={(e) => setRows(e.target.value)}
          placeholder="Rows"
          type="number"
        />
        <Input
          className="h-[40px] w-[80px]"
          value={cols}
          change={(e) => setCols(e.target.value)}
          placeholder="Cols"
          type="number"
        />
      </div>
      <div className="flex flex-wrap gap-4">
        <Input
          className="h-[40px] w-[250px]"
          value={tableName}
          change={(e) => setTableName(e.target.value)}
          placeholder="Enter Table Name"
        />

        <button
          className="px-3 py-1 bg-cyan-950 text-light-500"
          onClick={() => {
            if (tableName !== undefined && docs !== undefined) {
              dispatch(
                addTable({
                  path: RRIP_S,
                  name: tableName,
                  rows: parseFloat(rows),
                  cols: parseFloat(cols),
                })
              );
              setRows("");
              setCols("");
              setTableName("");
            } else {
              toast.error("Table Name Cannot be empty");
            }
          }}
        >
          Add Table
        </button>
        <button
          className="px-3 py-1 bg-cyan-950 text-light-500"
          onClick={() => {
            if (tableName !== undefined && docs !== undefined) {
              dispatch(
                addTable({
                  path: RRIP_S,
                  loc: "after",
                  index: parseFloat(SLI[0]),
                  name: tableName,
                  rows: parseFloat(rows),
                  cols: parseFloat(cols),
                })
              );
              setRows("");
              setCols("");
              setTableName("");
            } else {
              toast.error("Table Name Cannot be empty");
            }
          }}
        >
          After
        </button>
        <button
          className="px-3 py-1 bg-cyan-950 text-light-500"
          onClick={() => {
            if (tableName !== undefined && docs !== undefined) {
              dispatch(
                addTable({
                  path: RRIP_S,
                  loc: "before",
                  index: parseFloat(SLI[0]),
                  name: tableName,
                  rows: parseFloat(rows),
                  cols: parseFloat(cols),
                })
              );
              setRows("");
              setCols("");
              setTableName("");
            } else {
              toast.error("Table Name Cannot be empty");
            }
          }}
        >
          Before
        </button>
      </div>
    </div>
  );
};

export default AddTable;
