import { returnEle, returnHFEle, returnEI, consoleFun } from "./functions";

const essentials = {
  addDoc: (state, { payload }) => {
    state.docs.push({
      XTS: [],
      ele: [],
      properties: {
        creator: payload.value,
        numbering: {
          config: payload.numbering,
        },
        sec: {},
        table: {
          width: {
            size: "100%",
          },
          margins: {
            left: "0.08in",
            right: "0.08in",
            top: "0.05in",
            bottom: "0.05in",
          },
          borders: {
            left: {
              style: "single",
              color: "000000",
              size: 3,
              space: 0,
            },
            right: {
              style: "single",
              color: "000000",
              size: 3,
              space: 0,
            },
            top: {
              style: "single",
              color: "000000",
              size: 3,
              space: 0,
            },
            bottom: {
              style: "single",
              color: "000000",
              size: 3,
              space: 0,
            },
          },
        },
        row: {},
        cell: {
          verticalAlign: "center",
          borders: {
            left: {
              style: "single",
              color: "000000",
              size: 3,
              space: 0,
            },
            right: {
              style: "single",
              color: "000000",
              size: 3,
              space: 0,
            },
            top: {
              style: "single",
              color: "000000",
              size: 3,
              space: 0,
            },
            bottom: {
              style: "single",
              color: "000000",
              size: 3,
              space: 0,
            },
          },
        },
        para: {
          alignment: "left",
          spacing: {
            before: "0pt",
            after: "8pt",
          },
        },
        text: {
          font: "Trebuchet MS",
          fontSize: "9.5pt",
        },
      },
      name: payload.name,
      type: "doc",
      F: {},
    });
  },
  pushDoc: (state, { payload }) => {
    state.docs.push(payload);
  },
  removeDoc: (state, { payload }) => {
    const splitIndexPath = payload[0].split("_");
    state.docs.splice(parseFloat(splitIndexPath[0]), 1);
    state.indexPath = "";
  },

  setIndexPath: (state, { payload }) => {
    state.indexPath = payload;
    if (payload) {
      const splitIndexPath = payload.split("/");
      state.SIP = splitIndexPath;

      let RIP = [...splitIndexPath].reverse();
      let RIP_I = [...splitIndexPath].reverse();
      let RIP_CB = [...splitIndexPath].reverse();
      let RIP_SY = [...splitIndexPath].reverse();
      let RIP_T = [...splitIndexPath].reverse();
      let RIP_P = [...splitIndexPath].reverse();
      let RIP_TC = [...splitIndexPath].reverse();
      let RIP_TR = [...splitIndexPath].reverse();
      let RIP_TB = [...splitIndexPath].reverse();
      let RIP_H = [...splitIndexPath].reverse();
      let RIP_F = [...splitIndexPath].reverse();
      let RIP_S = [...splitIndexPath].reverse();
      let RIP_DOC = [...splitIndexPath].reverse();

      let LII = RIP_I.findIndex((index) => index.split("_")[1] === "I");
      let LICB = RIP_SY.findIndex((index) => index.split("_")[1] === "CB");
      let LISY = RIP_SY.findIndex((index) => index.split("_")[1] === "SY");
      let LIT = RIP_T.findIndex((index) => index.split("_")[1] === "T");
      let LIP = RIP_P.findIndex((index) => index.split("_")[1] === "P");
      let LITC = RIP_TC.findIndex((index) => index.split("_")[1] === "TC");
      let LITR = RIP_TR.findIndex((index) => index.split("_")[1] === "TR");
      let LITB = RIP_TB.findIndex((index) => index.split("_")[1] === "TB");
      let LIH = RIP_H.findIndex((index) => index.split("_")[1] === "H");
      let LIF = RIP_F.findIndex((index) => index.split("_")[1] === "F");
      let LIS = RIP_S.findIndex((index) => index.split("_")[1] === "S");
      let LIDOC = RIP_DOC.findIndex((index) => index.split("_")[1] === "Doc");

      RIP_I.splice(0, LII);
      RIP_CB.splice(0, LICB);
      RIP_SY.splice(0, LISY);
      RIP_T.splice(0, LIT);
      RIP_P.splice(0, LIP);
      RIP_TC.splice(0, LITC);
      RIP_TR.splice(0, LITR);
      RIP_TB.splice(0, LITB);
      RIP_H.splice(0, LIH);
      RIP_F.splice(0, LIF);
      RIP_S.splice(0, LIS);
      RIP_DOC.splice(0, LIDOC);

      const RRIP = [...RIP].reverse();
      const RRIP_I = [...RIP_I].reverse();
      const RRIP_CB = [...RIP_CB].reverse();
      const RRIP_SY = [...RIP_SY].reverse();
      const RRIP_T = [...RIP_T].reverse();
      const RRIP_P = [...RIP_P].reverse();
      const RRIP_TC = [...RIP_TC].reverse();
      const RRIP_TR = [...RIP_TR].reverse();
      const RRIP_TB = [...RIP_TB].reverse();
      const RRIP_H = [...RIP_H].reverse();
      const RRIP_F = [...RIP_F].reverse();
      const RRIP_S = [...RIP_S].reverse();
      const RRIP_DOC = [...RIP_DOC].reverse();

      state.NIP = RRIP.map((index) => index.split("_")[0]).join("_");
      state.NIP_I = RRIP_I.map((index) => index.split("_")[0]).join("_");
      state.NIP_CB = RRIP_CB.map((index) => index.split("_")[0]).join("_");
      state.NIP_SY = RRIP_SY.map((index) => index.split("_")[0]).join("_");
      state.NIP_T = RRIP_T.map((index) => index.split("_")[0]).join("_");
      state.NIP_P = RRIP_P.map((index) => index.split("_")[0]).join("_");
      state.NIP_TC = RRIP_TC.map((index) => index.split("_")[0]).join("_");
      state.NIP_TR = RRIP_TR.map((index) => index.split("_")[0]).join("_");
      state.NIP_TB = RRIP_TB.map((index) => index.split("_")[0]).join("_");
      state.NIP_H = RRIP_H.map((index) => index.split("_")[0]).join("_");
      state.NIP_F = RRIP_F.map((index) => index.split("_")[0]).join("_");
      state.NIP_S = RRIP_S.map((index) => index.split("_")[0]).join("_");
      state.NIP_DOC = RRIP_DOC.map((index) => index.split("_")[0]).join("_");

      state.RRIP = RRIP;
      state.RRIP_I = LII !== -1 ? RRIP_I : undefined;
      state.RRIP_CB = LICB !== -1 ? RRIP_CB : undefined;
      state.RRIP_SY = LISY !== -1 ? RRIP_SY : undefined;
      state.RRIP_T = LIT !== -1 ? RRIP_T : undefined;
      state.RRIP_P = LIP !== -1 ? RRIP_P : undefined;
      state.RRIP_TC = LITC !== -1 ? RRIP_TC : undefined;
      state.RRIP_TR = LITR !== -1 ? RRIP_TR : undefined;
      state.RRIP_TB = LITB !== -1 ? RRIP_TB : undefined;
      state.RRIP_H = LIH !== -1 ? RRIP_H : undefined;
      state.RRIP_F = LIF !== -1 ? RRIP_F : undefined;
      state.RRIP_S = LIS !== -1 ? RRIP_S : undefined;
      state.RRIP_DOC = LIDOC !== -1 ? RRIP_DOC : undefined;
    }
  },
  resetDocs: (state, { payload }) => {
    state.docId = undefined;
    state.docs = undefined;
    state.entryForm = {};
    state.forName = "";
    state.name = "";
    state.category = "";
    state.runStatus = false;
    state.indexPath = "";
    state.dev = false;
    state.minimal = true;
    state.SIP = undefined;
    state.NIP_I = undefined;
    state.NIP_CB = undefined;
    state.NIP_SY = undefined;
    state.NIP_T = undefined;
    state.NIP_P = undefined;
    state.NIP_TC = undefined;
    state.NIP_TR = undefined;
    state.NIP_TB = undefined;
    state.NIP_H = undefined;
    state.NIP_F = undefined;
    state.NIP_S = undefined;
    state.NIP_DOC = undefined;
    state.NIP = undefined;
    state.RRIP_I = undefined;
    state.RRIP_CB = undefined;
    state.RRIP_SY = undefined;
    state.RRIP_T = undefined;
    state.RRIP_P = undefined;
    state.RRIP_TC = undefined;
    state.RRIP_TR = undefined;
    state.RRIP_TB = undefined;
    state.RRIP_H = undefined;
    state.RRIP_F = undefined;
    state.RRIP_S = undefined;
    state.RRIP_DOC = undefined;
    state.RRIP = undefined;
  },

  removeEle: (state, { payload }) => {
    const splitIndexPath = payload;
    let newIndexPath = splitIndexPath
      .map((index) => index.split("_")[0])
      .join("_");

    let conditionMet = false;

    const setProperty = (ele, currIndex, currPath = "", parentEle) => {
      const newPath =
        currPath !== "" ? `${currPath}_${currIndex}` : `${currIndex}`;
      if (!conditionMet) {
        if (newPath === newIndexPath) {
          let indexArr = newIndexPath.split("_");
          let eleIndex = indexArr[indexArr.length - 1];
          // state.indexPath = "";
          parentEle.ele.splice(eleIndex, 1);
          conditionMet = true;
        } else if (ele.ele?.length > 0) {
          ele.ele.forEach((E, i) => setProperty(E, i, newPath, ele));
        }
      }
    };

    state.docs.forEach((doc, i) => setProperty(doc, i));
    let newRRIP = payload.slice(0, -1);
    state.indexPath = newRRIP.join("/");
  },
  // Common Function
  updateShading: (state, { payload }) => {
    let ele;
    if (payload.layoutEle) {
      ele = returnHFEle(state, payload.path, payload.layoutEle);
    } else {
      ele = returnEle(state, payload.path);
    }

    if (ele.properties.shading) {
      if (payload.loc === "color" || payload.loc === "fill") {
        ele.properties.shading[payload.loc] = payload.value
          .replace("#", "")
          .toUpperCase();
      } else {
        ele.properties.shading[payload.loc] = payload.value;
      }
    } else {
      switch (payload.loc) {
        case "color":
          ele.properties.shading = {
            color: payload.value.replace("#", "").toUpperCase(),
            fill: "FFFFFF",
            type: "solid",
          };
          break;
        case "fill":
          ele.properties.shading = {
            color: "000000",
            fill: payload.value.replace("#", "").toUpperCase(),
            type: "solid",
          };
          break;
        case "type":
          ele.properties.shading = {
            color: "000000",
            fill: "FFFFFF",
            type: payload.value,
          };
          break;
      }
    }
  },
};

export default essentials;
