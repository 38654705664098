import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTableWidth } from "../../../../../../slices/docsSlice";
import Input from "../../../../../../utils/Input";

const WidthSize = ({ widthSize, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TB, RRIP_DOC } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">Set Width Size</h2>
      <Input
        className="h-[40px] w-[150px]"
        value={widthSize}
        type="text"
        blur={(e) => {
          dispatch(
            setTableWidth({
              loc: "size",
              table: RRIP_TB,
              doc: RRIP_DOC,
              value: e.target.value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
        change={(e) => setWidthSize(e.target.value)}
      />
    </div>
  );
};

export default WidthSize;
