import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../utils/Input";
import { toast } from "react-toastify";
import {
  setDocs,
  addDoc,
  pushDoc,
  addPara,
  addTable,
  setIndexPath,
} from "../../../../slices/docsSlice";
import {
  SECTION,
  TABLE,
  ROW,
  CELL,
  PARA,
  TEXT,
} from "../../../../slices/Docs/elements";
import { useGetNumberingQuery } from "../../../../slices/numberingApiSlice";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import { DOMParser } from "@xmldom/xmldom";
import { v4 as uuidv4 } from "uuid";
import { cloneDeepWith } from "lodash";
import mammoth from "mammoth";
// import { JSDOM } from "jsdom";

const DocumentControls = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  // const { productInfo } = useSelector((state) => state.product);
  const { docs } = useSelector((state) => state.docs);
  const { agency } = useSelector((state) => state.agency);
  const [docName, setDocName] = useState();
  const [paraName, setParaName] = useState();
  const [tableName, setTableName] = useState();
  const [rows, setRows] = useState();
  const [cols, setCols] = useState();

  const { data: numberingTypes, refetch, isLoading } = useGetNumberingQuery();

  let DOC = {
    XTS: [],
    ele: [],
    properties: {
      creator: agency,
      numbering: {
        config: numberingTypes,
      },
      sec: {},
      table: {
        width: {
          size: "100%",
        },
        margins: {
          left: "0.08in",
          right: "0.08in",
          top: "0.05in",
          bottom: "0.05in",
        },
        borders: {
          left: {
            style: "single",
            color: "000000",
            size: 3,
            space: 0,
          },
          right: {
            style: "single",
            color: "000000",
            size: 3,
            space: 0,
          },
          top: {
            style: "single",
            color: "000000",
            size: 3,
            space: 0,
          },
          bottom: {
            style: "single",
            color: "000000",
            size: 3,
            space: 0,
          },
        },
      },
      row: {},
      cell: {
        verticalAlign: "center",
        borders: {
          left: {
            style: "single",
            color: "000000",
            size: 3,
            space: 0,
          },
          right: {
            style: "single",
            color: "000000",
            size: 3,
            space: 0,
          },
          top: {
            style: "single",
            color: "000000",
            size: 3,
            space: 0,
          },
          bottom: {
            style: "single",
            color: "000000",
            size: 3,
            space: 0,
          },
        },
      },
      para: {
        alignment: "left",
        spacing: {
          before: "0pt",
          after: "8pt",
        },
      },
      text: {
        font: "Trebuchet MS",
        fontSize: "9.5pt",
      },
    },
    name: "Document",
    type: "doc",
  };

  const handleFileUpload = async (event, magic) => {
    // console.log(magic);
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      // Mammoth code
      const arrayBuffer = await file.arrayBuffer();
      const { value: html } = await mammoth.convertToHtml({
        arrayBuffer,
      });
      const htmlparser = new window.DOMParser();
      const htmldoc = htmlparser.parseFromString(html, "text/html");
      // console.log(htmldoc);

      const content = e.target.result;
      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip);
      const xmlContent = zip.files["word/document.xml"].asText();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlContent, "application/xml");

      let newDoc = structuredClone(DOC);
      let newSec = structuredClone(SECTION);

      const setTableWidth = (payload) => {
        if (payload.tableProps.width) {
          payload.tableProps.width[payload.loc] = payload.value;
        } else {
          payload.tableProps.width = {
            [payload.loc]: payload.value,
          };
        }
      };

      const setParaSpacing = (payload) => {
        if (payload.paraProps.spacing) {
          payload.paraProps.spacing[payload.loc] = payload.value;
        } else {
          payload.paraProps.spacing = {
            [payload.loc]: payload.value,
          };
        }
      };

      const createText = (element) => {
        return element.childNodes[0].data;
      };

      const createRun = ({ text, para, cell, row, table }) => {
        let newText = structuredClone(TEXT);
        let runChildren = text.childNodes;
        let child = Object.keys(runChildren)
          .map((E) => {
            if (runChildren[E].localName === "t") {
              return createText(runChildren[E]);
            } else if (runChildren[E].localName === "r") {
              return createRun({
                text: runChildren[E],
                para,
                cell,
                row,
                table,
              });
            }
            return null;
          })
          .filter(Boolean);

        newText.t = child[0];

        let attributes = text.childNodes[0].attributes._ownerElement.childNodes;
        Object.keys(attributes).map((attr) => {
          switch (attributes[attr].nodeName) {
            case "w:rFonts":
              newText.properties.font =
                attributes[attr].attributes[0].nodeValue;
              break;
            case "w:b":
              newText.properties.bold = true;
              break;
            // case "w:sz":
            //   if (attributes[attr].attributes[0].nodeValue > "10") {
            //     newText.properties.fontSize = `${
            //       parseFloat(attributes[attr].attributes[0].nodeValue) / 2
            //     }pt`;
            //   } else {
            //     newText.properties.fontSize = `${attributes[attr].attributes[0].nodeValue}pt`;
            //   }
            //   break;
          }
        });
        if (
          runChildren["0"].localName === "rPr" ||
          runChildren["0"].localName === "t"
        ) {
          if (typeof newText.t === "object") {
            newText = newText.t;
          }
          return newText;
        }
      };

      const createPara = ({ para, cell, row, table, mainEle }) => {
        let newPara = structuredClone(PARA);
        // if (mainEle) {
        //   newPara.user = {};
        //   newPara.manager = {};
        // }
        let paraChildren = para.childNodes;
        const child = Object.keys(paraChildren)
          .map((E) => {
            if (paraChildren[E].localName === "r") {
              return createRun({
                text: paraChildren[E],
                para,
                cell,
                row,
                table,
              });
            }
            return null;
          })
          .filter(Boolean);
        newPara.ele = child;
        let attributes =
          para?.childNodes[0]?.attributes?._ownerElement?.childNodes;
        attributes &&
          Object.keys(attributes)?.map((attr) => {
            switch (attributes[attr].nodeName) {
              case "w:pStyle":
                newPara.properties.style =
                  attributes[attr].attributes[0].nodeValue;
                break;
              case "w:spacing":
                Object.keys(attributes[attr].attributes).map((att) => {
                  switch (attributes[attr].attributes[att].nodeName) {
                    case "w:before":
                      setParaSpacing({
                        loc: "before",
                        paraProps: newPara.properties,
                        value: `${
                          parseFloat(
                            attributes[attr].attributes[att].nodeValue
                          ) / 20
                        }pt`,
                      });
                      break;
                    case "w:after":
                      setParaSpacing({
                        loc: "after",
                        paraProps: newPara.properties,
                        value: `${
                          parseFloat(
                            attributes[attr].attributes[att].nodeValue
                          ) / 20
                        }pt`,
                      });
                      break;
                    case "w:line":
                      setParaSpacing({
                        loc: "line",
                        paraProps: newPara.properties,
                        value: `${
                          parseFloat(
                            attributes[attr].attributes[att].nodeValue
                          ) / 20
                        }pt`,
                      });
                      break;
                    case "w:lineRule":
                      setParaSpacing({
                        loc: "lineRule",
                        paraProps: newPara.properties,
                        value: attributes[attr].attributes[att].nodeValue,
                      });
                      break;
                  }
                });

                break;
              case "w:jc":
                newPara.properties.alignment =
                  attributes[attr].attributes[0].nodeValue;
                break;
            }
          });
        return newPara;
      };

      const createCell = ({ cell, bodyCell, CI, row, RI, table }) => {
        if (!bodyCell || !bodyCell.childNodes) {
          // console.error("bodyCell is undefined or does not have childNodes", {
          //   bodyCell,
          // });
          return null; // or handle the error appropriately
        }

        let xyz = Array.from(bodyCell.childNodes);
        let newCell = structuredClone(CELL);
        let CHILDREN = [];
        let cellChildren = Object.keys(cell.childNodes).forEach((C, index) => {
          let arr;
          if (cell.childNodes[C].childNodes) {
            arr = Object.keys(cell.childNodes[C].childNodes);
            arr = arr.slice(0, -1);
          }
          if (arr !== undefined) {
            if (cell.childNodes[C].nodeName === "w:p" && arr.length > 1) {
              CHILDREN.push(cell.childNodes[C]);
            } else if (cell.childNodes[C].nodeName === "w:tbl") {
              CHILDREN.push(cell.childNodes[C]);
            }
          }
        });

        // console.log(CHILDREN, xyz);

        const child = CHILDREN.map((E, i) => {
          // console.log(i, xyz[i]);
          if (E.localName === "tbl") {
            // console.log(i, xyz[i]);
            return createTable({
              table: E,
              bodyTable: xyz[i],
            });
          } else if (E.localName === "p") {
            return createPara({ para: E, cell, row, table });
          }
          return null;
        }).filter(Boolean);
        let attributes = cell.childNodes[0].attributes._ownerElement.childNodes;
        Object.keys(attributes).map((attr) => {
          switch (attributes[attr].nodeName) {
            case "w:gridSpan":
              Object.keys(attributes[attr].attributes).map((att) => {
                if (attributes[attr].attributes[att].nodeName === "w:val") {
                  newCell.properties.columnSpan = parseFloat(
                    attributes[attr].attributes[att].nodeValue
                  );
                }
              });
              break;
            case "w:vMerge":
              let rowspan;
              if (attributes[attr]?.attributes[0]?.nodeValue === "restart") {
                // let maxCols = calculateNoOfColumnsInTable(table);
                // rowspan = calculateRowSpan({ RI, CI, row, table, maxCols });
                let bodyCellRS = bodyCell?.getAttribute("rowspan");
                rowspan = parseFloat(bodyCellRS) || 1;
              }
              Object.keys(attributes[attr].attributes).map((att) => {
                if (attributes[attr].attributes[att].nodeName === "w:val") {
                  newCell.properties.rowSpan = rowspan;
                }
              });
              break;
          }
        });
        newCell.ele = child;
        return newCell;
      };

      const createRow = ({ row, bodyRow, RI, table }) => {
        let newRow = structuredClone(ROW);
        let rowChildren = row.childNodes;
        let cells = Object.keys(rowChildren)
          .map((cell) => {
            let E = row.childNodes[cell];
            let isMergedCell;
            if (E.childNodes) {
              isMergedCell = Object.keys(E.childNodes)
                .map((cellIndex) => {
                  if (E.childNodes[cellIndex].nodeName === "w:tcPr") {
                    return Object.keys(E.childNodes[cellIndex].childNodes)
                      .map((attrIndex) => {
                        if (
                          E.childNodes[cellIndex].childNodes[attrIndex]
                            .nodeName === "w:vMerge"
                        ) {
                          if (
                            E.childNodes[cellIndex]?.childNodes[attrIndex]
                              ?.attributes[0]?.nodeValue === "continue"
                          ) {
                            return "mergedCell";
                          }
                        }
                        return null;
                      })
                      .filter(Boolean);
                  }
                })
                .filter(Boolean);
            }

            // console.log(isMergedCell);
            if (
              row.childNodes[cell].nodeName === "w:tc"
              // &&
              // isMergedCell[0][0] !== "mergedCell"
            ) {
              if (
                isMergedCell !== undefined &&
                isMergedCell[0][0] !== "mergedCell"
              ) {
                return row.childNodes[cell];
              }
            }
            return null;
          })
          .filter(Boolean);
        // console.log(bodyRow, row);
        let bodycells;
        if (bodyRow) {
          bodycells = Array.from(bodyRow.childNodes);
        }
        // console.log(bodycells[0]);
        // console.log(cells);
        const child = cells
          .map((E, i) => {
            // console.log(E, bodycells[i]);
            let isMergedCell = Object.keys(E.childNodes)
              .map((cellIndex) => {
                if (E.childNodes[cellIndex].nodeName === "w:tcPr") {
                  return Object.keys(E.childNodes[cellIndex].childNodes)
                    .map((attrIndex) => {
                      if (
                        E.childNodes[cellIndex].childNodes[attrIndex]
                          .nodeName === "w:vMerge"
                      ) {
                        if (
                          E.childNodes[cellIndex]?.childNodes[attrIndex]
                            ?.attributes[0]?.nodeValue === "continue"
                        ) {
                          return "mergedCell";
                        }
                      }
                      return null;
                    })
                    .filter(Boolean);
                }
              })
              .filter(Boolean);
            if (
              E.localName === "tc" &&
              isMergedCell[0][0] !== "mergedCell" &&
              bodycells?.length > 0
            ) {
              return createCell({
                cell: E,
                bodyCell: bodycells[i],
                CI: i,
                row,
                RI,
                table,
              });
            }
            return null;
          })
          .filter(Boolean);
        newRow.ele = child;
        return newRow;
      };

      const createTable = ({ table, nextEle, bodyTable, mainEle }) => {
        let newTable = structuredClone(TABLE);
        // if (magic) {
        //   newTable.properties.margins = {
        //     left: "0in",
        //     right: "0in",
        //     top: "0.05in",
        //     bottom: "0in",
        //   };
        // }
        // if (mainEle) {
        //   newTable.user = {};
        //   newTable.manager = {};
        // }
        let tableChildren = table.childNodes;
        let rows = Object.keys(tableChildren)
          .map((row) => {
            if (table.childNodes[row].nodeName === "w:tr") {
              return table.childNodes[row];
            }
            return null;
          })
          .filter(Boolean);

        let bodyrows;
        if (bodyTable) {
          // console.log(bodyTable);
          bodyrows = Array.from(bodyTable?.childNodes[0]?.childNodes);
        }
        const child = rows
          .map((E, i) => {
            // console.log(E, bodyTable, bodyrows, bodyrows[i]);
            if (E.localName === "tr" && bodyrows) {
              return createRow({ row: E, bodyRow: bodyrows[i], RI: i, table });
            }
            return null;
          })
          .filter(Boolean);
        newTable.name = "Table";
        let attributes =
          table.childNodes[0].attributes._ownerElement.childNodes;

        attributes &&
          Object.keys(attributes)?.map((attr) => {
            switch (attributes[attr].nodeName) {
              case "w:tblW":
                Object.keys(attributes[attr].attributes).map((att) => {
                  switch (attributes[attr].attributes[att].name) {
                    case "w:type":
                      setTableWidth({
                        loc: "type",
                        tableProps: newTable.properties,
                        value: attributes[attr].attributes[att].nodeValue,
                      });
                      break;
                    case "w:w":
                      setTableWidth({
                        loc: "size",
                        tableProps: newTable.properties,
                        value: attributes[attr].attributes[att].nodeValue,
                      });
                  }
                });
                break;
              case "w:tblCellMar":
                // console.log();
                break;
            }
          });

        // console.log(nextEle.nodeName);
        newTable.ele = child;
        if (mainEle && nextEle?.nodeName !== "w:p" && !magic) {
          let text = structuredClone(TEXT);
          let para = structuredClone(PARA);
          para.name = "gap";
          para.ele.push(text);
          return [newTable, para];
        } else if (magic) {
          let text = structuredClone(TEXT);
          let para = structuredClone(PARA);
          para.name = "gap";
          para.ele.push(text);
          return [newTable, para];
        }
        return newTable;
      };

      let children = [];
      let sections = [];
      let elements = [];

      Object.keys(xmlDoc.documentElement.childNodes).forEach((R) => {
        if (xmlDoc.documentElement.childNodes[R]?.localName === "body") {
          let nodes = xmlDoc.documentElement.childNodes[R].childNodes;
          Object.keys(nodes).forEach((R) => {
            let arr;
            if (nodes[R].childNodes) {
              arr = Object.keys(nodes[R].childNodes);
              arr = arr.slice(0, -1);
            }
            if (arr !== undefined) {
              // console.log(arr, arr.length);
              if (nodes[R].nodeName === "w:p" && arr.length > 1) {
                elements.push(nodes[R]);
              } else if (nodes[R].nodeName === "w:tbl") {
                elements.push(nodes[R]);
              }
            }
          });
        }
      });

      // console.log(elements);

      // console.log(htmldoc.body, htmldoc.body.childNodes);
      const bodyEles = Array.from(htmldoc.body.childNodes);

      const checkULorOLorLI = (ele) => {
        // console.log(ele.nodeName);
        if (
          ele.nodeName === "UL" ||
          ele.nodeName === "OL" ||
          ele.nodeName === "LI"
          // ele.nodeName === "STRONG" ||
          // ele.nodeName === "#text"
        ) {
          if (ele.childNodes.length > 1) {
            return ele.childNodes;
          } else {
            if (ele.childNodes[0]) {
              return checkULorOLorLI(ele.childNodes[0]);
            } else {
              return null;
            }
          }
        }
      };

      for (let i = 0; i < bodyEles.length; i++) {
        const ele = bodyEles[i];
        const paras = checkULorOLorLI(ele);
        console.log(paras);

        if (paras) {
          const newParagraphs = Array.from(paras)
            .map((listItem) => {
              const p = document.createElement("p");
              return p;
            })
            .filter(Boolean);
          console.log(newParagraphs);
          bodyEles.splice(i, 1, ...newParagraphs);
          i += newParagraphs.length - 1; // Adjust the index to account for the new paragraphs
        }
      }

      // bodyEles.map((ele) => {
      //   console.log(ele);
      // });

      // console.log(elements.length);
      console.log(elements);
      console.log(bodyEles);
      let localNames = elements.map((element) => element.localName);
      console.log(localNames, bodyEles);

      // console.log(magic);

      let nodes = [];

      elements.map((element, i) => {
        // console.log(elements[element]);
        if (
          element?.nextSibling?.localName === "sectPr" ||
          element?.localName === "sectPr"
        ) {
          newSec.name = "Section";
          newSec.ele = children;
          sections.push(newSec);
          children = [];
          newSec = structuredClone(SECTION);
        } else {
          switch (element.localName) {
            case "p":
              let para = createPara({ para: element, mainEle: true });
              if (magic) {
                nodes.push(para);
              } else {
                children.push(para);
              }
              break;
            case "tbl":
              let newTable = structuredClone(TABLE);
              let newRow = structuredClone(ROW);
              let newCell = structuredClone(CELL);
              if (magic) {
                newTable.properties.margins = {
                  left: "0in",
                  right: "0in",
                  top: "0.05in",
                  bottom: "0in",
                };
              }
              newCell.ele = nodes;
              newRow.ele.push(newCell);
              newTable.ele.push(newRow);
              newTable.properties.borders = false;
              newTable.para = {
                spacing: {
                  before: "0pt",
                  after: "2pt",
                },
              };
              if (nodes.length > 0) {
                children.push(newTable);
                let text = structuredClone(TEXT);
                let para = structuredClone(PARA);
                para.name = "gap";
                para.ele.push(text);
                children.push(para);
                nodes = [];
              } else if (nodes.length === 1) {
                children.push(...nodes);
              }
              // console.log(element, bodyEles[i]);
              let table = createTable({
                table: element,
                nextEle: elements[i + 1],
                bodyTable: bodyEles[i],
                mainEle: true,
              });
              if (Array.isArray(table)) {
                children.push(...table);
              } else {
                children.push(table);
              }
              break;
          }
        }
      });
      newDoc.name = "Document";
      newDoc.ele = sections;
      dispatch(pushDoc(newDoc));
      dispatch(setIndexPath(`${docs.length}_Doc`));
    };

    reader.readAsBinaryString(file);
    fileInputRef.current.value = null;
    fileInputRef2.current.value = null;
  };

  return (
    <section className="psm">
      <h1 className="font-mono text-center pb-4">Document Controls</h1>

      <h2 className="font-mono pb-2">Document Name</h2>
      <div className="flex gap-4">
        <div className="flex gap-4 pb-6 relative">
          {/* Hidden file input */}
          <input
            type="file"
            accept=".docx"
            onChange={(e) => handleFileUpload(e, true)}
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            ref={fileInputRef}
          />
          {/* Custom button */}
          <button className="w-[110px] h-[40px] bg-cyan-950 text-white focus:outline-none">
            Magic Upload
          </button>
        </div>
        <div className="flex gap-4 pb-6 relative">
          {/* Hidden file input */}
          <input
            type="file"
            accept=".docx"
            onChange={handleFileUpload}
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            ref={fileInputRef2}
          />
          {/* Custom button */}
          <button className="w-[110px] h-[40px] bg-cyan-950 text-white focus:outline-none">
            Upload DOCX
          </button>
        </div>
      </div>
      <div className="flex gap-4 pb-6">
        <Input
          className="h-[40px] w-[250px]"
          value={docName}
          change={(e) => setDocName(e.target.value)}
          placeholder="Enter Document Name"
        />
        <button
          className="px-3 py-1 bg-cyan-950 text-light-500"
          onClick={() => {
            if (docName !== "") {
              if (numberingTypes) {
                dispatch(
                  addDoc({
                    name: docName,
                    value: agency,
                    numbering: numberingTypes,
                  })
                );
                dispatch(setIndexPath(`${docs.length}_Doc`));
              } else {
                toast.error("Failed to fetch Numbering Types");
              }
            } else {
              toast.error("Document name cannot be empty");
            }
          }}
        >
          Add Doc
        </button>
      </div>
      {/* <h2 className="font-mono pb-2">Paragraph Name</h2>
      <div className="flex gap-4 pb-6">
        <Input
          className="h-[40px] w-[250px]"
          value={paraName}
          change={(e) => setParaName(e.target.value)}
          placeholder="Enter Paragraph Name"
        />
        <button
          className="px-3 py-1 bg-cyan-950 text-light-500"
          onClick={() => {
            if (paraName !== undefined && docs !== undefined) {
              dispatch(
                addPara({
                  path: RRIP_DOC,
                  name: paraName,
                })
              );
            } else {
              toast.error("Para Name Cannot be empty");
            }
          }}
        >
          Add Para
        </button>
      </div>
      <h2 className="font-mono pb-2">Table Name, Rows and Cols</h2>
      <div className="flex flex-wrap gap-4 pb-6">
        <Input
          className="h-[40px] w-[250px]"
          value={tableName}
          change={(e) => setTableName(e.target.value)}
          placeholder="Enter Table Name"
        />
        <Input
          className="h-[40px] w-[150px]"
          value={rows}
          change={(e) => setRows(e.target.value)}
          placeholder="Enter no.of Rows"
          type="number"
        />
        <Input
          className="h-[40px] w-[150px]"
          value={cols}
          change={(e) => setCols(e.target.value)}
          placeholder="Enter no.of Cols"
          type="number"
        />

        <button
          className="px-3 py-1 bg-cyan-950 text-light-500"
          onClick={() => {
            if (tableName !== undefined && docs !== undefined) {
              dispatch(
                addTable({
                  path: RRIP_DOC,
                  name: tableName,
                  rows: parseFloat(rows),
                  cols: parseFloat(cols),
                })
              );
            } else {
              toast.error("Table Name Cannot be empty");
            }
          }}
        >
          Add Table
        </button>
      </div> */}
    </section>
  );
};

export default DocumentControls;
