import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTableBorder } from "../../../../../../slices/docsSlice";
import Button from "../../../../../../utils/Button";

const Border = ({ border, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TB } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">Set Border</h2>
      <Button
        name="Border"
        value={border}
        click={(value) => {
          dispatch(
            updateTableBorder({
              table: RRIP_TB,
              value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
      />
    </div>
  );
};

export default Border;
