import React, { useEffect, useState } from "react";
import Input from "../../../utils/Input";

import CodeMirror from "@uiw/react-codemirror";
import { autocompletion } from "@codemirror/autocomplete";
import { javascript } from "@codemirror/lang-javascript";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { EditorView } from "@codemirror/view"; // <-- ADDED

import { isValidVariableName } from "../../shared/InputCheck";
import { useDispatch, useSelector } from "react-redux";
import {
  addFunctions,
  updateFunction,
  deleteFunction,
} from "../../../slices/docsSlice";
import { toast } from "react-toastify";
import { returnPropEle } from "./Properties/CommFunctions";

const DocFunctions = () => {
  const dispatch = useDispatch();
  const [functions, setFunctions] = useState([]);
  const [func, setFunc] = useState("() => {\n  \n}");
  const [name, setName] = useState("");
  const [search, setSearch] = useState("");

  const { docs, RRIP_DOC, NIP_DOC } = useSelector((state) => state.docs);

  const splitDoc = RRIP_DOC[0].split("_");

  useEffect(() => {
    const doc = RRIP_DOC ? returnPropEle(docs, RRIP_DOC) : undefined;
    if (doc) {
      if (doc.F) {
        setFunctions(doc.F);
      } else {
        setFunctions();
      }
    }
  }, [docs, NIP_DOC]);

  const addFunc = () => {
    console.log(func);
    if (isValidVariableName(name) && name !== "") {
      dispatch(
        addFunctions({
          doc: RRIP_DOC,
          value: func,
          name,
        })
      );
      setName("");
      setFunc("() => {\n  \n}");
      toast.success("Function Added Succesfully.");
    } else {
      toast.error(
        "Invalid variable name. It must start with a letter, $, or _ and can contain letters, numbers, $, or _."
      );
    }
  };

  const updateFunc = () => {
    dispatch(
      updateFunction({
        doc: RRIP_DOC,
        value: func,
        name,
      })
    );
    setName("");
    setFunc("() => {\n  \n}");
    toast.success("Function Updated Succesfully.");
  };

  const deleteFunc = () => {
    dispatch(
      deleteFunction({
        doc: RRIP_DOC,
        name,
      })
    );
    setFunc("() => {\n  \n}");
    setName("");
  };

  const refreshFuncs = () => {
    const doc = RRIP_DOC ? returnPropEle(docs, RRIP_DOC) : undefined;
    if (doc) {
      if (doc.F) {
        setFunctions(doc.F);
      } else {
        setFunctions();
      }
    }
  };

  return (
    <div className="w-full border-l psm">
      <div className="flex">
        <div className="">
          <div className="flex flex-col gap-2 pb-2 h-[445px]">
            <div className="flex gap-2">
              <Input
                className="h-[40px]"
                type="text"
                placeholder="Search Function"
                value={search}
                change={(e) => setSearch(e.target.value)}
              />
              <button
                className="bg-cyan-950 text-light-500 h-[40px] px-2"
                onClick={refreshFuncs}
              >
                Refresh
              </button>
            </div>
            <div className="flex flex-wrap overflow-auto">
              {functions
                ? Object.keys(functions).map((fun) => {
                    return (
                      <div
                        className="morphous-inset psm rounded h-[100px] w-[300px] cursor-pointer"
                        onClick={() => {
                          setName(fun);
                          setFunc(functions[fun].toString());
                          setIsExisting(true);
                        }}
                      >
                        <h1 className="font-bold text-center">{fun}</h1>
                        <CodeMirror
                          className="text-sm"
                          value={functions[fun].toString()}
                          theme={vscodeDark}
                          extensions={[
                            javascript({ jsx: true }),
                            autocompletion(),
                            EditorView.lineWrapping, // <-- ADDED
                          ]}
                        />
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          <div className="flex items-center gap-2 mb-2">
            <div className="h-[40px] w-[40px] bg-orange-500 flex items-center justify-center">
              {splitDoc[0]}
            </div>
            <Input
              className="h-[40px]"
              type="text"
              placeholder="Function Name"
              value={name}
              change={(e) => setName(e.target.value)}
            />
            <button
              className="bg-cyan-950 text-light-500 h-[40px] px-2"
              onClick={addFunc}
            >
              Add Func
            </button>
            <button
              className="bg-cyan-950 text-light-500 h-[40px] px-2"
              onClick={updateFunc}
            >
              Update Func
            </button>
            {/* <button className="bg-primary-500 text-light-500 h-[40px] px-2">
          Update Func
        </button> */}
            <button
              className="bg-danger-500 text-light-500 h-[40px] px-2"
              onClick={deleteFunc}
            >
              Delete Func
            </button>
          </div>
        </div>
        <div className="flex-1">
          <CodeMirror
            className="text-base"
            value={func}
            theme={vscodeDark}
            extensions={[
              javascript({ jsx: true }),
              autocompletion(),
              EditorView.lineWrapping,
            ]}
            onChange={(value) => {
              setFunc(value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DocFunctions;
