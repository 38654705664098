import React from "react";

const Button = ({ click, name, value }) => {
  return (
    <button
      className={`px-2 min-w-[40px] h-[40px] border ${
        value ? "bg-cyan-950 text-light-500" : ""
      }`}
      onClick={() => click(value)}
    >
      {name}
    </button>
  );
};

export default Button;
