import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import Input from "../../../../utils/Input";
import Dropdown from "../../../../utils/Dropdown";
import Button from "../../../../utils/Button";
import { useDispatch } from "react-redux";
import {
  removeUserInput,
  updateInputType,
  updateInputLength,
  updateInputCaps,
  updateInputUnit,
  updateDefaultValue,
} from "../../../../slices/entryForm";

const EFInput = ({ opInput, operator }) => {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col flex-wrap items-start gap-2 bg-red-200 p-2 rounded relative">
      <div className="flex gap-2">
        <div
          className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
          onClick={() =>
            dispatch(
              removeUserInput({
                name: opInput.placeholder,
                operator: operator,
              })
            )
          }
        >
          <IoCloseSharp />
        </div>
        <div>
          <h2 className="pb-1">
            {opInput.placeholder} ({opInput.element})
          </h2>
          <Input
            className="h-[40px]"
            type="text"
            placeholder="default value"
            value={opInput.defaultValue}
            change={(e) => {
              dispatch(
                updateDefaultValue({
                  name: opInput.placeholder,
                  operator: operator,
                  value: e.target.value,
                })
              );
            }}
          />
        </div>
        <div>
          <h2 className="pb-1">Unit</h2>
          <Dropdown
            name="Unit"
            width={150}
            height={40}
            value={opInput.unit}
            options={["none", "₹", "L", "Cr", "%"]}
            target="option"
            click={(value) => {
              dispatch(
                updateInputUnit({
                  name: opInput.placeholder,
                  operator: operator,
                  value: value,
                })
              );
            }}
          />
        </div>
      </div>
      <div className="flex gap-2 items-end">
        <div>
          <h2 className="pb-1">Type</h2>
          <Dropdown
            name="Type"
            width={150}
            height={40}
            value={opInput.type}
            options={["text", "number"]}
            target="option"
            click={(value) => {
              dispatch(
                updateInputType({
                  name: opInput.placeholder,
                  operator: operator,
                  value: value,
                })
              );
            }}
          />
        </div>
        <div>
          <h2 className="pb-1">Length</h2>
          <Input
            className="w-[80px] h-[40px]"
            type="number"
            value={opInput.maxLength ? opInput.maxLength : ""}
            change={(e) => {
              dispatch(
                updateInputLength({
                  name: opInput.placeholder,
                  operator: operator,
                  value: e.target.value,
                })
              );
            }}
          />
        </div>
        <div>
          <Button
            name="All Caps"
            value={opInput.caps}
            click={(value) => {
              dispatch(
                updateInputCaps({
                  name: opInput.placeholder,
                  operator: operator,
                  value: value,
                })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EFInput;
