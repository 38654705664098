import { apiSlice } from "./apiSlice";
import { USERS_URL } from "../constants";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/auth`,
        method: "POST",

        body: data,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${USERS_URL}/logout`,
        method: "POST",
      }),
    }),
    profile: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/profile`,
        method: "PUT",

        body: data,
      }),
    }),
    // Adding Permission also creates the user but isPermitted flag is set to false.
    // permission: builder.mutation({
    //   query: (data) => ({
    //     url: `${USERS_URL}/permission`,
    //     method: "POST",
    //     body: data,
    //   }),
    //   providesTags: ["User"],
    // }),
    register: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}`,
        method: "POST",
        body: data,
      }),
      providesTags: ["User"],
    }),
    permissionAccept: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/permission`,
        method: "PUT",
        body: data,
      }),
    }),
    refreshPermissionList: builder.query({
      query: () => ({
        url: `${USERS_URL}/refresh`,
      }),
      providesTags: ["User"],
      keepUnusedDataFor: 5,
    }),
    refreshPasswordList: builder.query({
      query: () => ({
        url: `${USERS_URL}/refreshPasswordList`,
      }),
      providesTags: ["User"],
      keepUnusedDataFor: 5,
    }),
    managersList: builder.query({
      query: (pageNumber) => ({
        url: `${USERS_URL}/managersList`,
        params: { pageNumber },
      }),
      providesTags: ["User"],
      keepUnusedDataFor: 5,
    }),
    createOperator: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/createOperator`,
        method: "POST",
        body: data,
      }),
      providesTags: ["User"],
    }),
    createUser: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/createUser`,

        method: "POST",
        body: data,
      }),
      providesTags: ["User"],
    }),
    getAgencyOperators: builder.query({
      query: () => ({
        url: `${USERS_URL}/agencyOperators`,
      }),
      providesTags: ["User"],
      keepUnusedDataFor: 5,
    }),
    getAgencyUsers: builder.query({
      query: ({ pageNumber }) => ({
        url: `${USERS_URL}/agencyUsers`,
        params: { pageNumber },
      }),
      providesTags: ["User"],
      keepUnusedDataFor: 5,
    }),
    updateByAuthority: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/updateByAuthority`,
        method: "POST",
        body: data,
      }),
      providesTags: ["User"],
    }),

    getUsers: builder.query({
      query: () => ({
        url: USERS_URL,
      }),
      providesTags: ["User"],
      keepUnusedDataFor: 5,
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `${USERS_URL}/${userId}`,
        method: "DELETE",
      }),
    }),
    getUserDetails: builder.query({
      query: (id) => ({
        url: `${USERS_URL}/${id}`,
      }),
      keepUnusedDataFor: 5,
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/profile`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    assignDocs: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/assignDocs`,
        method: "POST",
        body: data,
      }),
    }),
    removeDocs: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/deleteDocs`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  usePermissionMutation,
  usePermissionAcceptMutation,
  useRefreshPermissionListQuery,
  useRefreshPasswordListQuery,
  useManagersListQuery,
  useCreateOperatorMutation,
  useCreateUserMutation,
  useGetAgencyOperatorsQuery,
  useGetAgencyUsersQuery,
  useUpdateByAuthorityMutation,
  useProfileMutation,
  useGetUsersQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useGetUserDetailsQuery,
  useAssignDocsMutation,
  useRemoveDocsMutation,
} = userApiSlice;
