import { returnEle, returnHFEle, returnEI, consoleFun } from "./functions";
import { SECTION } from "./elements";

const documentProps = {
  // Doc Properties
  addSection: (state, { payload }) => {
    let SP;
    let SLI;
    let lastIndex;
    const section = structuredClone(SECTION);
    section.name = payload.name ? payload.name : "Section";
    if (payload.path.length === 1) {
      let ele = returnEle(state, payload.path);
      ele.ele.push(section);
    } else if (payload.path.length === 2) {
      SLI = payload.path[1].split("_");
      lastIndex = parseFloat(SLI[0]);
      SP = payload.path.slice(0, 1);
      let ele = returnEle(state, SP);
      if (payload.loc === "after") {
        ele.ele.splice(lastIndex + 1, 0, section);
      } else if (payload.loc === "before") {
        ele.ele.splice(lastIndex, 0, section);
      } else {
        ele.ele.push(section);
      }
    } else if (payload.path.length >= 3) {
      SLI = payload.path[1].split("_");
      lastIndex = parseFloat(SLI[0]);
      SP = payload.path.slice(0, 1);
      let SEI = payload.path[2].split("_");
      let elementIndex = parseFloat(SEI[0]);
      let SPS = payload.path.slice(0, 2);
      let doc = returnEle(state, SP);
      let sec = returnEle(state, SPS);
      if (payload.loc === "after") {
        let beforeEles = sec.ele.slice(0, elementIndex + 1);
        let afterEles = sec.ele.slice(elementIndex + 1);
        const section1 = structuredClone(SECTION);
        section1.name = sec.name;
        section1.properties = sec.properties;
        section1.headers = sec.headers;
        section1.footers = sec.footers;
        section1.ele = beforeEles;

        section.properties = sec.properties;
        section.headers = sec.headers;
        section.footers = sec.footers;

        const section2 = structuredClone(SECTION);
        section2.name = "Section";
        section2.properties = sec.properties;
        section2.headers = sec.headers;
        section2.footers = sec.footers;
        section2.ele = afterEles;

        doc.ele.splice(lastIndex, 1, section1, section2);
      } else if (payload.loc === "before") {
        let beforeEles = sec.ele.slice(0, elementIndex);
        let afterEles = sec.ele.slice(elementIndex);
        const section1 = structuredClone(SECTION);
        section1.name = sec.name;
        section1.properties = sec.properties;
        section1.headers = sec.headers;
        section1.footers = sec.footers;
        section1.ele = beforeEles;

        section.properties = sec.properties;
        section.headers = sec.headers;
        section.footers = sec.footers;

        const section2 = structuredClone(SECTION);
        section2.name = "Section";
        section2.properties = sec.properties;
        section2.headers = sec.headers;
        section2.footers = sec.footers;
        section2.ele = afterEles;

        doc.ele.splice(lastIndex, 1, section1, section2);
      } else {
        ele.ele.push(section);
      }
    }
  },
  setNumbering: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    if (doc.properties.numbering) {
      doc.properties.numbering.config = payload.value;
    } else {
      doc.properties["numbering"] = {
        config: payload.value,
      };
    }
  },
  updateMergeSec: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    consoleFun(doc.properties);
    if (doc.properties.mergeSec) {
      delete doc.properties.mergeSec;
    } else {
      doc.properties.mergeSec = true;
    }
  },
  setBulkFiles: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    if (doc.bulkFiles) {
      delete doc.bulkFiles;
      delete doc.jsFun;
      delete doc.cluster;
    } else {
      doc.bulkFiles = true;
      doc.cluster = "";
      doc.jsFun = `function(D) {
  
}`;
    }
  },

  addFunctions: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    if (doc.F !== undefined) {
      doc.F[payload.name] = eval("(" + payload.value + ")");
    } else {
      doc.F = {
        [payload.name]: eval("(" + payload.value + ")"),
      };
    }
  },
  updateFunction: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.F[payload.name] = eval("(" + payload.value + ")");
  },
  deleteFunction: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    delete doc.F[payload.name];
  },

  // DocSec Properties

  // DocTab Properties
  setDocTableWidth: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.table.width[payload.loc] = payload.value;
  },
  setDocTableMargins: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.table.margins[payload.loc] = payload.value;
  },
  setDocTableBorders: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.table.borders[payload.direction][payload.loc] =
      payload.value;
  },
  // DocRow Properties
  setDocRowHeight: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    if (doc.properties.row.height) {
      doc.properties.row.height[payload.loc] = payload.value;
    } else {
      doc.properties.row.height = {
        [payload.loc]: payload.value,
      };
    }
  },
  // DocCell Properties
  setDocCellAlignment: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.cell.verticalAlign = payload.value;
  },
  setDocCellMargins: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.cell.margins[payload.loc] = payload.value;
  },
  setDocCellBorders: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.cell.borders[payload.direction][payload.loc] = payload.value;
  },
  // DocPara Properties
  setDocParaAlignment: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.para.alignment = payload.value;
  },
  setDocParaSpacing: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.para.spacing[payload.loc] = payload.value;
  },
  // DocText Properties
  setDocTextFont: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.text.font = payload.value;
  },
  setDocTextFontSize: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.text.fontSize = payload.value;
  },
};

export default documentProps;
