import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DocumentSVG from "../assets/icons/word.svg";
import { TbMenuDeep } from "react-icons/tb";
import { CgMenuGridR } from "react-icons/cg";
import { BsFillFileEarmarkImageFill } from "react-icons/bs";
import { toggleSidebar, toggleFunctionBar } from "../slices/utilSlice";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resetDocs,
  setDocId,
  setDocs,
  setIndexPath,
  setName,
  setCategory,
  setRunStatus,
  setDev,
  setMinimal,
} from "../slices/docsSlice";
import { setEFandFilledEF, setFilledEF } from "../slices/entryForm";
import { resetDocument } from "../slices/documentSlice";
import { resetForms } from "../slices/formsSlice";
import { resetInternalDependency } from "../slices/internalDependencySlice";
import produceClone from "./Lodash";
import { useHotkeys } from "react-hotkeys-hook";
import Dropdown from "../utils/Dropdown";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { agency } = useSelector((state) => state.agency);
  const { document } = useSelector((state) => state.document);
  const [ID, setID] = useState(document?.category); // Internal Dependency
  const [ED, setED] = useState("");
  const { docs, category, indexPath, dev, minimal } = useSelector(
    (state) => state.docs
  );
  const { entryForm } = useSelector((state) => state.entryForm);
  const { userForms, forms } = useSelector((state) => state.forms);
  const { listIDS, internalDependency } = useSelector(
    (state) => state.internalDependency
  );
  const [isReadyToNavigate, setIsReadyToNavigate] = useState(false);

  const [newIDS, setNewIDS] = useState([]);

  const { pathname } = useLocation();
  let splitLoc = pathname.split("/");
  let splitLoc2 = pathname.split("/");
  const currentPath = splitLoc[3];
  if (splitLoc.length >= 4) {
    let length = splitLoc.length - 3;
    splitLoc.splice(3, length);
  }
  const baseURL = splitLoc.join("/");

  useHotkeys("esc", () => {
    dispatch(setDev());
    let SIP = indexPath.split("/");
    if (SIP.length > 2 && !dev) {
      SIP.splice(3);
      dispatch(setIndexPath(SIP.join("/")));
      let lastIndex = SIP[SIP.length - 1];
      let SLI = lastIndex.split("_");
      let path = `${baseURL}/${SIP.join("/")}`;
      switch (SLI[1]) {
        case "P":
          navigate(`${path}/SP/PP`);
          break;
        case "TB":
          navigate(`${path}/SP/TBP`);
          break;
      }
    }
  });

  useEffect(() => {
    if (document?.internalDependency) {
      const dependencies = [];
      Object.keys(document.internalDependency).map((dependency) => {
        dependencies.push(dependency);
        dependencies.push(`${dependency}_EF`);
      });
      setNewIDS(dependencies);
    }
  }, [document]);

  useEffect(() => {
    if (isReadyToNavigate) {
      navigate("/manager/CD");
    }
  }, [isReadyToNavigate]);

  useEffect(() => {
    if (
      !document &&
      !internalDependency &&
      !userForms &&
      !forms &&
      !listIDS &&
      !docs
    ) {
      setIsReadyToNavigate(true);
    }
  }, [internalDependency, listIDS, userForms, forms, document, docs]);

  // console.log(userForms);

  return (
    <div className="box psm-x justify-between border-b z-10 dark:bg-cyan-950">
      <div className="box gap-2">
        <div
          className="cursor-pointer hover:text-orange-500"
          onClick={() => dispatch(toggleSidebar())}
        >
          <CgMenuGridR className="h-[30px] w-[30px]" />
        </div>
        <div
          className="cursor-pointer py-1 px-2 border-secondary text-lg flex items-center border font-trebuchet"
          onClick={() => {
            dispatch(resetDocument());
            dispatch(resetInternalDependency());
            dispatch(resetForms());
            dispatch(resetDocs());
            navigate("/manager/CD");
          }}
        >
          {/* <div className="pr-1">
            <TbMenuDeep />
          </div> */}
          <h3>{agency ? agency : "Doc Creator"}</h3>
        </div>
      </div>
      {/* <div>
        {splitIndexPath.map((ip) => {
          const splitIp = ip.split("_");
          return (
            <>
              <Input2 value={splitIp[0]} />
            </>
          );
        })}
      </div> */}

      <div className="flex gap-4">
        {document ? (
          <>
            {userForms.length <= 10 ? (
              <>
                {[...userForms]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((obj) => {
                    return (
                      <div
                        id={obj.name}
                        className={`py-2 cursor-pointer ${
                          obj.name === splitLoc2[2] || obj.name === splitLoc2[3]
                            ? "border-b-4 border-orange-500 font-bold"
                            : null
                        }`}
                        onClick={(e) => {
                          let value = e.target.id;
                          let role;
                          if (userInfo.role === "Manager") {
                            role = "manager";
                          } else if (userInfo.role === "Operator") {
                            role = "op";
                          }
                          setID(value);
                          if (
                            internalDependency.some(
                              (ID) => `${ID.category}$${ID.name}` === value
                            )
                          ) {
                            dispatch(setDocId(forms[value]._id));

                            let newDoc = produceClone(forms[value].document);
                            newDoc.map((d) => {
                              if (d.F) {
                                Object.keys(d.F).map((f) => {
                                  d.F[f] = eval("(" + d.F[f] + ")");
                                });
                              }
                            });

                            dispatch(setDocs(newDoc));
                            dispatch(
                              setEFandFilledEF({
                                entryForm: forms[value].entryForm,
                                filledEF: forms[`${value}_EF`],
                              })
                            );
                            dispatch(setIndexPath(forms[value].indexPath));
                            dispatch(setName(forms[value].name));
                            dispatch(setCategory(forms[value].category));
                            dispatch(setRunStatus(forms[value].runStatus));
                            if (forms[value].indexPath === "") {
                              navigate(`/${role}/${value}/DC`);
                            } else {
                              console.log(value);
                              let RRIP = forms[value].indexPath.split("/");
                              let lastIndex = RRIP[RRIP.length - 1];
                              let SLI = lastIndex.split("_");
                              let path = `/${role}/${value}/${forms[value].indexPath}`;
                              let NIP = RRIP.map(
                                (index) => index.split("_")[0]
                              ).join("_");
                              let splitNIP = NIP.split("_");
                              let lastNIP = splitNIP[splitNIP.length - 1];
                              switch (SLI[1]) {
                                case "I":
                                  if (RRIP.length === 4) {
                                    navigate(`${path}/SP/PP/IP`);
                                  } else {
                                    navigate(`${path}/SP/TBP/TR/TC/PP/IP`);
                                  }
                                  break;
                                case "CB":
                                  if (RRIP.length === 4) {
                                    navigate(
                                      `${path}/SP/PP/CBP/${lastNIP}#${NIP}`
                                    );
                                  } else {
                                    navigate(
                                      `${path}/SP/TBP/TR/TC/PP/CBP/${lastNIP}#${NIP}`
                                    );
                                  }
                                  break;
                                case "SY":
                                  if (RRIP.length === 4) {
                                    navigate(
                                      `${path}/SP/PP/SYP/${lastNIP}#${NIP}`
                                    );
                                  } else {
                                    navigate(
                                      `${path}/SP/TBP/TR/TC/PP/SYP/${lastNIP}#${NIP}`
                                    );
                                  }
                                  break;
                                case "T":
                                  if (RRIP.length === 4) {
                                    navigate(
                                      `${path}/SP/PP/TP/${lastNIP}#${NIP}`
                                    );
                                  } else {
                                    navigate(
                                      `${path}/SP/TBP/TR/TC/PP/TP/${lastNIP}#${NIP}`
                                    );
                                  }
                                  break;
                                case "P":
                                  if (RRIP.length === 3) {
                                    navigate(`${path}/SP/PP`);
                                  } else {
                                    navigate(`${path}/SP/TBP/TR/TC/PP`);
                                  }
                                  break;
                                case "TC":
                                  navigate(`${path}/SP/TBP/TR/TC`);
                                  break;
                                case "TR":
                                  navigate(`${path}/SP/TBP/TR`);
                                  break;
                                case "TB":
                                  navigate(`${path}/SP/TBP`);
                                  break;
                                case "S":
                                  navigate(`${path}/SP`);
                                  break;
                                case "Doc":
                                  navigate(`${path}/DP`);
                                  break;
                              }
                            }
                          } else {
                            let splitValue = value.split("_");
                            if (splitValue[splitValue.length - 1] !== "EF") {
                              navigate(`/${role}/${value}`);
                            } else {
                              let splitValue = value.split("_");
                              let newValue = splitValue.slice(0, -1);
                              newValue = newValue.join("_");
                              let splitNewValue = newValue.split("$");
                              dispatch(
                                setEFandFilledEF({
                                  entryForm: forms[newValue].entryForm,
                                  filledEF: forms[value],
                                })
                              );
                              dispatch(setDocId(forms[newValue]._id));

                              let newDoc = produceClone(
                                forms[newValue].document
                              );
                              newDoc.map((d) => {
                                if (d.F) {
                                  Object.keys(d.F).map((f) => {
                                    d.F[f] = eval("(" + d.F[f] + ")");
                                  });
                                }
                              });

                              dispatch(setDocs(newDoc));
                              dispatch(setIndexPath(forms[newValue].indexPath));
                              dispatch(setRunStatus(forms[newValue].runStatus));
                              dispatch(setCategory(splitNewValue[0]));
                              dispatch(setName(splitNewValue[1]));
                              setTimeout(() => {
                                navigate(`/${role}/EF/${value}`);
                              }, 0);
                            }
                          }
                        }}
                      >
                        {obj.name
                          .substring(obj.name.indexOf("$") + 1)
                          .replaceAll("_", " ")}
                      </div>
                    );
                  })}
              </>
            ) : (
              <Dropdown
                options={[...userForms]}
                // name="Document"
                value={ID.substring(ID.indexOf("$") + 1).replaceAll("_", " ")}
                target={`option.name.substring(option.name.indexOf("$") + 1).replaceAll("_", " ")`}
                click={(userForm) => {
                  let value = userForm.name;
                  let role;
                  if (userInfo.role === "Manager") {
                    role = "manager";
                  } else if (userInfo.role === "Operator") {
                    role = "op";
                  }
                  setID(value);
                  if (
                    internalDependency.some(
                      (ID) => `${ID.category}$${ID.name}` === value
                    )
                  ) {
                    dispatch(setDocId(forms[value]._id));

                    let newDoc = produceClone(forms[value].document);
                    newDoc.map((d) => {
                      if (d.F) {
                        Object.keys(d.F).map((f) => {
                          d.F[f] = eval("(" + d.F[f] + ")");
                        });
                      }
                    });

                    dispatch(setDocs(newDoc));
                    dispatch(
                      setEFandFilledEF({
                        entryForm: forms[value].entryForm,
                        filledEF: forms[`${value}_EF`],
                      })
                    );
                    dispatch(setIndexPath(forms[value].indexPath));
                    dispatch(setName(forms[value].name));
                    dispatch(setCategory(forms[value].category));
                    dispatch(setRunStatus(forms[value].runStatus));
                    if (forms[value].indexPath === "") {
                      navigate(`/${role}/${value}/DC`);
                    } else {
                      console.log(value);
                      let RRIP = forms[value].indexPath.split("/");
                      let lastIndex = RRIP[RRIP.length - 1];
                      let SLI = lastIndex.split("_");
                      let path = `/${role}/${value}/${forms[value].indexPath}`;
                      let NIP = RRIP.map((index) => index.split("_")[0]).join(
                        "_"
                      );
                      let splitNIP = NIP.split("_");
                      let lastNIP = splitNIP[splitNIP.length - 1];
                      switch (SLI[1]) {
                        case "I":
                          if (RRIP.length === 4) {
                            navigate(`${path}/SP/PP/IP`);
                          } else {
                            navigate(`${path}/SP/TBP/TR/TC/PP/IP`);
                          }
                          break;
                        case "CB":
                          if (RRIP.length === 4) {
                            navigate(`${path}/SP/PP/CBP/${lastNIP}#${NIP}`);
                          } else {
                            navigate(
                              `${path}/SP/TBP/TR/TC/PP/CBP/${lastNIP}#${NIP}`
                            );
                          }
                          break;
                        case "SY":
                          if (RRIP.length === 4) {
                            navigate(`${path}/SP/PP/SYP/${lastNIP}#${NIP}`);
                          } else {
                            navigate(
                              `${path}/SP/TBP/TR/TC/PP/SYP/${lastNIP}#${NIP}`
                            );
                          }
                          break;
                        case "T":
                          if (RRIP.length === 4) {
                            navigate(`${path}/SP/PP/TP/${lastNIP}#${NIP}`);
                          } else {
                            navigate(
                              `${path}/SP/TBP/TR/TC/PP/TP/${lastNIP}#${NIP}`
                            );
                          }
                          break;
                        case "P":
                          if (RRIP.length === 3) {
                            navigate(`${path}/SP/PP`);
                          } else {
                            navigate(`${path}/SP/TBP/TR/TC/PP`);
                          }
                          break;
                        case "TC":
                          navigate(`${path}/SP/TBP/TR/TC`);
                          break;
                        case "TR":
                          navigate(`${path}/SP/TBP/TR`);
                          break;
                        case "TB":
                          navigate(`${path}/SP/TBP`);
                          break;
                        case "S":
                          navigate(`${path}/SP`);
                          break;
                        case "Doc":
                          navigate(`${path}/DP`);
                          break;
                      }
                    }
                  } else {
                    let splitValue = value.split("_");
                    if (splitValue[splitValue.length - 1] !== "EF") {
                      navigate(`/${role}/${value}`);
                    } else {
                      let splitValue = value.split("_");
                      let newValue = splitValue.slice(0, -1);
                      newValue = newValue.join("_");
                      let splitNewValue = newValue.split("$");
                      dispatch(
                        setEFandFilledEF({
                          entryForm: forms[newValue].entryForm,
                          filledEF: forms[value],
                        })
                      );
                      dispatch(setDocId(forms[newValue]._id));

                      let newDoc = produceClone(forms[newValue].document);
                      newDoc.map((d) => {
                        if (d.F) {
                          Object.keys(d.F).map((f) => {
                            d.F[f] = eval("(" + d.F[f] + ")");
                          });
                        }
                      });

                      dispatch(setDocs(newDoc));
                      dispatch(setIndexPath(forms[newValue].indexPath));
                      dispatch(setRunStatus(forms[newValue].runStatus));
                      dispatch(setCategory(splitNewValue[0]));
                      dispatch(setName(splitNewValue[1]));
                      setTimeout(() => {
                        navigate(`/${role}/EF/${value}`);
                      }, 0);
                    }
                  }
                }}
                width={250}
              />
            )}
          </>
        ) : null}
        {userInfo?.role !== "Operator" ? (
          <>
            <div className="flex">
              <button
                className={`border w-[50px] ${
                  dev ? "bg-cyan-950 text-light-500" : ""
                }`}
                onClick={() => dispatch(setDev())}
              >
                D
              </button>
            </div>
            <div className="flex">
              <button
                className={`border w-[50px] ${
                  minimal ? "bg-cyan-950 text-light-500" : ""
                }`}
                onClick={() => dispatch(setMinimal())}
              >
                M
              </button>
            </div>
            <div
              className={`flex gap-5 items-center w-5 ${
                currentPath === "DC" ? "border-b-2" : ""
              } border-orange-500 cursor-pointer`}
              onClick={() => navigate(`${baseURL}/DC`)}
            >
              <img src={DocumentSVG} className="w-full" />
            </div>
            <div
              className={`flex gap-5 items-center w-5 ${
                currentPath === "IC" ? "border-b-2" : ""
              } border-orange-500 cursor-pointer text-black`}
              onClick={() => navigate(`${baseURL}/IC`)}
            >
              <BsFillFileEarmarkImageFill className="w-full text-xl" />
            </div>
            {dev ? (
              <button>
                <div
                  className="cursor-pointer hover:text-orange-500"
                  onClick={() => dispatch(toggleFunctionBar())}
                >
                  <CgMenuGridR className="h-[30px] w-[30px]" />
                </div>
              </button>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Navbar;
