import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../../utils/Input";
import { addSection } from "../../../../../../slices/docsSlice";

const AddSection = ({}) => {
  const dispatch = useDispatch();
  const { RRIP, RRIP_DOC } = useSelector((state) => state.docs);
  const [sectionName, setSectionName] = useState();
  return (
    <div className="flex gap-4 pb-4">
      <Input
        className="h-[40px] w-[250px]"
        value={sectionName}
        change={(e) => setSectionName(e.target.value)}
        placeholder="Enter Section Name"
      />
      <button
        className="px-3 py-1 bg-cyan-950 text-light-500"
        onClick={() => {
          dispatch(
            addSection({
              path: RRIP_DOC,
              name: sectionName,
            })
          );
          setSectionName("");
        }}
      >
        Add Section
      </button>
      <button
        className="px-3 py-1 bg-cyan-950 text-light-500"
        onClick={() => {
          dispatch(
            addSection({
              path: RRIP,
              loc: "after",
              name: sectionName,
            })
          );
          setSectionName("");
        }}
      >
        After
      </button>
      <button
        className="px-3 py-1 bg-cyan-950 text-light-500"
        onClick={() => {
          dispatch(
            addSection({
              path: RRIP,
              loc: "before",
              name: sectionName,
            })
          );
          setSectionName("");
        }}
      >
        Before
      </button>
    </div>
  );
};

export default AddSection;
