import React, { useState, useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";
import Input from "../../../../utils/Input";
import TextArea from "../../../../utils/TextArea";
import Button from "../../../../utils/Button";
import { useDispatch } from "react-redux";
import {
  removeUserInput,
  updateUserCondition,
  updateInputCaps,
  updateDefaultValue,
} from "../../../../slices/entryForm";

import CodeMirror from "@uiw/react-codemirror";
import { autocompletion } from "@codemirror/autocomplete";
import { javascript } from "@codemirror/lang-javascript";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";

const CTextArea = ({ opInput, operator }) => {
  const [dv, setDv] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setDv(opInput.condition);
  }, [opInput]);

  return (
    <div className="flex flex-col gap-2 bg-blue-300 p-2 rounded w-full relative">
      <div
        className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
        onClick={() =>
          dispatch(
            removeUserInput({
              name: opInput.placeholder,
              operator: operator,
            })
          )
        }
      >
        <IoCloseSharp />
      </div>
      <div>
        <CodeMirror
          className="text-sm"
          value={dv}
          theme={vscodeDark}
          extensions={[javascript({ jsx: true }), autocompletion()]}
          onChange={(value) => {
            setDv(value);
          }}
          onBlur={() => {
            dispatch(
              updateUserCondition({
                operator: operator,
                name: opInput.placeholder,
                condition: dv,
              })
            );
          }}
        />
      </div>
      <div className="flex flex-wrap items-center gap-4">
        <div>
          <h2 className="pb-1">
            {opInput.placeholder} ({opInput.element})
          </h2>
          <TextArea
            type="text"
            placeholder="default value"
            value={opInput.defaultValue}
            change={(e) => {
              let value = e.target.value;
              if (opInput.caps) {
                value = value.toUpperCase();
              }
              dispatch(
                updateDefaultValue({
                  name: opInput.placeholder,
                  operator: operator,
                  value: value,
                })
              );
            }}
          />
        </div>
        <div>
          <Button
            name="All Caps"
            value={opInput.caps}
            click={(value) => {
              dispatch(
                updateInputCaps({
                  name: opInput.placeholder,
                  operator: operator,
                  value: value,
                })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CTextArea;
