export const TEXT = {
  t: "",
  properties: {},
  type: "text",
};

export const CHECKBOX = {
  properties: {
    fontSize: "12pt",
    checked: true,
    checkedState: {
      value: "2611",
      font: "Wingdings",
    },
    uncheckedState: {
      value: "2610",
      font: "Wingdings",
    },
  },
  type: "checkbox",
};

export const SYMBOL = {
  char: "",
  properties: {},
  type: "symbol",
};

export const PARA = {
  ele: [],
  properties: {
    text: {},
  },
  name: 0,
  type: "para",
};

export const CELL = {
  ele: [],
  properties: {
    para: {},
    text: {},
  },
  name: "",
  type: "cell",
};

export const ROW = {
  ele: [],
  properties: {
    cell: {},
    para: {},
    text: {},
  },
  name: "",
  type: "row",
};

export const TABLE = {
  ele: [],
  properties: {
    row: {},
    cell: {},
    para: {},
    text: {},
  },
  name: "",
  type: "table",
};

export const SECTION = {
  ele: [],
  properties: {
    page: {
      size: {
        orientation: "portrait",
      },
      margin: {
        left: "0.8in",
        right: "0.8in",
        top: "0.5in",
        bottom: "0.5in",
      },
      pageWidth: "8.27in",
      pageHeight: "11.69in",
      borders: {
        pageBorderLeft: {
          style: "single",
          color: "000000",
          size: 1,
          space: 20,
        },
        pageBorderRight: {
          style: "single",
          color: "000000",
          size: 1,
          space: 20,
        },
        pageBorderTop: {
          style: "single",
          color: "000000",
          size: 1,
          space: 1,
        },
        pageBorderBottom: {
          style: "single",
          color: "000000",
          size: 1,
          space: 1,
        },
      },
    },
    column: 1,
    type: "continuous",
    table: {},
    row: {},
    cell: {},
    para: {},
    text: {},
  },
  headers: {},
  footers: {},
  name: "",
  type: "section",
};
