import React, { useEffect, useState, useRef } from "react";
import { parse } from "parse5";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../utils/Input";
import { setSingleForm } from "../../slices/formsSlice";
import { toast } from "react-toastify";
// import { setCibil } from "../features/testSlice";
import CibilStructure from "./components/CibilStructure";

const CIBIL = () => {
  const fileInputRef = useRef(null);
  const { userInfo } = useSelector((state) => state.auth);
  const [noOfCibils, setNoOfCibils] = useState(1);
  const [cibil, setCibil] = useState([]);
  const { forms } = useSelector((state) => state.forms);
  const dispatch = useDispatch();

  const handleFileUpload = (e) => {
    e.preventDefault();
    let customerFile = e.target.files;
    let fileReader = new FileReader();

    fileReader.readAsText(customerFile[0]);
    fileReader.onload = (e) => {
      let data = e.target.result;
      let doc = parse(data);
      let newCibil = cibil ? [...cibil] : [];
      let record = {};
      doc.childNodes[1].childNodes[1].childNodes[0].childNodes[0].childNodes.map(
        (ele, index) => {
          if (index === 1) {
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4, index1) => {
                    if (index1 === 1) {
                      el4.childNodes.map((el5, index2) => {
                        if (index2 === 0) {
                          el5.childNodes.map((el6) => {
                            el6.childNodes.map((el7) => {
                              el7.childNodes.map((el8, index3) => {
                                if (index3 === 0) {
                                  el8.childNodes.map((el9, index4) => {
                                    if (index4 === 1) {
                                      let value = el9.childNodes[0].value;
                                      if (typeof value === "string") {
                                        record.name = value.trim();
                                      } else {
                                        record.name = value;
                                      }
                                      // updateCibil(el9.childNodes[0].value);
                                      // // updateCibil('');
                                      // updateDetails(el9.childNodes[0].value);
                                    }
                                  });
                                }
                              });
                            });
                          });
                        } else if (index2 === 2) {
                          el5.childNodes.map((el6) => {
                            el6.childNodes.map((el7) => {
                              el7.childNodes.map((el8, index5) => {
                                if (index5 === 0) {
                                  el8.childNodes.map((el9, index6) => {
                                    if (index6 === 1) {
                                      let value = el9.childNodes[0].value;
                                      if (typeof value === "string") {
                                        record.controlReportDate = value.trim();
                                      } else {
                                        record.controlReportDate = value;
                                      }
                                      // updateCibil(el9.childNodes[0].value);
                                      // updateDetails(el9.childNodes[0].value);
                                    }
                                  });
                                } else if (index5 === 2) {
                                  el8.childNodes.map((el9, index6) => {
                                    if (index6 === 1) {
                                      let value = el9.childNodes[0].value;
                                      if (typeof value === "string") {
                                        record.controlNumber = value.trim();
                                      } else {
                                        record.controlNumber = value;
                                      }
                                      // updateCibil(el9.childNodes[0].value);
                                      // updateDetails(el9.childNodes[0].value);
                                    }
                                  });
                                }
                              });
                            });
                          });
                        }
                      });
                    }
                  });
                });
              });
            });
          } else if (index === 4) {
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4, index1) => {
                    if (index1 === 1) {
                      el4.childNodes.map((el5, index2) => {
                        if (index2 === 0) {
                          el5.childNodes.map((el6) => {
                            el6.childNodes.map((el7) => {
                              el7.childNodes.map((el8, index3) => {
                                if (index3 === 1) {
                                  el8.childNodes.map((el9, index4) => {
                                    if (index4 === 1) {
                                      let value = el9.childNodes[0].value;
                                      // console.log(value);
                                      if (typeof value === "string") {
                                        record.dateOfBirth = value.trim();
                                      } else {
                                        record.dateOfBirth = value;
                                      }
                                      // updateDetails(el9.childNodes[0].value);
                                    }
                                  });
                                }
                              });
                            });
                          });
                        }
                      });
                    }
                  });
                });
              });
            });
          } else if (index === 8) {
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4) => {
                    // let CreditScore;
                    let CreditRemarks = [];
                    el4.childNodes.map((el5, index1) => {
                      if (index1 === 1) {
                        // CreditScore = el5.childNodes[0].value;
                        let value = el5.childNodes[0].value;
                        if (typeof value === "string") {
                          record.creditScore = value.trim();
                        } else {
                          record.creditScore = value;
                        }
                      }
                      if (index1 === 2) {
                        el5.childNodes[0].childNodes.map((remark) => {
                          CreditRemarks.push(remark.childNodes[0].value);
                        });
                      }
                    });
                    record.creditRemarks = CreditRemarks;
                    // console.log(record);
                    // updateCibil([CreditScore, CreditRemarks]);
                    // updateCibil(CreditScore);
                    // updateCibil(CreditRemarks);
                  });
                });
              });
            });
          } else if (index === 10) {
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4) => {
                    // let PersonalScore;
                    let PersonalRemarks = [];
                    el4.childNodes.map((el5, index1) => {
                      if (index1 === 1) {
                        // PersonalScore = el5.childNodes[0].value;
                        let value = el5.childNodes[0].value;
                        if (typeof value === "string") {
                          record.personalScore = value.trim();
                        } else {
                          record.personalScore = value;
                        }
                      }
                      if (index1 === 2) {
                        el5.childNodes[0].childNodes.map((remark) => {
                          PersonalRemarks.push(remark.childNodes[0].value);
                        });
                      }
                    });
                    record.personalRemarks = PersonalRemarks;
                    console.log(record);
                    // updateCibil([PersonalScore, PersonalRemarks]);
                    // updateCibil(PersonalScore);
                    // updateCibil(PersonalRemarks);
                  });
                });
              });
            });
          } else if (index === 19) {
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4, index1) => {
                    if (index1 === 2) {
                      if (
                        el4.childNodes[0].childNodes[0].value.trim() ===
                        "INCOME TAX ID NUMBER (PAN)"
                      ) {
                        record.pan = el4.childNodes[1].childNodes[0].value;
                        // updatePAN(el4.childNodes[1].childNodes[0].value);
                      } else {
                        record.pan = "";
                        // updatePAN("-");
                      }
                    }
                  });
                });
              });
            });
          } else if (index === 22) {
            // phone no
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  let phNo = "";
                  el3.childNodes.map((el4, index1) => {
                    if (index1 > 1) {
                      let numType = el4.childNodes[0].childNodes[0].value
                        .replace("\n", "")
                        .trim();
                      if (numType === "MOBILE PHONE" && phNo === "") {
                        record.phNo = el4.childNodes[1].childNodes[0].value;
                        // phNo = el4.childNodes[1].childNodes[0].value;
                      }
                    }
                  });
                  // updateDetails(phNo, "phNo");
                });
              });
            });
          } else if (index === 25) {
            // console.log(ele); // email Id
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4, index1) => {
                    console.log(el4);
                    if (index1 > 1) {
                      if (!record.email) {
                        record.email =
                          el4.childNodes[0].childNodes[0].value.toLowerCase();
                      }
                    }
                  });
                  // updateDetails(email);
                });
              });
            });
          } else if (index === 28) {
            // Address
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4, index1) => {
                    if (index1 === 1) {
                      el4.childNodes.map((el5) => {
                        el5.childNodes.map((el6, index2) => {
                          if (index2 === 1) {
                            el6.childNodes.map((el7) => {
                              let address = el7.value
                                .replace(/\s+/g, " ")
                                .trim();
                              if (address === "(e)") {
                                address =
                                  el3.childNodes[1].childNodes[0].childNodes[3].childNodes[0].value
                                    .replace(/\s+/g, " ")
                                    .trim();
                              }
                              address = address.replace(/\s,/g, ", ");
                              if (
                                address.substring(0, 4) === "SAME" &&
                                el3.childNodes[3] !== undefined
                              ) {
                                address =
                                  el3.childNodes[3].childNodes[0].childNodes[1].childNodes[0].value
                                    .replace(/\s+/g, " ")
                                    .trim();
                                if (address === "(e)") {
                                  address =
                                    el3.childNodes[3].childNodes[0].childNodes[3].childNodes[0].value
                                      .replace(/\s+/g, " ")
                                      .trim();
                                }
                                address = address.replace(/\s,/g, ", ");
                                record.address = address;
                                // updateDetails(address, "address");
                              } else {
                                record.address = address;
                                // updateDetails(address, "address");
                              }
                            });
                          }
                        });
                      });
                    }
                  });
                });
              });
            });
          } else if (index === 34) {
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4, index1) => {
                    if (index1 === 3) {
                      el4.childNodes.map((el5, index2) => {
                        // console.log(el5);
                        if (index2 === 1) {
                          record.noOfTotalAccounts = el5.childNodes[1].value;
                          // console.log(el5.childNodes[1].value);
                          // updateCibil(el5.childNodes[1].value);
                        } else if (index2 === 2) {
                          if (el5.childNodes[1] !== undefined) {
                            let HighCR = (
                              parseFloat(
                                el5.childNodes[1].value.replace(/,/g, "")
                              ) / 100000
                            ).toFixed(2);
                            record.highCreditSanctionAmt = HighCR;
                            // console.log(HighCR);
                            // updateCibil(HighCR);
                          } else {
                            record.highCreditSanctionAmt = "0.00";
                            // console.log("0.00");
                            // updateCibil("0.00");
                          }
                        } else if (index2 === 3) {
                          if (el5.childNodes[1] !== undefined) {
                            let CurrentBal = (
                              parseFloat(
                                el5.childNodes[1].value.replace(/,/g, "")
                              ) / 100000
                            ).toFixed(2);
                            record.currentBalance = CurrentBal;
                            // console.log(CurrentBal);
                            // updateCibil(CurrentBal);
                            // updateDetails(CurrentBal, "curr bal");
                          } else {
                            record.currentBalance = "0.00";
                            // console.log("0.00");
                            // updateCibil("0.00");
                            // updateDetails("0.00", "curr bal");
                          }
                        }
                      });
                    } else if (index1 === 4) {
                      el4.childNodes.map((el5, index2) => {
                        if (index2 === 1) {
                          record.noOfOverdueAccounts = el5.childNodes[1].value;
                          // console.log(el5.childNodes[1].value);
                          // updateCibil(el5.childNodes[1].value);
                        } else if (index2 === 3) {
                          if (el5.childNodes[1] !== undefined) {
                            let OverDueAmt = (
                              parseFloat(
                                el5.childNodes[1].value.replace(/,/g, "")
                              ) / 100000
                            ).toFixed(2);
                            record.overdueAmount = OverDueAmt;
                            // console.log(OverDueAmt);
                            // updateCibil(OverDueAmt);
                          } else {
                            record.overdueAmount = "0.00";
                            // console.log("0.00");
                            // updateCibil("0.00");
                          }
                        }
                      });
                    } else if (index1 === 5) {
                      el4.childNodes.map((el5, index2) => {
                        if (index2 === 1) {
                          record.zeroBalance = el5.childNodes[1].value;
                          // console.log(record);
                          // console.log(el5.childNodes[1].value);
                          // updateCibil(el5.childNodes[1].value);
                        }
                      });
                    }
                  });
                });
              });
            });
          } else if (index === 37) {
            record.accounts = [];
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4, index1) => {
                    // console.log(index1);
                    // if (index1 === 10) {
                    //   console.log(el4);
                    // }
                    if (
                      index1 === 3 ||
                      index1 === 10 ||
                      index1 === 17 ||
                      index1 === 24 ||
                      index1 === 31 ||
                      index1 === 38 ||
                      index1 === 45 ||
                      index1 === 52 ||
                      index1 === 59 ||
                      index1 === 66 ||
                      index1 === 73 ||
                      index1 === 80 ||
                      index1 === 87 ||
                      index1 === 94 ||
                      index1 === 101 ||
                      index1 === 108 ||
                      index1 === 115 ||
                      index1 === 122 ||
                      index1 === 129 ||
                      index1 === 136 ||
                      index1 === 143 ||
                      index1 === 150 ||
                      index1 === 157 ||
                      index1 === 164 ||
                      index1 === 171 ||
                      index1 === 178 ||
                      index1 === 185 ||
                      index1 === 192 ||
                      index1 === 199 ||
                      index1 === 206
                    ) {
                      el4.childNodes.map((el5, index2) => {
                        if (index2 === 2) {
                          el5.childNodes.map((el6) => {
                            if (el6.childNodes !== undefined) {
                              el6.childNodes.map((el7) => {
                                el7.childNodes.map((el8, index3) => {
                                  if (
                                    el8.childNodes[0].childNodes[0] !==
                                    undefined
                                  ) {
                                    if (
                                      el8.childNodes[0].childNodes[0]
                                        .childNodes[0].value ===
                                      "CURRENT BALANCE:"
                                    ) {
                                      if (
                                        el8.childNodes[0].childNodes[1]
                                          .value !== "0"
                                      ) {
                                        let sanctionAmt;
                                        let overdue = "0";
                                        if (
                                          el4.childNodes[0].childNodes[0]
                                            .childNodes[0].childNodes[2]
                                            .childNodes[0].childNodes[1]
                                            .value === "CREDIT CARD"
                                        ) {
                                          if (
                                            el4.childNodes[2].childNodes[0]
                                              .childNodes[0].childNodes[0]
                                              .childNodes[0].childNodes[0]
                                              .childNodes[0].value ===
                                            "HIGH-CREDIT:"
                                          ) {
                                            sanctionAmt =
                                              el4.childNodes[2].childNodes[0]
                                                .childNodes[0].childNodes[0]
                                                .childNodes[0].childNodes[1]
                                                .value;
                                          } else {
                                            sanctionAmt = "Dummy";
                                          }
                                        } else {
                                          sanctionAmt =
                                            el4.childNodes[2].childNodes[0]
                                              .childNodes[0].childNodes[0]
                                              .childNodes[0].childNodes[1]
                                              .value;
                                        }

                                        // console.log(
                                        //   el4.childNodes[2].childNodes[0]
                                        //     .childNodes[0]
                                        // );
                                        // OverDue
                                        if (
                                          el4.childNodes[2].childNodes[0]
                                            .childNodes[0].childNodes[2]
                                        ) {
                                          if (
                                            el4.childNodes[2].childNodes[0]
                                              .childNodes[0].childNodes[2]
                                              .childNodes[0].childNodes[0] !==
                                            undefined
                                          ) {
                                            if (
                                              el4.childNodes[2].childNodes[0]
                                                .childNodes[0].childNodes[2]
                                                .childNodes[0].childNodes[0]
                                                .childNodes[0].value ===
                                              "OVERDUE:"
                                            ) {
                                              overdue =
                                                el4.childNodes[2].childNodes[0]
                                                  .childNodes[0].childNodes[2]
                                                  .childNodes[0].childNodes[1]
                                                  .value;
                                            }
                                          }
                                        }

                                        record.accounts.push([
                                          [
                                            el4.childNodes[0].childNodes[0]
                                              .childNodes[0].childNodes[2]
                                              .childNodes[0].childNodes[1]
                                              .value,
                                            el4.childNodes[0].childNodes[0]
                                              .childNodes[0].childNodes[1]
                                              .childNodes[0].childNodes[1]
                                              .value,
                                          ],
                                          el4.childNodes[0].childNodes[0]
                                            .childNodes[0].childNodes[3]
                                            .childNodes[0].childNodes[1].value,
                                          el4.childNodes[1].childNodes[0]
                                            .childNodes[0].childNodes[0]
                                            .childNodes[0].childNodes[1].value,
                                          // sanctionAmt,
                                          (
                                            parseFloat(
                                              sanctionAmt.replace(/,/g, "")
                                            ) / 100000
                                          ).toFixed(2),
                                          (
                                            parseFloat(
                                              el8.childNodes[0].childNodes[1].value.replace(
                                                /,/g,
                                                ""
                                              )
                                            ) / 100000
                                          ).toFixed(2),
                                          // el8.childNodes[0].childNodes[1].value,
                                          // overdue,
                                          (
                                            parseFloat(
                                              overdue.replace(/,/g, "")
                                            ) / 100000
                                          ).toFixed(2),
                                        ]);
                                      }
                                    }
                                  }
                                });
                                // console.log('Break');
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                });
              });
            });
          }
          // console.log(ele);
        }
      );
      newCibil.push(record);
      setCibil(newCibil);
    };
    fileInputRef.current.value = null;
  };

  const submitHandler = () => {
    dispatch(
      setSingleForm({
        name: "Cibil",
        value: cibil,
      })
    );
    toast.success("Cibil Added Succesfully.");
  };

  useEffect(() => {
    setCibil(forms.Cibil);
  }, [forms.Cibil]);

  return (
    <div className="psm">
      <h1 className="pb-1">No. of Cibil Reports</h1>
      <Input
        type="number"
        value={noOfCibils}
        change={(e) => setNoOfCibils(parseFloat(e.target.value))}
      />
      <div className="mt-2">
        <div className="flex gap-10 bg-gray-100 p-6 rounded-lg">
          <div className="flex flex-col gap-2">
            <div className="flex items-center space-x-4">
              <label
                htmlFor="customerFile"
                className="inline-block bg-cyan-800 text-white px-6 py-2 cursor-pointer w-[300px] text-center"
              >
                Upload .html File here
              </label>
              <input
                type="file"
                id="customerFile"
                ref={fileInputRef}
                className="hidden"
                onChange={handleFileUpload}
              />
              <label htmlFor="customerFile" className="cursor-pointer">
                Upload File
              </label>
            </div>
          </div>

          <div>
            {Array.from({ length: noOfCibils }).map((_, i) => {
              return (
                <div>
                  <h1>
                    {i + 1}. {cibil ? cibil[i]?.name : ""}
                  </h1>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <input
            type="submit"
            value="Submit"
            className="bg-cyan-950 text-light-500 px-2 py-1 cursor-pointer"
            onClick={submitHandler}
          />
        </div>
      </div>
      {cibil?.length > 0 && userInfo.role === "Manager" ? (
        <CibilStructure cibil={cibil} />
      ) : null}
    </div>
  );
};

export default CIBIL;
