import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCheckboxToPara } from "../../../../../../slices/docsSlice";

const AddCheckbox = ({ SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_P } = useSelector((state) => state.docs);

  return (
    // <div className="flex gap-4">
    <div>
      <h2 className="pb-1">Add Checkbox</h2>
      <button
        className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
        onClick={() => {
          dispatch(
            addCheckboxToPara({
              para: RRIP_P,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
      >
        Checkbox
      </button>
    </div>
    // </div>
  );
};

export default AddCheckbox;
