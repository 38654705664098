import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTableCellMargin } from "../../../../../../slices/docsSlice";
import Input from "../../../../../../utils/Input";

const MarginBottom = ({ marginBottom, setMarginBottom, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TB, RRIP_DOC } = useSelector((state) => state.docs);

  return (
    <div>
      <h2 className="pb-1">Bottom Margin</h2>
      <Input
        className="h-[40px] w-[150px]"
        value={marginBottom}
        placeholder="bottom margin"
        blur={(e) =>
          dispatch(
            setTableCellMargin({
              loc: "bottom",
              doc: RRIP_DOC,
              table: RRIP_TB,
              value: e.target.value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          )
        }
        change={(e) => setMarginBottom(e.target.value)}
        type="text"
      />
    </div>
  );
};

export default MarginBottom;
