import { createSlice } from "@reduxjs/toolkit";
import { consoleFun } from "./Docs/functions";

const initialState = {
  entryForm: undefined,
  filledEF: undefined,
};

const entryFormSlice = createSlice({
  name: "entryForm",
  initialState,
  reducers: {
    setEFandFilledEF: (state, { payload }) => {
      state.entryForm = payload.entryForm;
      state.filledEF = payload.filledEF;
    },
    setEntryForm: (state, { payload }) => {
      state.entryForm = payload;
    },
    setFilledEF: (state, { payload }) => {
      state.filledEF = payload;
    },
    addUserInput: (state, { payload }) => {
      switch (payload.element) {
        case "input":
          if (payload.cluster) {
            if (payload.nesCluster) {
              state.entryForm[payload.operator][payload.cluster].row[
                payload.index
              ].row.push({
                type: "text",
                placeholder: payload.name,
                defaultValue: "",
                element: payload.element,
              });
            } else {
              state.entryForm[payload.operator][payload.cluster].row.push({
                type: "text",
                placeholder: payload.name,
                defaultValue: "",
                element: payload.element,
              });
            }
          } else {
            state.entryForm[payload.operator][payload.name] = {
              type: "text",
              placeholder: payload.name,
              defaultValue: "",
              element: payload.element,
            };
          }
          break;
        case "textarea":
          if (payload.cluster) {
            if (payload.nesCluster) {
              state.entryForm[payload.operator][payload.cluster].row[
                payload.index
              ].row.push({
                placeholder: payload.name,
                defaultValue: "",
                element: payload.element,
              });
            } else {
              state.entryForm[payload.operator][payload.cluster].row.push({
                placeholder: payload.name,
                defaultValue: "",
                element: payload.element,
              });
            }
          } else {
            state.entryForm[payload.operator][payload.name] = {
              placeholder: payload.name,
              defaultValue: "",
              element: payload.element,
            };
          }
          break;
        case "dropdown":
          if (payload.cluster) {
            if (payload.nesCluster) {
              state.entryForm[payload.operator][payload.cluster].row[
                payload.index
              ].row.push({
                placeholder: payload.name,
                options: payload.options,
                defaultValue: payload.options[0],
                element: payload.element,
              });
            } else {
              state.entryForm[payload.operator][payload.cluster].row.push({
                placeholder: payload.name,
                options: payload.options,
                defaultValue: payload.options[0],
                element: payload.element,
              });
            }
          } else {
            state.entryForm[payload.operator][payload.name] = {
              placeholder: payload.name,
              options: payload.options,
              defaultValue: payload.options[0],
              element: payload.element,
            };
          }
          break;
        case "date":
          if (payload.cluster) {
            if (payload.nesCluster) {
              state.entryForm[payload.operator][payload.cluster].row[
                payload.index
              ].row.push({
                placeholder: payload.name,
                defaultValue: "",
                element: payload.element,
              });
            } else {
              state.entryForm[payload.operator][payload.cluster].row.push({
                placeholder: payload.name,
                defaultValue: "",
                element: payload.element,
              });
            }
          } else {
            state.entryForm[payload.operator][payload.name] = {
              placeholder: payload.name,
              defaultValue: "",
              element: payload.element,
            };
          }
          break;
        case "conditional_input":
          state.entryForm[payload.operator][payload.name] = {
            type: "text",
            placeholder: payload.name,
            defaultValue: "",
            element: payload.element,
            condition: "() => {\n  \n  return false\n}",
          };
          break;
        case "conditional_textarea":
          state.entryForm[payload.operator][payload.name] = {
            placeholder: payload.name,
            defaultValue: "",
            element: payload.element,
            condition: "() => {\n  \n  return false\n}",
          };
          break;
        case "conditional_dropdown":
          state.entryForm[payload.operator][payload.name] = {
            placeholder: payload.name,
            options: payload.options,
            defaultValue: payload.options[0],
            element: payload.element,
            condition: "() => {\n  \n  return false\n}",
          };
          break;
        case "conditional_date":
          state.entryForm[payload.operator][payload.name] = {
            placeholder: payload.name,
            element: payload.element,
            condition: "() => {\n  \n  return false\n}",
          };
          break;
        case "conditional_multi":
          state.entryForm[payload.operator][payload.name] = {
            row: [],
            cluster: payload.name,
            element: payload.element,
            condition: "() => {\n  \n  return false\n}",
          };
          break;
        case "multi":
          if (payload.cluster) {
            state.entryForm[payload.operator][payload.cluster].row.push({
              row: [],
              cluster: payload.name,
              element: payload.element,
            });
          } else {
            state.entryForm[payload.operator][payload.name] = {
              row: [],
              cluster: payload.name,
              element: payload.element,
            };
          }
          break;
      }
    },
    updateDefaultValue: (state, { payload }) => {
      const { operator, index, cluster, name, value, index1 } = payload;
      if (cluster) {
        if (index1 !== undefined) {
          state.entryForm[operator][cluster].row[index].row[
            index1
          ].defaultValue = value;
        } else {
          state.entryForm[operator][cluster].row[index].defaultValue = value;
        }
      } else {
        state.entryForm[operator][name].defaultValue = value;
      }
    },
    updateInputType: (state, { payload }) => {
      const { operator, index, cluster, name, value, index1 } = payload;
      if (cluster) {
        if (index1 !== undefined) {
          state.entryForm[operator][cluster].row[index].row[index1].type =
            value;
        } else {
          state.entryForm[operator][cluster].row[index].type = value;
        }
      } else {
        state.entryForm[operator][name].type = value;
      }
    },
    updateInputCaps: (state, { payload }) => {
      const { operator, index, cluster, name, value, index1 } = payload;
      if (cluster) {
        if (index1 !== undefined) {
          state.entryForm[operator][cluster].row[index].row[index1].caps =
            value;
          if (!value) {
            state.entryForm[operator][cluster].row[index].row[
              index1
            ].caps = true;
          } else {
            state.entryForm[operator][cluster].row[index].row[
              index1
            ].caps = false;
          }
        } else {
          state.entryForm[operator][cluster].row[index].caps = value;
          if (!value) {
            state.entryForm[operator][cluster].row[index].caps = true;
          } else {
            state.entryForm[operator][cluster].row[index].caps = false;
          }
        }
      } else {
        state.entryForm[operator][name].caps = value;
        if (!value) {
          state.entryForm[operator][name].caps = true;
        } else {
          state.entryForm[operator][name].caps = false;
        }
      }
    },
    updateInputLength: (state, { payload }) => {
      const { operator, index, cluster, name, value, index1 } = payload;
      if (cluster) {
        if (index1 !== undefined) {
          if (value === "") {
            delete state.entryForm[operator][cluster].row[index].row[index1]
              .maxLength;
          } else {
            state.entryForm[operator][cluster].row[index].row[
              index1
            ].maxLength = value;
          }
        } else {
          if (value === "") {
            delete state.entryForm[operator][cluster].row[index].maxLength;
          } else {
            state.entryForm[operator][cluster].row[index].maxLength = value;
          }
        }
      } else {
        if (state.entryForm[operator][name].maxLength) {
          state.entryForm[operator][name].maxLength = value;
        } else {
          state.entryForm[operator][name].maxLength = value;
        }
      }
    },
    updateInputUnit: (state, { payload }) => {
      const { operator, index, cluster, name, value, index1 } = payload;
      if (cluster) {
        if (index1 !== undefined) {
          if (value === "none") {
            delete state.entryForm[operator][cluster].row[index].row[index1]
              .unit;
          } else {
            state.entryForm[operator][cluster].row[index].row[index1].unit =
              value;
          }
        } else {
          if (value === "none") {
            delete state.entryForm[operator][cluster].row[index].unit;
          } else {
            state.entryForm[operator][cluster].row[index].unit = value;
          }
        }
      } else {
        if (value === "none") {
          delete state.entryForm[operator][name].unit;
        } else {
          state.entryForm[operator][name].unit = value;
        }
      }
    },

    updateUserCondition: (state, { payload }) => {
      state.entryForm[payload.operator][payload.name].condition =
        payload.condition;
    },
    removeUserInput: (state, { payload }) => {
      const { cluster, index, operator, name, index1 } = payload;
      if (cluster) {
        if (index1 !== undefined) {
          state.entryForm[operator][cluster].row[index].row.splice(index1, 1);
        } else {
          state.entryForm[operator][cluster].row.splice(index, 1);
        }
      } else {
        delete state.entryForm[operator][name];
      }
    },
    updateNoOfRows: (state, { payload }) => {
      const { operator, cluster, rows } = payload;
      let arr = [];
      for (let i = 0; i < rows; i++) {
        if (state.filledEF[operator][cluster][i]) {
          arr.push(state.filledEF[operator][cluster][i]);
        } else {
          let row = state.filledEF[operator][cluster][0];
          arr.push(row);
        }
      }
      state.filledEF[operator][cluster] = arr;
    },
    updateNesNoOfRows: (state, { payload }) => {
      const { operator, cluster, rows, i, j } = payload;
      let arr = [];
      for (let k = 0; k < rows; k++) {
        if (state.filledEF[operator][cluster][i][j][k]) {
          arr.push(state.filledEF[operator][cluster][i][j][k]);
        } else {
          let row = state.filledEF[operator][cluster][i][j][0];
          arr.push(row);
        }
      }
      state.filledEF[operator][cluster][i][j] = arr;
    },
    updateFEF: (state, { payload }) => {
      const { operator, cluster, name, value, i, j, k, l } = payload;
      if (cluster) {
        if (k !== undefined) {
          state.filledEF[operator][cluster][i][j][k][l] = value;
        } else {
          state.filledEF[operator][cluster][i][j] = value;
        }
      } else {
        state.filledEF[operator][name] = value;
      }
    },
  },
});

export const {
  setEFandFilledEF,
  setEntryForm,
  setFilledEF,
  addUserInput,
  updateDefaultValue,
  updateInputType,
  updateInputCaps,
  updateInputLength,
  updateInputUnit,
  updateUserCondition,
  removeUserInput,
  updateNoOfRows,
  updateNesNoOfRows,
  updateFEF,
  // XT Properties
  addXTInput,
  addXTDropDown,
  updateXTName,
  updateXTDv,
  removeXT,
  // MXT Properties
  addCondition,
  addMXTInput,
  addMXTDropDown,
  updateMXTName,
  updateMXTDv,
  removeMXT,
} = entryFormSlice.actions;

export default entryFormSlice.reducer;
