import React from "react";
import Dropdown from "../../../../utils/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { updateFEF } from "../../../../slices/entryForm";
import { updateFFEF } from "../../../../slices/formsSlice";

const CFDropdown = ({ ele, operator }) => {
  const dispatch = useDispatch();
  const { dev, category, name } = useSelector((state) => state.docs);
  const { filledEF } = useSelector((state) => state.entryForm);
  const { userInfo } = useSelector((state) => state.auth);
  return (
    <div>
      <h2 className="pb-1">{ele.placeholder}</h2>
      <Dropdown
        height={40}
        name={ele.placeholder}
        value={filledEF[operator][ele.placeholder]}
        options={ele.options}
        target="option"
        click={(value) => {
          if (userInfo.role === "Manager") {
            let text = `${category}$${name}_EF['${operator}']['${ele.placeholder}']`;
            navigator.clipboard.writeText(text);
          }

          dispatch(
            updateFEF({
              operator: operator,
              name: ele.placeholder,
              value: value,
            })
          );

          dispatch(
            updateFFEF({
              EF: `${category}$${name}_EF`,
              name: ele.placeholder,
              value: value,
              operator,
            })
          );
        }}
      />
    </div>
  );
};

export default CFDropdown;
