import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTableTextFontSize } from "../../../../../../../slices/docsSlice";
import Input from "../../../../../../../utils/Input";

const TextSize = ({ fontSize, setFontSize, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TB } = useSelector((state) => state.docs);

  return (
    <div>
      <h2 className="pb-1">Set Text Size</h2>
      <Input
        className="h-[40px] w-[100px]"
        value={fontSize}
        type="text"
        blur={(e) =>
          dispatch(
            setTableTextFontSize({
              table: RRIP_TB,
              value: e.target.value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          )
        }
        change={(e) => setFontSize(e.target.value)}
      />
    </div>
  );
};

export default TextSize;
