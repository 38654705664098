import { get } from "lodash";
export const switchVerAlignment = (value) => {
  switch (value) {
    case "start":
      return "START";
    case "center":
      return "CENTER";
    case "end":
      return "END";
    case "both":
      return "JUSTIFIED";
    case "mediumKashida":
      return "MEDIUM_KASHIDA";
    case "distribute":
      return "DISTRIBUTE";
    case "numTab":
      return "NUM_TAB";
    case "highKashida":
      return "HIGH_KASHIDA";
    case "lowKashida":
      return "LOW_KASHIDA";
    case "thaiDistribute":
      return "THAI_DISTRIBUTE";
    case "left":
      return "LEFT";
    case "right":
      return "RIGHT";
  }
};

export const switchShadeType = (value) => {
  switch (value) {
    case "solid":
      return "SOLID";
    case "clear":
      return "CLEAR";
    case "diagCross":
      return "DIAGONAL_CROSS";
    case "diagStripe":
      return "DIAGONAL_STRIPE";
    case "horzCross":
      return "HORIZONTAL_CROSS";
    case "horzStripe":
      return "HORIZONTAL_STRIPE";
    case "nil":
      return "NIL";
    case "pct5":
      return "PERCENT_5";
    case "pct10":
      return "PERCENT_10";
    case "pct12":
      return "PERCENT_12";
    case "pct15":
      return "PERCENT_15";
    case "pct20":
      return "PERCENT_20";
    case "pct25":
      return "PERCENT_25";
    case "pct30":
      return "PERCENT_30";
    case "pct35":
      return "PERCENT_35";
    case "pct37":
      return "PERCENT_37";
    case "pct40":
      return "PERCENT_40";
    case "pct45":
      return "PERCENT_45";
    case "pct50":
      return "PERCENT_50";
    case "pct55":
      return "PERCENT_55";
    case "pct60":
      return "PERCENT_60";
    case "pct62":
      return "PERCENT_62";
    case "pct65":
      return "PERCENT_65";
    case "pct70":
      return "PERCENT_70";
    case "pct75":
      return "PERCENT_75";
    case "pct80":
      return "PERCENT_80";
    case "pct85":
      return "PERCENT_85";
    case "pct87":
      return "PERCENT_87";
    case "pct90":
      return "PERCENT_90";
    case "pct95":
      return "PERCENT_95";
    case "reverseDiagStripe":
      return "REVERSE_DIAGONAL_STRIPE";
    case "thinDiagCross":
      return "THIN_DIAGONAL_CROSS";
    case "thinDiagStripe":
      return "THIN_DIAGONAL_STRIPE";
    case "thinHorzCross":
      return "THIN_HORIZONTAL_CROSS";
    case "thinReverseDiagStripe":
      return "THIN_REVERSE_DIAGONAL_STRIPE";
    case "thinVertStripe":
      return "THIN_VERTICAL_STRIPE";
    case "vertStripe":
      return "VERTICAL_STRIPE";
  }
};

export const switchWidthType = (value) => {
  switch (value) {
    case "dxa":
      return "DXA";
    case "pct":
      return "PERCENTAGE";
    case "nil":
      return "NIL";
  }
};

export const returnPropEle = (docs, path) => {
  const splitIndexPath = path;
  let newIndexPath = splitIndexPath
    .map((index) => index.split("_")[0])
    .join("_");

  let mainEle = null;
  let conditionMet = false;

  const setProperty = (ele, currIndex, currPath = "") => {
    const newPath =
      currPath !== "" ? `${currPath}_${currIndex}` : `${currIndex}`;
    if (!conditionMet) {
      if (newPath === newIndexPath) {
        mainEle = ele;
        conditionMet = true;
      } else if (ele.ele?.length > 0) {
        ele.ele.forEach((E, i) => setProperty(E, i, newPath));
      }
    }
  };

  docs.forEach((doc, i) => setProperty(doc, i));
  return mainEle;
};

export const returnPropHFEle = (docs, path, layoutEle) => {
  let elePath = "";
  path.map((ele, i) => {
    let SE = ele.split("_");
    if (SE[1] === "S") {
      elePath =
        elePath +
        `[${SE[0]}].${layoutEle === "0_H" ? "headers.ele" : "footers.ele"}`;
    } else if (SE[1] !== "H" && SE[1] !== "F") {
      elePath =
        elePath + (i === path.length - 1 ? `[${SE[0]}]` : `[${SE[0]}].ele`);
    }
  });
  console.log(elePath);
  console.log(get(docs, elePath));
  return get(docs, elePath);
};
