import { DxaToPt, cmToPx, convertToPx } from "../../UnitConversion";

export const getCellStyle = ({
  cellProps,
  rowProps,
  tableProps,
  sectionProps,
  docProps,
}) => {
  const style = {};
  if (docProps.cell?.borders) {
    style.borderLeft =
      docProps.cell.borders.left.style !== "none" ? "1px solid black" : "";
    style.borderRight =
      docProps.cell.borders.right.style !== "none" ? "1px solid black" : "";
    style.borderTop =
      docProps.cell.borders.top.style !== "none" ? "1px solid black" : "";
    style.borderBottom =
      docProps.cell.borders.bottom.style !== "none" ? "1px solid black" : "";
  }
  if (`${tableProps.borders}` !== "undefined") {
    style.border =
      tableProps.borders === true ? "1px solid black" : "2px dashed black";
  }
  if (`${cellProps.borders}` !== "undefined") {
    style.border =
      cellProps.borders === true ? "1px solid black" : "2px dashed blue";
  }

  if (docProps.table?.margins) {
    style.paddingLeft = convertToPx(docProps.table.margins.left);
    style.paddingRight = convertToPx(docProps.table.margins.right);
    style.paddingTop = convertToPx(docProps.table.margins.top);
    style.paddingBottom = convertToPx(docProps.table.margins.bottom);
  }
  if (tableProps.margins) {
    style.paddingLeft = convertToPx(tableProps.margins.left);
    style.paddingRight = convertToPx(tableProps.margins.right);
    style.paddingTop = convertToPx(tableProps.margins.top);
    style.paddingBottom = convertToPx(tableProps.margins.bottom);
  }
  if (cellProps.margins) {
    style.paddingLeft = convertToPx(cellProps.margins.left);
    style.paddingRight = convertToPx(cellProps.margins.right);
    style.paddingTop = convertToPx(cellProps.margins.top);
    style.paddingBottom = convertToPx(cellProps.margins.bottom);
  }

  if (docProps.verticalAlignment) {
    style.verticalAlignment = docProps.verticalAlignment;
  }

  if (rowProps.height) {
    style.lineHeight = `${cmToPx(rowProps.height.value)}px`;
  }

  if (rowProps.width) {
    style.display = "table-cell";
    style.width = convertToPx(rowProps.width.size);
  }
  style.minWidth = "10px";
  if (cellProps.width) {
    style.display = "table-cell";
    style.width = convertToPx(cellProps.width.size);
  }

  if (cellProps.shading) {
    style.backgroundColor = `#${cellProps.shading.color}`;
    style.color = `#${cellProps.shading.fill}`;
  }

  return style;
};
