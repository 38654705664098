import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTableCellMargin } from "../../../../../../slices/docsSlice";
import Input from "../../../../../../utils/Input";

const MarginTop = ({ marginTop, setMarginTop, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TB, RRIP_DOC } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">Top Margin</h2>
      <Input
        className="h-[40px] w-[150px]"
        value={marginTop}
        placeholder="top margin"
        blur={(e) =>
          dispatch(
            setTableCellMargin({
              loc: "top",
              doc: RRIP_DOC,
              table: RRIP_TB,
              value: e.target.value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          )
        }
        change={(e) => setMarginTop(e.target.value)}
        type="text"
      />
    </div>
  );
};

export default MarginTop;
