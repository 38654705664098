import React from "react";
import { addUserInput } from "../../../slices/entryForm";
import { useDispatch, useSelector } from "react-redux";

const EFButtons = ({ operator }) => {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col gap-2">
      <h2>{operator.toUpperCase()} Controls</h2>
      <div className="flex gap-2 flex-wrap">
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => {
            const inputKey = prompt("Enter Input Key: ");
            dispatch(
              addUserInput({
                name: inputKey,
                operator: operator,
                element: "input",
              })
            );
          }}
        >
          Add Input
        </button>
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => {
            const inputKey = prompt("Enter Input Key: ");
            dispatch(
              addUserInput({
                name: inputKey,
                operator: operator,
                element: "textarea",
              })
            );
          }}
        >
          Add Text Area
        </button>
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => {
            const inputKey = prompt("Enter Input Key: ");
            let noOfVal = prompt("No. of Inputs");
            let valArr = [];
            for (let i = 0; i < noOfVal; i++) {
              let val = prompt(`Input ${i + 1}`);
              valArr.push(val);
            }
            dispatch(
              addUserInput({
                name: inputKey,
                operator: operator,
                element: "dropdown",
                options: valArr,
              })
            );
          }}
        >
          Add Drop Down
        </button>
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => {
            const inputKey = prompt("Enter Input Key: ");
            dispatch(
              addUserInput({
                name: inputKey,
                operator: operator,
                element: "date",
              })
            );
          }}
        >
          Add Date Picker
        </button>
      </div>
      <div className="flex gap-2 flex-wrap">
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => {
            const inputKey = prompt("Enter Input Key: ");
            dispatch(
              addUserInput({
                name: inputKey,
                operator: operator,
                element: "conditional_input",
              })
            );
          }}
        >
          Functional Input
        </button>
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => {
            const inputKey = prompt("Enter Input Key: ");
            dispatch(
              addUserInput({
                name: inputKey,
                operator: operator,
                element: "conditional_textarea",
              })
            );
          }}
        >
          Functional Text Area
        </button>
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => {
            const inputKey = prompt("Enter Input Key: ");
            let noOfVal = prompt("No. of Inputs");
            let valArr = [];
            for (let i = 0; i < noOfVal; i++) {
              let val = prompt(`Input ${i + 1}`);
              valArr.push(val);
            }
            dispatch(
              addUserInput({
                name: inputKey,
                operator: operator,
                element: "conditional_dropdown",
                options: valArr,
              })
            );
          }}
        >
          Functional Drop Down
        </button>
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => {
            const inputKey = prompt("Enter Input Key: ");
            dispatch(
              addUserInput({
                name: inputKey,
                operator: operator,
                element: "conditional_date",
              })
            );
          }}
        >
          Functional Date Picker
        </button>
      </div>
      <div className="flex gap-2 flex-wrap">
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => {
            const inputKey = prompt("Enter Cluster Name: ");
            dispatch(
              addUserInput({
                name: inputKey,
                operator: operator,
                element: "multi",
              })
            );
          }}
        >
          Add Multiple Inputs
        </button>
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => {
            const inputKey = prompt("Enter Cluster Name: ");
            dispatch(
              addUserInput({
                name: inputKey,
                operator: operator,
                element: "conditional_multi",
              })
            );
          }}
        >
          Add Multi Functional
        </button>
      </div>
    </div>
  );
};

export default EFButtons;
