import React from "react";
import Input from "../../../../../utils/Input";
import { useSelector, useDispatch } from "react-redux";
import { updateFEF } from "../../../../../slices/entryForm";
import { updateFFEF } from "../../../../../slices/formsSlice";

const MDATE = ({ cluster, ele, operator, i, j, k, l }) => {
  const dispatch = useDispatch();
  const { filledEF } = useSelector((state) => state.entryForm);
  const { userInfo } = useSelector((state) => state.auth);
  const { dev, category, name } = useSelector((state) => state.docs);

  let dd, mm, yy;
  if (k !== undefined) {
    const value = filledEF[operator]?.[cluster]?.[i]?.[j]?.[k]?.[l];
    if (value) {
      [dd, mm, yy] = value.split("-");
    } else {
      [dd, mm, yy] = ["", "", ""];
    }
  } else {
    const value = filledEF[operator]?.[cluster]?.[i]?.[j];
    if (value) {
      [dd, mm, yy] = value.split("-");
    } else {
      [dd, mm, yy] = ["", "", ""];
    }
  }

  let inputDate = `${yy}-${mm}-${dd}`;

  return (
    <div>
      <h2 className="pb-1">{ele.placeholder}</h2>
      <Input
        type="date"
        value={inputDate}
        change={(e) => {
          const [year, month, day] = e.target.value.split("-");
          let date = `${day}-${month}-${year}`;
          if (k !== undefined) {
            dispatch(
              updateFEF({
                cluster: cluster,
                i,
                j,
                k,
                l,
                operator: operator,
                name: ele.placeholder,
                value: date,
              })
            );
            dispatch(
              updateFFEF({
                cluster: cluster,
                i,
                j,
                k,
                l,
                EF: `${category}$${name}_EF`,
                name: ele.placeholder,
                value: date,
                operator,
              })
            );
          } else {
            dispatch(
              updateFEF({
                cluster: cluster,
                i,
                j,
                operator: operator,
                name: ele.placeholder,
                value: date,
              })
            );
            dispatch(
              updateFFEF({
                cluster: cluster,
                i,
                j,
                EF: `${category}$${name}_EF`,
                name: ele.placeholder,
                value: date,
                operator,
              })
            );
          }
        }}
        click={() => {
          if (userInfo.role === "Manager") {
            let text = `${category}$${name}_EF['${operator}']['${ele.placeholder}']`;
            navigator.clipboard.writeText(text);
          }
        }}
      />
    </div>
  );
};

export default MDATE;
