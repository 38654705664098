import React from "react";
import Input from "../../../../../../utils/Input";
import Dropdown from "../../../../../../utils/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  setSecFooterType,
  addFooterPara,
  addFooterTable,
} from "../../../../../../slices/docsSlice";

const SecFooter = ({
  footerType,
  footerRows,
  footerCols,
  setFooterRows,
  setFooterCols,
}) => {
  const dispatch = useDispatch();
  const { RRIP_S } = useSelector((state) => state.docs);
  const { headerFooterTypes } = useSelector((state) => state.options);
  return (
    <div className="flex gap-4 pb-4">
      <div>
        <h1 className="pb-1">Footer</h1>
        <Dropdown
          width={200}
          height={40}
          options={headerFooterTypes}
          target="option"
          name="Type"
          value={footerType}
          click={(value) => {
            dispatch(
              setSecFooterType({
                section: RRIP_S,
                type: value,
              })
            );
          }}
          style={
            footerType ? { backgroundColor: "#083344", color: "#f8f9fa" } : {}
          }
        />
      </div>
      {footerType ? (
        <>
          <div>
            <h1 className="pb-1">Para</h1>
            <button
              className="bg-cyan-950 text-light-500 h-[40px] w-[100px]"
              onClick={() => {
                dispatch(
                  addFooterPara({
                    section: RRIP_S,
                  })
                );
              }}
            >
              Para
            </button>
          </div>
          <div>
            <h1 className="pb-1">Rows</h1>
            <Input
              className="h-[40px] w-[100px]"
              value={footerRows}
              change={(e) => setFooterRows(e.target.value)}
              placeholder="Rows"
              type="number"
            />
          </div>
          <div>
            <h1 className="pb-1">Cols</h1>
            <Input
              className="h-[40px] w-[100px]"
              value={footerCols}
              change={(e) => setFooterCols(e.target.value)}
              placeholder="Cols"
              type="number"
            />
          </div>
          <div>
            <h1 className="pb-1">Table</h1>
            <button
              className="bg-cyan-950 text-light-500 h-[40px] w-[100px]"
              onClick={() => {
                dispatch(
                  addFooterTable({
                    section: RRIP_S,
                    rows: parseFloat(footerRows),
                    cols: parseFloat(footerCols),
                  })
                );
              }}
            >
              Table
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SecFooter;
