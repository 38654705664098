import React from "react";
import Input from "../../../../utils/Input";
import { useDispatch, useSelector } from "react-redux";
import { updateFEF } from "../../../../slices/entryForm";
import { updateFFEF } from "../../../../slices/formsSlice";

const CFInput = ({ ele, operator }) => {
  const dispatch = useDispatch();
  const { dev, category, name } = useSelector((state) => state.docs);
  const { filledEF } = useSelector((state) => state.entryForm);
  const { userInfo } = useSelector((state) => state.auth);
  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="pb-1">{ele.placeholder}</h2>
        {ele.unit && <h2>[{ele.unit}]</h2>}
      </div>
      <Input
        maxLength={ele.maxLength ? parseFloat(ele.maxLength) : ""}
        type={ele.type}
        placeholder={ele.placeholder}
        value={filledEF[operator][ele.placeholder]}
        change={(e) => {
          let value = e.target.value;
          if (ele.caps) {
            value = value.toUpperCase();
          }
          dispatch(
            updateFEF({
              operator: operator,
              name: ele.placeholder,
              value: value,
            })
          );

          dispatch(
            updateFFEF({
              EF: `${category}$${name}_EF`,
              name: ele.placeholder,
              value: value,
              operator,
            })
          );
        }}
        click={() => {
          if (userInfo.role === "Manager") {
            let text = `${category}$${name}_EF['${operator}']['${ele.placeholder}']`;
            navigator.clipboard.writeText(text);
          }
        }}
      />
    </div>
  );
};

export default CFInput;
