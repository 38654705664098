import { createSlice } from "@reduxjs/toolkit";
// Do not remove
import set from "lodash/set";
import get from "lodash/get";

import basicProps from "./Docs/basicProps";
import tableProps from "./Docs/tableProps";
import rowProps from "./Docs/rowProps";
import cellProps from "./Docs/cellProps";
import paraProps from "./Docs/paraProps";
import textProps from "./Docs/textProps";
import imageProps from "./Docs/imageProps";
import sectionProps from "./Docs/sectionProps";
import documentProps from "./Docs/documentProps";
import symbolProps from "./Docs/symbolProps";
import checkboxProps from "./Docs/checkboxProps";
import functionProps from "./Docs/functionProps";
import essentials from "./Docs/essentials";

const initialState = {
  docId: undefined,
  docs: undefined,
  entryForm: {},
  forName: "",
  name: "",
  category: "",
  runStatus: false,
  indexPath: "",
  dev: false,
  minimal: true,
  SIP: undefined,
  NIP_I: undefined,
  NIP_CB: undefined,
  NIP_SY: undefined,
  NIP_T: undefined,
  NIP_P: undefined,
  NIP_TC: undefined,
  NIP_TR: undefined,
  NIP_TB: undefined,
  NIP_H: undefined,
  NIP_F: undefined,
  NIP_S: undefined,
  NIP_DOC: undefined,
  NIP: undefined,
  RRIP_I: undefined,
  RRIP_CB: undefined,
  RRIP_SY: undefined,
  RRIP_T: undefined,
  RRIP_P: undefined,
  RRIP_TC: undefined,
  RRIP_TR: undefined,
  RRIP_TB: undefined,
  RRIP_H: undefined,
  RRIP_F: undefined,
  RRIP_S: undefined,
  RRIP_DOC: undefined,
  RRIP: undefined,
};

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    ...basicProps,
    ...documentProps,
    ...sectionProps,
    ...paraProps,
    ...textProps,
    ...imageProps,
    ...checkboxProps,
    ...symbolProps,
    ...tableProps,
    ...rowProps,
    ...cellProps,
    ...functionProps,
    ...essentials,
  },
});

export const {
  // Basic Props
  setDocId,
  setDocs,
  setForName,
  setName,
  setCategory,
  setRunStatus,
  setDev,
  setMinimal,
  updateEleName,
  // Document Props
  addSection,
  setNumbering,
  updateMergeSec,
  setBulkFiles,
  addFunctions,
  updateFunction,
  deleteFunction,
  setDocTableWidth,
  setDocTableMargins,
  setDocTableBorders,
  setDocRowHeight,
  setDocCellAlignment,
  setDocCellMargins,
  setDocCellBorders,
  setDocParaAlignment,
  setDocParaSpacing,
  setDocTextFont,
  setDocTextFontSize,
  // Section Properties
  addPara,
  addTable,
  setSecOrientation,
  setSecBorderStyle,
  setSecBorderColor,
  setSecBorderSize,
  setSecBorderSpace,
  setSecMargin,
  setSecType,
  setSecHeaderType,
  addHeaderPara,
  addHeaderTable,
  setSecFooterType,
  addFooterPara,
  addFooterTable,
  // Para Properties
  updateAlignment,
  addThematicBreak,
  addPageBreak,
  updateBorder,
  updateSpacing,
  setParaNumbering,
  updateParaIndent,
  addTextToPara,
  addImageToPara,
  addSymbolToPara,
  addCheckboxToPara,
  // Text Properties
  updateText,
  updateTextFont,
  updateTextSize,
  updateTextCasing,
  updateTextBold,
  updateTextItalics,
  updateTextUnderline,
  updateTextColor,
  // Image Properties
  updateImageTransformation,
  // CheckBox Properties
  updateChecked,
  updateCheckedState,
  updateUncheckedState,
  // Symbol Properties
  updateSymbolFont,
  updateSymbolSize,
  updateSymbolBold,
  updateSymbolItalics,
  // Table Properties
  setTableWidth,
  setTableCellMargin,
  updateTableBorder,
  setTableAlignment,
  addColumnToTable,
  addRowToTable,
  setTableCellAlignment,
  setTableParaAlignment,
  setTableParaSpacing,
  setTableTextFont,
  setTableTextFontSize,
  // Row Props
  setRowHeight,
  setRowCellWidth,
  // Cell Props
  setCellMargin,
  updateCellBorder,
  setCellWidth,
  updateColSpan,
  updateRowSpan,
  replaceTable,
  // JS Function Properties
  addJSFunc,
  updateJSFunc,
  removeJSFunc,
  updateInc,
  // Essentials
  addDoc,
  pushDoc,
  removeDoc,
  setIndexPath,
  resetDocs,
  removeEle,
  updateShading,
} = documentsSlice.actions;

export default documentsSlice.reducer;
