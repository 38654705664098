import React from "react";
import Input from "../../../../../../utils/Input";
import { useDispatch, useSelector } from "react-redux";
import { updateShading } from "../../../../../../slices/docsSlice";

const ShadeColor = ({ shadeColor, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_P } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">Para Color</h2>
      <Input
        className="h-[40px] w-[100px] p-0 shadow-none"
        value={shadeColor}
        blur={(e) => {
          dispatch(
            updateShading({
              loc: "color",
              path: RRIP_P,
              value: e.target.value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
        // change={(e) => setShadeColor(e.target.value)}
        type="color"
      />
    </div>
  );
};

export default ShadeColor;
