import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCellMargin } from "../../../../../../../../slices/docsSlice";
import Input from "../../../../../../../../utils/Input";

const MarginRight = ({ marginRight, setMarginRight, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TC, RRIP_TB, RRIP_DOC } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">Right Margin</h2>
      <Input
        className="h-[40px] w-[150px]"
        value={marginRight}
        placeholder="right margin"
        blur={(e) =>
          dispatch(
            setCellMargin({
              loc: "right",
              cell: RRIP_TC,
              table: RRIP_TB,
              doc: RRIP_DOC,
              value: e.target.value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          )
        }
        change={(e) => setMarginRight(e.target.value)}
        type="text"
      />
    </div>
  );
};

export default MarginRight;
