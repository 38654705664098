import React, { useEffect, useState } from "react";

import Input from "../../../../../../utils/Input";

import { useDispatch, useSelector } from "react-redux";
import { returnPropEle, returnPropHFEle } from "../../CommFunctions";
import { updateImageTransformation } from "../../../../../../slices/docsSlice";

const ImageProperties = () => {
  const dispatch = useDispatch();
  const { docs, SIP, RRIP_I, NIP_I } = useSelector((state) => state.docs);
  const { imageName } = useSelector((state) => state.element);
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  let SIPHF = SIP[2];

  useEffect(() => {
    let image;
    if (SIPHF === "0_H" || SIPHF === "0_F") {
      image = returnPropHFEle(docs, RRIP_I, SIPHF);
    } else {
      image = returnPropEle(docs, RRIP_I);
    }
    if (image) {
      setWidth(image.properties.transformation.width);
      setHeight(image.properties.transformation.height);
    }
  }, [docs, NIP_I]);

  return (
    <div className="psm morphous bg-color">
      <h2 className="font-mono pb-2 text-center">
        Image Properties [{imageName}]
      </h2>
      <div className="flex gap-4 pb-4">
        <div>
          <h2 className="pb-1">Image Width</h2>
          <Input
            className="h-[40px] w-[100px]"
            value={width}
            blur={(e) => {
              dispatch(
                updateImageTransformation({
                  image: RRIP_I,
                  dimension: "width",
                  value: e.target.value,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
            change={(e) => setWidth(e.target.value)}
            type="number"
          />
        </div>
        <div>
          <h2 className="pb-1">Image Height</h2>
          <Input
            className="h-[40px] w-[100px]"
            value={height}
            blur={(e) => {
              dispatch(
                updateImageTransformation({
                  image: RRIP_I,
                  dimension: "height",
                  value: e.target.value,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
            change={(e) => setHeight(e.target.value)}
            type="number"
          />
        </div>
      </div>
    </div>
  );
};

export default ImageProperties;
