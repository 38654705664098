import { get } from "lodash";

export const returnEle = (state, path) => {
  const splitIndexPath = path;
  let newIndexPath = splitIndexPath
    .map((index) => index.split("_")[0])
    .join("_");

  let mainEle = null;
  let conditionMet = false;

  const setProperty = (ele, currIndex, currPath = "") => {
    const newPath =
      currPath !== "" ? `${currPath}_${currIndex}` : `${currIndex}`;
    if (!conditionMet) {
      if (newPath === newIndexPath) {
        mainEle = ele;
        conditionMet = true;
      } else if (ele.ele?.length > 0) {
        ele.ele.forEach((E, i) => setProperty(E, i, newPath));
      }
    }
  };

  state.docs.forEach((doc, i) => setProperty(doc, i));
  return mainEle;
};

export const returnHFEle = (state, path, layoutEle) => {
  let elePath = "docs";
  path.map((ele, i) => {
    let SE = ele.split("_");
    if (SE[1] === "S") {
      elePath =
        elePath +
        `[${SE[0]}].${layoutEle === "0_H" ? "headers.ele" : "footers.ele"}`;
    } else if (SE[1] !== "H" && SE[1] !== "F") {
      elePath =
        elePath + (i === path.length - 1 ? `[${SE[0]}]` : `[${SE[0]}].ele`);
    }
  });
  return get(state, elePath);
};

export const returnEI = (state, path) => {
  const splitIndexPath = path;
  let newIndexPath = splitIndexPath
    .map((index) => index.split("_")[0])
    .join("_");

  let mainEle = null;
  let mainIndex = null;
  let conditionMet = false;

  const setProperty = (ele, currIndex, currPath = "") => {
    const newPath =
      currPath !== "" ? `${currPath}_${currIndex}` : `${currIndex}`;
    if (!conditionMet) {
      if (newPath === newIndexPath) {
        mainEle = ele;
        mainIndex = currIndex;
        conditionMet = true;
      } else if (ele.ele?.length > 0) {
        ele.ele.forEach((E, i) => setProperty(E, i, newPath));
      }
    }
  };

  state.docs.forEach((doc, i) => setProperty(doc, i));
  return {
    ele: mainEle,
    index: mainIndex,
  };
};

export const consoleFun = (value) => {
  console.log(JSON.parse(JSON.stringify(value)));
};
