import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRowToTable } from "../../../../../../slices/docsSlice";

const AddRowToTable = ({ SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TB } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">Row</h2>
      <button
        className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
        onClick={() => {
          dispatch(
            addRowToTable({
              table: RRIP_TB,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
      >
        Row
      </button>
    </div>
  );
};

export default AddRowToTable;
