import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTableTextFont } from "../../../../../../../slices/docsSlice";
import Dropdown from "../../../../../../../utils/Dropdown";

const Font = ({ font, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TB } = useSelector((state) => state.docs);
  const { fonts } = useSelector((state) => state.options);
  return (
    <div>
      <h2 className="pb-1">Set Text Font</h2>
      <Dropdown
        className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
        value={font}
        options={fonts}
        target="option"
        width={250}
        height={40}
        click={(value) => {
          dispatch(
            setTableTextFont({
              table: RRIP_TB,
              value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
      />
    </div>
  );
};

export default Font;
