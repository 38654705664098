import React from "react";
import TextArea from "../../../../../utils/TextArea";
import { useSelector, useDispatch } from "react-redux";
import { updateFEF } from "../../../../../slices/entryForm";
import { updateFFEF } from "../../../../../slices/formsSlice";

const MTEXTAREA = ({ cluster, ele, operator, i, j, k, l }) => {
  const dispatch = useDispatch();
  const { filledEF } = useSelector((state) => state.entryForm);
  const { userInfo } = useSelector((state) => state.auth);
  const { dev, category, name } = useSelector((state) => state.docs);

  let val;
  if (k !== undefined) {
    val =
      filledEF[operator] &&
      filledEF[operator][cluster] &&
      filledEF[operator][cluster][i][j]
        ? filledEF[operator][cluster][i][j][k][l]
        : "";
  } else {
    val =
      filledEF[operator] &&
      filledEF[operator][cluster] &&
      filledEF[operator][cluster][i] &&
      filledEF[operator][cluster][i][j]
        ? filledEF[operator][cluster][i][j]
        : "";
  }

  return (
    <div>
      <h2 className="pb-1">{ele.placeholder}</h2>
      <TextArea
        className="h-[100px]"
        placeholder={ele.placeholder}
        value={val}
        change={(e) => {
          let value = e.target.value;
          if (ele.caps) {
            value = value.toUpperCase();
          }
          if (filledEF[operator] && filledEF[operator][cluster]) {
            if (k !== undefined) {
              dispatch(
                updateFEF({
                  cluster: cluster,
                  i,
                  j,
                  k,
                  l,
                  operator: operator,
                  name: ele.placeholder,
                  value: value,
                })
              );
              dispatch(
                updateFFEF({
                  cluster: cluster,
                  i,
                  j,
                  k,
                  l,
                  EF: `${category}$${name}_EF`,
                  name: ele.placeholder,
                  value: value,
                  operator,
                })
              );
            } else {
              dispatch(
                updateFEF({
                  cluster: cluster,
                  i,
                  j,
                  operator: operator,
                  name: ele.placeholder,
                  value: value,
                })
              );
              dispatch(
                updateFFEF({
                  cluster: cluster,
                  i,
                  j,
                  EF: `${category}$${name}_EF`,
                  name: ele.placeholder,
                  value: value,
                  operator,
                })
              );
            }
          }
        }}
        click={() => {
          if (userInfo.role === "Manager") {
            let text = `${category}$${name}_EF['${operator}']['${ele.placeholder}']`;
            navigator.clipboard.writeText(text);
          }
        }}
      />
    </div>
  );
};

export default MTEXTAREA;
