import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../../utils/Input";
import { addPara } from "../../../../../../slices/docsSlice";

const AddPara = ({ SLI }) => {
  const dispatch = useDispatch();
  const { RRIP_S } = useSelector((state) => state.docs);
  const [paraName, setParaName] = useState();
  return (
    <div className="flex gap-4 pb-4">
      <Input
        className="h-[40px] w-[250px]"
        value={paraName}
        change={(e) => setParaName(e.target.value)}
        placeholder="Enter Paragraph Name"
      />
      <button
        className="px-3 py-1 bg-cyan-950 text-light-500"
        onClick={() => {
          dispatch(
            addPara({
              path: RRIP_S,
              name: paraName,
            })
          );
          setParaName("");
        }}
      >
        Add Para
      </button>
      <button
        className="px-3 py-1 bg-cyan-950 text-light-500"
        onClick={() => {
          dispatch(
            addPara({
              path: RRIP_S,
              loc: "after",
              index: parseFloat(SLI[0]),
              name: paraName,
            })
          );
        }}
      >
        After
      </button>
      <button
        className="px-3 py-1 bg-cyan-950 text-light-500"
        onClick={() => {
          dispatch(
            addPara({
              path: RRIP_S,
              loc: "before",
              index: parseFloat(SLI[0]),
              name: paraName,
            })
          );
        }}
      >
        Before
      </button>
    </div>
  );
};

export default AddPara;
