import React from "react";
import Input from "../../../../utils/Input";
import { useDispatch, useSelector } from "react-redux";
import { updateFEF } from "../../../../slices/entryForm";
import { updateFFEF } from "../../../../slices/formsSlice";

const CFDate = ({ ele, operator }) => {
  const dispatch = useDispatch();
  const { dev, category, name } = useSelector((state) => state.docs);
  const { filledEF } = useSelector((state) => state.entryForm);
  const { userInfo } = useSelector((state) => state.auth);
  return (
    <div>
      <h2 className="pb-1">{ele.placeholder}</h2>
      <Input
        type="date"
        placeholder={ele.placeholder}
        value={filledEF[operator][ele.placeholder]}
        change={(e) => {
          const [year, month, day] = e.target.value.split("-");
          let date = `${day}-${month}-${year}`;
          dispatch(
            updateFEF({
              operator: operator,
              name: ele.placeholder,
              value: date,
            })
          );
          dispatch(
            updateFFEF({
              EF: `${category}$${name}_EF`,
              name: ele.placeholder,
              value: date,
              operator,
            })
          );
        }}
        click={() => {
          if (userInfo.role === "Manager") {
            let text = `${category}$${name}_EF['${operator}']['${ele.placeholder}']`;
            navigator.clipboard.writeText(text);
          }
        }}
      />
    </div>
  );
};

export default CFDate;
