import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateColSpan } from "../../../../../../../../slices/docsSlice";
import Input from "../../../../../../../../utils/Input";

const ColumnSpan = ({ columnSpan, setColumnSpan, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TC, RRIP_TR, RRIP_TB } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">Col Span</h2>
      <div>
        <Input
          className="h-[40px] w-[100px]"
          value={columnSpan === undefined ? 1 : columnSpan}
          blur={(e) =>
            dispatch(
              updateColSpan({
                path: RRIP_TC,
                row: RRIP_TR,
                table: RRIP_TB,
                value: e.target.value,
                layoutEle:
                  SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
              })
            )
          }
          change={(e) => setColumnSpan(e.target.value)}
        />
      </div>
    </div>
  );
};

export default ColumnSpan;
