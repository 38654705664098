import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSecOrientation } from "../../../../../../slices/docsSlice";
import Dropdown from "../../../../../../utils/Dropdown";

const Orientation = ({ orientation }) => {
  const dispatch = useDispatch();
  const { RRIP_S } = useSelector((state) => state.docs);
  const { sectionOrientation } = useSelector((state) => state.options);
  return (
    <div>
      <h2 className="pb-1">Orientation</h2>
      <Dropdown
        width={150}
        height={40}
        options={sectionOrientation}
        target="option"
        value={orientation}
        click={(value) => {
          dispatch(
            setSecOrientation({
              section: RRIP_S,
              value,
            })
          );
        }}
      />
    </div>
  );
};

export default Orientation;
