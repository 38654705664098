import { createSlice } from "@reduxjs/toolkit";
import {
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineCheckBox,
  MdArrowForward,
} from "react-icons/md";
import { FaSmile, FaFrown, FaCut, FaStar } from "react-icons/fa";

const initialState = {
  navigationLock: false,
  headerFooterTypes: ["default", "first", "even"],
  sectionTypes: ["continuous", "evenPage", "oddPage", "nextPage", "nextColumn"],
  sectionOrientation: ["portrait", "landscape"],
  fonts: [
    "Arial MT",
    "Calibri",
    "Trebuchet MS",
    "Times New Roman",
    "Verdana",
    "Georgia",
    "Tahoma",
    "Courier New",
    "Comic Sans MS",
    "Impact",
    "Arial",
    "Segoe UI",
    "Lucida Console",
    "Palatino Linotype",
  ],
  fontSizes: [
    "7pt",
    "7.5pt",
    "8pt",
    "8.5pt",
    "9pt",
    "9.5pt",
    "10pt",
    "10.5pt",
    "11pt",
    "11.5pt",
    "12pt",
    "12.5pt",
    "13pt",
    "13.5pt",
    "14pt",
    "14.5pt",
    "15pt",
    "15.5pt",
    "16pt",
    "16.5pt",
    "17pt",
    "17.5pt",
    "18pt",
    "18.5pt",
    "19pt",
    "19.5pt",
    "20pt",
  ],
  isTrueOrFalse: ["true", "false"],
  colors: ["None", "Red", "Blue", "Green", "Yellow"],
  alignments: ["left", "center", "right", "justified"],
  cellAlignments: ["top", "center", "bottom"],
  borderStyles: [
    "single",
    "double",
    "triple",
    "nil",
    "none",
    "dashDotStroked",
    "dashed",
    "dashSmallGap",
    "dotDash",
    "dotDotDash",
    "dotted",
    "doubleWave",
    "inset",
    "outset",
    "thick",
    "thickThinLargeGap",
    "thickThinMediumGap",
    "thickThinSmallGap",
    "thinThickLargeGap",
    "thinThickMediumGap",
    "thinThickSmallGap",
    "thinThickThinLargeGap",
    "thinThickThinMediumGap",
    "thinThickThinSmallGap",
    "threeDEmboss",
    "threeDEngrave",
    "wave",
  ],
  underlines: [
    "SINGLE",
    "WORDS",
    "DOUBLE",
    "THICK",
    "DOTTED",
    "DOTTEDHEAVY",
    "DASH",
    "DASHEDHEAVY",
    "DASHLONG",
    "DASHLONGHEAVY",
    "DOTDASH",
    "DASHDOTHEAVY",
    "DOTDOTDASH",
    "DASHDOTDOTHEAVY",
    "WAVE",
    "WAVYHEAVY",
    "WAVYDOUBLE",
    "NONE",
  ],
  shadingTypes: [
    "solid",
    "clear",
    "diagCross",
    "diagStripe",
    "horzCross",
    "horzStripe",
    "nil",
    "pct5",
    "pct10",
    "pct12",
    "pct15",
    "pct20",
    "pct25",
    "pct30",
    "pct35",
    "pct37",
    "pct40",
    "pct45",
    "pct50",
    "pct55",
    "pct60",
    "pct62",
    "pct65",
    "pct70",
    "pct75",
    "pct80",
    "pct85",
    "pct87",
    "pct90",
    "pct95",
    "reverseDiagStripe",
    "thinDiagCross",
    "thinDiagStripe",
    "thinHorzCross",
    "thinReverseDiagStripe",
    "thinVertStripe",
    "vertStripe",
  ],
  levelFormat: [
    "decimal",
    "upperRoman",
    "lowerRoman",
    "upperLetter",
    "lowerLetter",
    "ordinal",
    "cardinalText",
    "ordinalText",
    "hex",
    "bullet",
    "none",
  ],
  levelSuffix: ["nothing", "space", "tab"],
  widthTypes: ["dxa", "pct", "auto", "nil"],
  tableAlignments: ["left", "right", "center", "start", "end", "both"],
  rowHeightRules: ["auto", "atLeast", "exact"],
  pageBorderDisplay: ["allPages", "firstPage", "notFirstPage"],
  pageBorderOffsetFrom: ["page", "text"],
  pageBorderZOrder: ["back", "front"],
  pageBorders: ["display", "offsetFrom", "zOrder"],
  bullet: ["•", "°", "■"],
  symbols: [
    {
      char: "\uF071",
      name: "unchecked box",
      icon: MdOutlineCheckBoxOutlineBlank,
    }, // Unchecked Box (Unicode)
    { char: "\uF0FE", name: "check box", icon: MdOutlineCheckBox }, // Checked Box (Unicode)
    { char: "\uF04A", name: "smile", icon: FaSmile }, // Smile (Unicode)
    { char: "\uF04C", name: "sad", icon: FaFrown }, // Frown (Unicode)
    { char: "\uF022", name: "scissors", icon: FaCut }, // Scissors (Unicode)
    { char: "\uF0F0", name: "right arrow", icon: MdArrowForward }, // Right Arrow (Unicode)
    { char: "\u7043", name: "star", icon: FaStar }, // Star (Unicode)
  ],
  letters: [
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ],
};

const optionsSlice = createSlice({
  name: "options",
  initialState,
  reducers: {
    setShowColors: (state, { payload }) => {
      state.showColors = !state.showColors;
    },
  },
});

export const { setShowColors } = optionsSlice.actions;

export default optionsSlice.reducer;
