import React, { useState } from "react";
import { useSelector } from "react-redux";
import produceClone from "../../components/Lodash";
import { useAddEntryFormMutation } from "../../slices/entryFormApiSlice";
import { toast } from "react-toastify";
import Input from "../../utils/Input";

const Submit = () => {
  const { forms, userForms } = useSelector((state) => state.forms);
  const { agency } = useSelector((state) => state.agency);
  const { document } = useSelector((state) => state.document);
  const { listIDS } = useSelector((state) => state.internalDependency);
  const [forName, setForName] = useState("");

  console.log(forms, userForms);

  const [addEntryForm, {}] = useAddEntryFormMutation();

  const submitHandler = async () => {
    let newUserForms = userForms.filter((item) => item.name !== "Submit");
    try {
      if (forName) {
        const res = await addEntryForm({
          listIDS,
          userForms: newUserForms,
          forms: forms,
          forName: forName,
          agency,
          category: document.category,
          docName: document.name,
        }).unwrap();
        toast.success(res.message);
      } else {
        toast.error("Enter For Name");
      }
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="psm flex justify-center flex-col gap-2 items-center">
      <Input
        type="text"
        value={forName}
        change={(e) => setForName(e.target.value)}
        placeholder="Enter For Name"
      />
      <button
        className="p-2 bg-cyan-950 text-light-500"
        onClick={submitHandler}
      >
        Submit
      </button>
    </div>
  );
};

export default Submit;
