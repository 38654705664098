import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTableAlignment } from "../../../../../../slices/docsSlice";
import Dropdown from "../../../../../../utils/Dropdown";

const Alignment = ({ alignment, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TB } = useSelector((state) => state.docs);
  const { tableAlignments } = useSelector((state) => state.options);
  return (
    <div>
      <h2 className="pb-1">Table Alignment</h2>
      <Dropdown
        className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
        value={alignment}
        name="alignment"
        options={tableAlignments}
        target="option"
        placeholder="alignment"
        width={150}
        height={40}
        click={(value) => {
          dispatch(
            setTableAlignment({
              table: RRIP_TB,
              value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
      />
    </div>
  );
};

export default Alignment;
