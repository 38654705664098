import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSecBorderColor } from "../../../../../../slices/docsSlice";
import Input from "../../../../../../utils/Input";

const PBLColor = ({ pageBorderLeftColor }) => {
  const dispatch = useDispatch();
  const { RRIP_S } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">PB Left color</h2>
      <Input
        className="h-[40px] w-[100px] p-0 shadow-none"
        value={pageBorderLeftColor}
        blur={(e) => {
          dispatch(
            setSecBorderColor({
              loc: "pageBorderLeft",
              section: RRIP_S,
              value: e.target.value,
            })
          );
        }}
        // change={(e) => setPageBorderLeftColor(e.target.value)}
        type="color"
      />
    </div>
  );
};

export default PBLColor;
