import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { returnPropEle } from "../CommFunctions";
import RetractableDiv from "../../../../../components/RetractableDiv";

import Orientation from "./components/Orientation";
import SectionType from "./components/SectionType";

import PBLStyle from "./components/PBLStyle";
import PBLColor from "./components/PBLColor";
import PBLSize from "./components/PBLSize";
import PBLSpace from "./components/PBLSpace";
import PBRStyle from "./components/PBRStyle";
import PBRColor from "./components/PBRColor";
import PBRSize from "./components/PBRSize";
import PBRSpace from "./components/PBRSpace";
import PBTStyle from "./components/PBTStyle";
import PBTColor from "./components/PBTColor";
import PBTSize from "./components/PBTSize";
import PBTSpace from "./components/PBTSpace";
import PBBStyle from "./components/PBBStyle";
import PBBColor from "./components/PBBColor";
import PBBSize from "./components/PBBSize";
import PBBSpace from "./components/PBBSpace";

import MarginLeft from "./components/MarginLeft";
import MarginRight from "./components/MarginRight";
import MarginTop from "./components/MarginTop";
import MarginBottom from "./components/MarginBottom";

import SecHeader from "./components/SecHeader";
import SecFooter from "./components/SecFooter";

import AddSection from "./components/AddSection";
import AddPara from "./components/AddPara";
import AddParaInTable from "./components/AddParaInTable";
import AddTable from "./components/AddTable";

const SectionProperties = () => {
  const {
    docs,
    SIP,
    NIP_S,
    NIP_P,
    NIP_T,
    RRIP_S,
    RRIP_DOC,
    RRIP,
    dev,
    minimal,
  } = useSelector((state) => state.docs);

  const [type, setType] = useState();
  const [orientation, setOrientation] = useState();
  const [marginTop, setMarginTop] = useState();
  const [marginBottom, setMarginBottom] = useState();
  const [marginLeft, setMarginLeft] = useState();
  const [marginRight, setMarginRight] = useState();
  const [pageBorderLeftStyle, setPageBorderLeftStyle] = useState();
  const [pageBorderLeftColor, setPageBorderLeftColor] = useState();
  const [pageBorderLeftSize, setPageBorderLeftSize] = useState();
  const [pageBorderLeftSpace, setPageBorderLeftSpace] = useState();
  const [pageBorderRightStyle, setPageBorderRightStyle] = useState();
  const [pageBorderRightColor, setPageBorderRightColor] = useState();
  const [pageBorderRightSize, setPageBorderRightSize] = useState();
  const [pageBorderRightSpace, setPageBorderRightSpace] = useState();
  const [pageBorderTopStyle, setPageBorderTopStyle] = useState();
  const [pageBorderTopColor, setPageBorderTopColor] = useState();
  const [pageBorderTopSize, setPageBorderTopSize] = useState();
  const [pageBorderTopSpace, setPageBorderTopSpace] = useState();
  const [pageBorderBottomStyle, setPageBorderBottomStyle] = useState();
  const [pageBorderBottomColor, setPageBorderBottomColor] = useState();
  const [pageBorderBottomSize, setPageBorderBottomSize] = useState();
  const [pageBorderBottomSpace, setPageBorderBottomSpace] = useState();

  const [headerType, setHeaderType] = useState();
  const [headerRows, setHeaderRows] = useState();
  const [headerCols, setHeaderCols] = useState();

  const [footerType, setFooterType] = useState();
  const [footerRows, setFooterRows] = useState();
  const [footerCols, setFooterCols] = useState();

  let lastIndex = SIP[SIP.length - 1];
  let SLI = lastIndex.split("_");

  useEffect(() => {
    const section = RRIP_S ? returnPropEle(docs, RRIP_S) : undefined;
    const doc = returnPropEle(docs, RRIP_DOC);

    if (section) {
      if (section.properties.page.size) {
        setOrientation(section.properties.page.size.orientation);
      }
      if (section.properties.page.margin) {
        setMarginLeft(section.properties.page.margin.left);
        setMarginRight(section.properties.page.margin.right);
        setMarginTop(section.properties.page.margin.top);
        setMarginBottom(section.properties.page.margin.bottom);
      }
      if (section.properties.page.borders) {
        setPageBorderLeftStyle(
          section.properties.page.borders.pageBorderLeft.style
        );
        setPageBorderLeftColor(
          `#${section.properties.page.borders.pageBorderLeft.color.toLowerCase()}`
        );
        setPageBorderLeftSize(
          section.properties.page.borders.pageBorderLeft.size
        );
        setPageBorderLeftSpace(
          section.properties.page.borders.pageBorderLeft.space
        );
        setPageBorderRightStyle(
          section.properties.page.borders.pageBorderRight.style
        );
        setPageBorderRightColor(
          `#${section.properties.page.borders.pageBorderRight.color}`
        );
        setPageBorderRightSize(
          section.properties.page.borders.pageBorderRight.size
        );
        setPageBorderRightSpace(
          section.properties.page.borders.pageBorderRight.space
        );

        setPageBorderTopStyle(
          section.properties.page.borders.pageBorderTop.style
        );
        setPageBorderTopColor(
          `#${section.properties.page.borders.pageBorderTop.color}`
        );
        setPageBorderTopSize(
          section.properties.page.borders.pageBorderTop.size
        );
        setPageBorderTopSpace(
          section.properties.page.borders.pageBorderTop.space
        );

        setPageBorderBottomStyle(
          section.properties.page.borders.pageBorderBottom.style
        );
        setPageBorderBottomColor(
          `#${section.properties.page.borders.pageBorderBottom.color}`
        );
        setPageBorderBottomSize(
          section.properties.page.borders.pageBorderBottom.size
        );
        setPageBorderBottomSpace(
          section.properties.page.borders.pageBorderBottom.space
        );
      }
      // if (`${section.properties.page.borders}`) {
      //   setBorders(`${section.properties.page.borders}`);
      // }
      if (section.properties.page.borders)
        if (section.properties.type) {
          setType(section.properties.type);
        }
      if (section.headers.type) {
        setHeaderType(section.headers.type);
      }
      if (section.footers.type) {
        setFooterType(section.footers.type);
      }
    }
  }, [docs, NIP_S]);

  return (
    <>
      {!dev ? (
        <>
          <RetractableDiv name="Section Properties" show={true}>
            <div className="psm morphous">
              {/* <h1 className="font-mono text-center pb-4">Section Properties</h1> */}
              <div className="flex gap-4 pb-4">
                <Orientation orientation={orientation} />
                <SectionType type={type} />
              </div>
              <div className={`${minimal ? "hidden" : "block pb-4"}`}>
                <div className="flex gap-4 pb-1">
                  <PBLStyle pageBorderLeftStyle={pageBorderLeftStyle} />
                  <PBLColor pageBorderLeftColor={pageBorderLeftColor} />
                  <PBLSize
                    pageBorderLeftSize={pageBorderLeftSize}
                    setPageBorderLeftSize={(V) => setPageBorderLeftSize(V)}
                  />
                  <PBLSpace
                    pageBorderLeftSpace={pageBorderLeftSpace}
                    setPageBorderLeftSpace={(V) => setPageBorderLeftSpace(V)}
                  />
                </div>
                <div className="flex gap-4 pb-1">
                  <PBRStyle pageBorderRightStyle={pageBorderRightStyle} />
                  <PBRColor pageBorderRightColor={pageBorderRightColor} />
                  <PBRSize
                    pageBorderRightSize={pageBorderRightSize}
                    setPageBorderRightSize={(V) => setPageBorderRightSize(V)}
                  />
                  <PBRSpace
                    pageBorderRightSpace={pageBorderRightSpace}
                    setPageBorderRightSpace={(V) => setPageBorderRightSpace(V)}
                  />
                </div>
                <div className="flex gap-4 pb-1">
                  <PBTStyle pageBorderTopStyle={pageBorderTopStyle} />
                  <PBTColor pageBorderTopColor={pageBorderTopColor} />
                  <PBTSize
                    pageBorderTopSize={pageBorderTopSize}
                    setPageBorderTopSize={(V) => setPageBorderTopSize(V)}
                  />
                  <PBTSpace
                    pageBorderTopSpace={pageBorderTopSpace}
                    setPageBorderTopSpace={(V) => setPageBorderTopSpace(V)}
                  />
                </div>
                <div className="flex gap-4 pb-1">
                  <PBBStyle pageBorderBottomStyle={pageBorderBottomStyle} />
                  <PBBColor pageBorderBottomColor={pageBorderBottomColor} />
                  <PBBSize
                    pageBorderBottomSize={pageBorderBottomSize}
                    setPageBorderBottomSize={(V) => setPageBorderBottomSize(V)}
                  />
                  <PBBSpace
                    pageBorderBottomSpace={pageBorderBottomSpace}
                    setPageBorderBottomSpace={(V) =>
                      setPageBorderBottomSpace(V)
                    }
                  />
                </div>
              </div>
              <div className="pb-4">
                <h1 className="pb-1 font-mono">
                  Margin Units [mm, cm, in, pt, pc, pi]
                </h1>
                <div className="flex gap-4">
                  <MarginLeft
                    marginLeft={marginLeft}
                    setMarginLeft={(value) => setMarginLeft(value)}
                  />
                  <MarginRight
                    marginRight={marginRight}
                    setMarginRight={(value) => setMarginRight(value)}
                  />
                  <MarginTop
                    marginTop={marginTop}
                    setMarginTop={(value) => setMarginTop(value)}
                  />
                  <MarginBottom
                    marginBottom={marginBottom}
                    setMarginBottom={(value) => setMarginBottom(value)}
                  />
                </div>
              </div>
              <div className={`${minimal ? "hidden" : "block"}`}>
                <SecHeader
                  headerType={headerType}
                  headerRows={headerRows}
                  headerCols={headerCols}
                  setHeaderRows={(V) => setHeaderRows(V)}
                  setHeaderCols={(V) => setHeaderCols(V)}
                />
                <SecFooter
                  footerType={footerType}
                  footerRows={footerRows}
                  footerCols={footerCols}
                  setFooterRows={(V) => setFooterRows(V)}
                  setFooterCols={(V) => setFooterCols(V)}
                />
              </div>
            </div>
          </RetractableDiv>
          <div className={`psm ${dev ? "hidden" : "block"}`}>
            {/* <h2 className="font-mono pb-2">Section Name</h2> */}
            <AddSection />
            {/* <h2 className="font-mono pb-2">Paragraph Name</h2> */}
            <AddPara SLI={SLI} />
            {/* <h2 className="font-mono pb-2">Para in Cell</h2> */}
            <AddParaInTable SLI={SLI} />
            {/* <h2 className="font-mono pb-2">Table Name, Rows and Cols</h2> */}
            <AddTable SLI={SLI} />
          </div>
        </>
      ) : null}
      <div className="mb-2">
        <Outlet />
      </div>
    </>
  );
};

export default SectionProperties;
