import React, { useState, useEffect, useRef } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { autocompletion } from "@codemirror/autocomplete";
import { javascript } from "@codemirror/lang-javascript";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { EditorView } from "@codemirror/view"; // <-- ADDED

import { useDispatch, useSelector } from "react-redux";
import { updateJSFunc } from "../slices/docsSlice";

const Editor = ({ value, path }) => {
  const dispatch = useDispatch();
  const { indexPath, name, category, docs } = useSelector(
    (state) => state.docs
  );
  const { document: mainFile } = useSelector((state) => state.document);
  const { forms } = useSelector((state) => state.forms);

  const [editorValue, setEditorValue] = useState(value);

  useEffect(() => {
    if (!localStorage.getItem("indexPath") && !localStorage.getItem("func")) {
      setEditorValue(value);
    } else {
      if (localStorage.getItem("indexPath") === path.join("/")) {
        let IP = localStorage.getItem("indexPath");
        let func = localStorage.getItem("func");
        let CATEGORY = localStorage.getItem("category");
        let NAME = localStorage.getItem("name");
        if (indexPath === IP && category === CATEGORY && name === NAME) {
          setEditorValue(func);
        } else {
          setEditorValue(value);
        }
      } else {
        setEditorValue(value);
      }
    }
  }, [value, path]);

  useEffect(() => {
    let IP = localStorage.getItem("indexPath");
    let func = localStorage.getItem("func");
    let CATEGORY = localStorage.getItem("category");
    let NAME = localStorage.getItem("name");
    if (indexPath === IP && category === CATEGORY && name === NAME) {
      setEditorValue(func);
      dispatch(updateJSFunc({ path, value: func }));
    }
  }, [indexPath, category, name, mainFile, forms, docs]);

  return (
    <CodeMirror
      className="text-base"
      value={editorValue}
      theme={vscodeDark}
      extensions={[
        javascript({ jsx: true }),
        autocompletion(),
        EditorView.lineWrapping, // <-- ADDED
      ]}
      onChange={(value) => {
        localStorage.setItem("func", value);
        localStorage.setItem("indexPath", indexPath);
        localStorage.setItem("category", category);
        localStorage.setItem("name", name);
        setEditorValue(value);
        // dispatch(updateJSFunc({ path, value: editorValue }));
      }}
      onBlur={() => {
        dispatch(updateJSFunc({ path, value: editorValue }));
      }}
    />
  );
};

export default Editor;
