import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateShading } from "../../../../../../../../slices/docsSlice";
import Input from "../../../../../../../../utils/Input";

const ShadeColor = ({ shadeColor, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TC } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">Cell Color</h2>
      <Input
        className="h-[40px] w-[100px] p-0 shadow-none"
        value={shadeColor}
        blur={(e) => {
          dispatch(
            updateShading({
              loc: "color",
              path: RRIP_TC,
              value: e.target.value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
        // change={(e) => setShadeColor(e.target.value)}
        type="color"
      />
    </div>
  );
};

export default ShadeColor;
