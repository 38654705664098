import React from "react";
import {
  AlignmentType,
  BorderStyle,
  ShadingType,
  WidthType,
  Document,
  Table,
  TableRow,
  TableCell,
  Paragraph,
  TextRun,
  ImageRun,
  SymbolRun,
  PageNumber,
  Header,
  Footer,
  NumberFormat,
  CheckBox,
} from "docx";

import { convertToTwips } from "../UnitConversion";

const transformation = {
  width: 341,
  height: 102,
};

const getWidth = (s) => {
  let width = {
    size: `${s}%`,
  };
  return width;
};

const getSpacing = (space) => {
  let spacing = {
    after: space,
  };
  return spacing;
};

const getNumbering = (reference, level) => {
  let numbering = {
    reference: reference,
    level: level,
  };
  return numbering;
};

const getCellWidth = (value) => {
  let type = WidthType.DXA;
  if (isNaN(parseFloat(value))) {
    // value = `${value}%`;
    type = WidthType.PERCENTAGE;
  }
  let width = {
    size: value,
    type: type,
  };
  return width;
};

const removeBorder = () => {
  let borders = {
    top: {
      style: BorderStyle.NIL,
      size: 0,
      color: "000000",
    },
    bottom: {
      style: BorderStyle.NIL,
      size: 0,
      color: "000000",
    },
    left: {
      style: BorderStyle.NIL,
      size: 0,
      color: "000000",
    },
    right: {
      style: BorderStyle.NIL,
      size: 0,
      color: "000000",
    },
  };
  return borders;
};

const borderBottom = () => {
  let borders = {
    top: {
      style: BorderStyle.NIL,
      size: 0,
      color: "000000",
    },
    bottom: {
      style: BorderStyle.SINGLE,
      size: 1,
      color: "000000",
    },
    left: {
      style: BorderStyle.NIL,
      size: 0,
      color: "000000",
    },
    right: {
      style: BorderStyle.NIL,
      size: 0,
      color: "000000",
    },
  };
  return borders;
};

const allBorders = () => {
  let borders = {
    top: {
      style: "single",
      size: 3,
      color: "auto",
      space: 0,
    },
    bottom: {
      style: "single",
      size: 3,
      color: "auto",
      space: 0,
    },
    left: {
      style: "single",
      size: 3,
      color: "auto",
      space: 0,
    },
    right: {
      style: "single",
      size: 3,
      color: "auto",
      space: 0,
    },
  };
  return borders;
};

const pageBorders = () => {
  let borders = {
    pageBorderTop: {
      style: "single",
      size: 3,
      color: "auto",
      space: 1,
    },
    pageBorderBottom: {
      style: "single",
      size: 3,
      color: "auto",
      space: 1,
    },
    pageBorderLeft: {
      style: "single",
      size: 3,
      color: "auto",
      space: 20,
    },
    pageBorderRight: {
      style: "single",
      size: 3,
      color: "auto",
      space: 20,
    },
  };
  return borders;
};

const getCheckbox = ({ checkbox, PP, CP, RP, TP, SP, DP }) => {
  const checkboxProps = checkbox.properties;
  return new CheckBox({
    checked: checkbox.checked,
    checkedState: checkboxProps.checkedState,
    uncheckedState: checkboxProps.uncheckedState,
  });
};

const getSymbol = ({ symbol, PP, CP, RP, TP, SP, DP }) => {
  console.log(symbol.char);
  const symbolProps = symbol.properties;
  let fontFamily = DP.text.font;
  let fontSize = DP.text.fontSize;
  if (symbolProps !== undefined) {
    if (symbolProps.font) {
      fontFamily = symbolProps.font;
    }
    if (symbolProps.fontSize) {
      fontSize = symbolProps.fontSize;
    }
  }
  return new SymbolRun({
    char: symbol.char,
    font: fontFamily,
    size: fontSize,
    bold: symbolProps.bold,
    italics: symbolProps.italics,
  });
};

const getImage = ({ image, PP, CP, RP, TP, SP, DP }) => {
  const imageProps = image.properties;
  return new ImageRun({
    data: image.image,
    transformation: imageProps.transformation,
  });
};

const getText = ({ text, PP, CP, RP, TP, SP, DP }) => {
  const textProps = text.properties;
  let fontFamily = DP.text.font;
  let fontSize = DP.text.fontSize;
  if (textProps !== undefined) {
    if (textProps.font) {
      fontFamily = textProps.font;
    }
    if (textProps.fontSize) {
      fontSize = textProps.fontSize;
    }
  }
  // if (textProps.font) {
  //   fontFamily = textProps.font;
  // }
  // if (textProps.fontSize) {
  //   fontSize = textProps.fontSize;
  // }

  let textColor = undefined;

  if (CP?.shading?.fill) {
    textColor = CP.shading.fill;
  } else if (PP?.shading?.fill) {
    textColor = PP.shading.fill;
  } else if (textProps.color) {
    textColor = textProps.color;
  }

  return new TextRun({
    text: text.t
      ? typeof text.t === "number"
        ? text.t.toFixed(2)
        : text.t
      : "\u00A0",
    underline: textProps.underline,
    bold: textProps.bold,
    color: textColor,
    font: fontFamily,
    size: fontSize,
    italics: textProps.italics,
    allCaps: textProps.allCaps,
    smallCaps: textProps.smallCaps,
  });
};

const getPara = ({ para, CP, RP, TP, SP, DP }) => {
  const PP = para.properties;
  let spacing;
  if (PP.spacing) {
    spacing = PP.spacing;
    // delete spacing.line;
  } else if (TP !== undefined) {
    if (TP.para?.spacing) {
      spacing = TP.para.spacing;
    }
  } else if (TP === undefined) {
    spacing = DP.para.spacing;
    // delete spacing.line;
  }
  const child = para.ele.map((E) => {
    if (E.type === "text") {
      return getText({
        text: E,
        PP,
        CP,
        RP,
        TP,
        SP,
        DP,
      });
    } else if (E.type === "image") {
      return getImage({
        image: E,
        PP,
        CP,
        RP,
        TP,
        SP,
        DP,
      });
    } else if (E.type === "symbol") {
      return getSymbol({
        symbol: E,
        PP,
        CP,
        RP,
        TP,
        SP,
        DP,
      });
    } else if (E.type === "checkbox") {
      return getCheckbox({
        checkbox: E,
        PP,
        CP,
        RP,
        TP,
        SP,
        DP,
      });
    }
  });
  return new Paragraph({
    children: child,
    alignment: PP.alignment
      ? PP.alignment === "justified"
        ? "both"
        : PP.alignment
      : "both",
    spacing: spacing,
    shading: PP.shading,
    indent: PP.indent,
    pageBreakBefore: PP.pageBreak,
    thematicBreak: PP.thematicBreak,
    numbering: PP.numbering,
  });
};

const getTableCell = ({ cell, RP, TP, SP, DP }) => {
  const CP = cell.properties;
  let borders = false;
  if (DP.cell.borders) {
    borders = DP.cell.borders;
  }
  if (`${TP.borders}` !== "undefined") {
    borders = TP.borders === true ? true : false;
  }
  if (`${CP.borders}` !== "undefined") {
    borders = CP.borders === true ? true : false;
  }
  let width; // Fix Width
  if (RP.width) {
    if (RP.width.type === "dxa") {
      width = {
        type: "dxa",
        size: parseFloat(RP.width.size),
      };
    } else {
      width = RP.width;
    }
  }
  if (CP.width) {
    width = CP.width;
  }
  const child = cell.ele.map((E) => {
    if (E.type === "para") {
      return getPara({
        para: E,
        CP,
        RP,
        TP,
        SP,
        DP,
      });
    } else if (E.type === "table") {
      return getTable({ table: E, SP, DP });
    }
  });
  return new TableCell({
    children: child,
    margins: CP.margins
      ? CP.margins
      : TP.margins
      ? TP.margins
      : DP.table.margins,
    verticalAlign: DP.cell.verticalAlign ? DP.cell.verticalAlign : "top",
    columnSpan: CP.columnSpan,
    rowSpan: CP.rowSpan,
    borders: !borders ? removeBorder() : borders,
    width: width,
    shading: CP.shading,
  });
};

const getTableRow = ({ row, TP, SP, DP }) => {
  const RP = row.properties;
  console.log(RP.height);
  const child = row.ele.map((E) => {
    return getTableCell({
      cell: E,
      RP,
      TP,
      SP,
      DP,
    });
  });
  return new TableRow({
    children: child,
    height: RP.height ? RP.height : DP.row.height,
  });
};

const getTable = ({ table, SP, DP }) => {
  const TP = table.properties;
  const child = table.ele.map((E) => {
    return getTableRow({ row: E, TP, SP, DP });
  });
  if (table.ele.length > 0) {
    return new Table({
      width: TP.width ? TP.width : DP.table.width,
      alignment: TP.alignment,
      margins: TP.margins ? TP.margins : DP.cell.margins,
      borders: DP.table.borders,
      rows: child,
    });
  }
};

const getHeader = ({ header, DP }) => {
  const child = header.ele.map((E) => {
    if (E.type === "para") {
      return getPara({ para: E, DP });
    } else {
      return getTable({ table: E, DP });
    }
  });

  return new Header({
    children: child,
  });
};

const getFooter = ({ footer, DP }) => {
  const child = footer.ele.map((E) => {
    if (E.type === "para") {
      return getPara({ para: E, DP });
    } else {
      return getTable({ table: E, DP });
    }
  });

  return new Footer({
    children: child,
  });
};

const getSection = ({ section, DP }) => {
  const SP = section.properties;
  let headers = undefined;
  let footers = undefined;
  if (section.headers.type) {
    headers = getHeader({ header: section.headers, DP });
  }
  if (section.footers.type) {
    footers = getFooter({ footer: section.footers, DP });
  }
  const child = section.ele.map((E) => {
    if (E.type === "para") {
      return getPara({ para: E, SP, DP });
    } else {
      return getTable({ table: E, SP, DP });
    }
  });
  return {
    headers:
      headers === undefined
        ? {}
        : {
            [section.headers.type]: headers,
          },
    footers:
      footers === undefined
        ? {}
        : {
            [section.footers.type]: footers,
          },
    children: child,
    properties: {
      page: {
        pageNumbers: {
          start: 1,
          formatType: NumberFormat.DECIMAL,
        },
        borders: SP.page.borders,
        size: {
          orientation: SP.page.size.orientation,
        },
        margin: SP.page.margin,
      },
    },
  };
};

const createTestDoc = ({ doc, path }) => {
  const DP = doc.properties;
  const child = doc.ele.map((E) => {
    return getSection({ section: E, DP });
  });
  return new Document({
    sections: child,
    numbering: DP.numbering,
  });
};

export { createTestDoc };
