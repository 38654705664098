import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSecBorderSpace } from "../../../../../../slices/docsSlice";
import Input from "../../../../../../utils/Input";

const PBRSpace = ({ pageBorderRightSpace, setPageBorderRightSpace }) => {
  const dispatch = useDispatch();
  const { RRIP_S } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">PB Right Space</h2>
      <Input
        className="h-[40px] w-[100px]"
        value={pageBorderRightSpace}
        type="number"
        blur={(e) => {
          dispatch(
            setSecBorderSpace({
              loc: "pageBorderRight",
              section: RRIP_S,
              value: e.target.value,
            })
          );
        }}
        change={(e) => setPageBorderRightSpace(e.target.value)}
      />
    </div>
  );
};

export default PBRSpace;
