import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Input from "../../utils/Input";
import TextArea from "../../utils/TextArea";
import Dropdown from "../../utils/Dropdown";
import { Fragment } from "react";
import Button from "../../utils/Button";
import {
  addUserInput,
  updateUserCondition,
  removeUserInput,
} from "../../slices/entryForm";
import { IoCloseSharp } from "react-icons/io5";
import EFButtons from "./entryForm/buttons";
import EFControls from "./entryForm/controls";
import EFFields from "./entryForm/fields";

import produceClone from "../../components/Lodash";
import { toast } from "react-toastify";
import { setSingleID } from "../../slices/internalDependencySlice";
import { setFilledEF } from "../../slices/entryForm";
import { getForms } from "../../slices/formsSlice";

import { useUpdateDocumentMutation } from "../../slices/documentsApiSlice";

const EntryForm = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { forms, userForms } = useSelector((state) => state.forms);
  const { docId, docs, indexPath, dev, category, name, runStatus } =
    useSelector((state) => state.docs);
  const { entryForm, filledEF } = useSelector((state) => state.entryForm);
  const { internalDependency } = useSelector(
    (state) => state.internalDependency
  );

  const [updateDocument, { isLoading: loadingUpdate }] =
    useUpdateDocumentMutation();

  const { pathname } = useLocation();
  const splitPath = pathname.split("/");
  // let lastIndex = splitPath[splitPath.length - 1];
  // let entryForm = lastIndex.slice(0, -3);

  let newForms = produceClone(forms);
  Object.keys(newForms).forEach((form) => {
    if (!form.includes("$")) {
      if (!window["GF"]) {
        window["GF"] = {
          [form]: newForms[form],
        };
      }
      window["GF"][form] = newForms[form];
    } else {
      window[form] = newForms[form];
    }
  });

  // console.log(forms);
  // console.log(filledEF);

  // let path = "forms[entryForm]";
  // if (userInfo.role === "Manager" || userInfo.role === "Operator") {
  //   path = "forms[entryForm].document";
  // }

  // console.log(docs);

  const updateDocumentHandler = async () => {
    const filledEF = {};
    Object.keys(entryForm).forEach((operator) => {
      let op = entryForm[operator];
      filledEF[operator] = {};
      Object.keys(op).forEach((item) => {
        const opInput = op[item];
        if (opInput.cluster) {
          filledEF[operator][item] = [[]];
          opInput.row.forEach((cluValue, i) => {
            if (cluValue.element === "multi") {
              filledEF[operator][item][0][i] = [[]];
              cluValue.row.forEach((nesValue) => {
                if (nesValue.defaultValue) {
                  filledEF[operator][item][0][i][0].push(nesValue.defaultValue);
                } else {
                  filledEF[operator][item][0][i][0].push("");
                }
              });
            } else {
              if (cluValue.defaultValue) {
                filledEF[operator][item][0].push(cluValue.defaultValue);
              } else {
                filledEF[operator][item][0].push("");
              }
            }
          });
        } else {
          if (opInput.defaultValue) {
            filledEF[operator][item] = opInput.defaultValue;
          } else {
            filledEF[operator][item] = "";
          }
        }
      });
    });

    let newDoc = produceClone(docs);
    newDoc.map((d) => {
      if (d.F) {
        Object.keys(d.F).map((f) => {
          d.F[f] = d.F[f].toString();
        });
      }
    });

    try {
      const res = await updateDocument({
        docId,
        docs: newDoc,
        entryForm,
        filledEF,
        indexPath,
      }).unwrap();
      console.log(res);
      internalDependency.map((doc, i) => {
        if (
          `${res.document.category}$${res.document.name}` ===
          `${doc.category}$${doc.name}`
        ) {
          dispatch(
            setSingleID({
              i,
              doc: res.document,
            })
          );
          dispatch(setFilledEF(res.document.filledEF));
          if (!dev) {
            newForms[`${res.document.category}$${res.document.name}`] =
              res.document;
            newForms[`${res.document.category}$${res.document.name}_EF`] =
              res.document.filledEF;
            dispatch(
              getForms({
                forms: newForms,
                userForms,
              })
            );
          }
        }
      });

      toast.success(res.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="psm">
      {!runStatus && userInfo.role === "Manager" ? (
        <div className="flex justify-center mb-2">
          <button
            className="bg-primary-500 text-light-500 px-2 py-1"
            onClick={() => updateDocumentHandler()}
          >
            Update Doc
          </button>
        </div>
      ) : null}

      <div className="flex gap-4">
        {userInfo.role === "Manager" && !dev ? (
          <div className="flex flex-2 flex-col gap-2">
            <EFButtons operator="user" />
            <div className="min-h-[1000px]">
              {Object.keys(entryForm)?.map((operator) => {
                let op = entryForm[operator];
                if (operator === "user") {
                  return <EFControls op={op} operator="user" />;
                }
              })}
            </div>
            <EFButtons operator="manager" />
            <div className="min-h-[1000px]">
              {Object.keys(entryForm)?.map((operator) => {
                let op = entryForm[operator];
                if (operator === "manager") {
                  return <EFControls op={op} operator="manager" />;
                }
              })}
            </div>
          </div>
        ) : null}
        {/* {dev ? ( */}
        <div className="flex-1 flex flex-col gap-2">
          {Object.keys(entryForm)?.map((operator) => {
            let op = entryForm[operator];
            if (userInfo.role === "User" && operator === "user") {
              return <EFFields op={op} operator={operator} />;
            } else if (userInfo.role === "Manager") {
              return <EFFields op={op} operator={operator} />;
            }
          })}
        </div>
        {/* ) : null} */}
      </div>
    </div>
  );
};

export default EntryForm;
