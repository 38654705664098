import React from "react";
import Button from "../../../../../../utils/Button";
import { useSelector, useDispatch } from "react-redux";
import { addThematicBreak } from "../../../../../../slices/docsSlice";

const LineBreak = ({ thematicBreak, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_P } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">Line Break</h2>
      <Button
        name="LB"
        value={thematicBreak}
        click={() => {
          dispatch(
            addThematicBreak({
              para: RRIP_P,
              value: thematicBreak,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
      />
    </div>
  );
};

export default LineBreak;
