import { returnEle, returnHFEle, returnEI, consoleFun } from "./functions";
import { TABLE, ROW, CELL, PARA, TEXT } from "./elements";

const cellProps = {
  setCellMargin: (state, { payload }) => {
    let cell;
    let table;
    if (payload.layoutEle) {
      cell = returnHFEle(state, payload.cell, payload.layoutEle);
      table = returnHFEle(state, payload.table, payload.layoutEle);
    } else {
      cell = returnEle(state, payload.cell);
      table = returnEle(state, payload.table);
    }
    const doc = returnEle(state, payload.doc);
    if (cell.properties.margins) {
      cell.properties.margins[payload.loc] = payload.value;
    } else if (table.properties.margins) {
      switch (payload.loc) {
        case "left":
          cell.properties.margins = {
            // marginUnitType: table.properties.margins.marginUnitType,
            left: payload.value,
            right: table.properties.margins.right,
            top: table.properties.margins.top,
            bottom: table.properties.margins.bottom,
          };
          break;
        case "right":
          cell.properties.margins = {
            // marginUnitType: table.properties.margins.marginUnitType,
            left: table.properties.margins.left,
            right: payload.value,
            top: table.properties.margins.top,
            bottom: table.properties.margins.bottom,
          };
          break;
        case "top":
          cell.properties.margins = {
            // marginUnitType: table.properties.margins.marginUnitType,
            left: table.properties.margins.left,
            right: table.properties.margins.right,
            top: payload.value,
            bottom: table.properties.margins.bottom,
          };
          break;
        case "bottom":
          cell.properties.margins = {
            // marginUnitType: table.properties.margins.marginUnitType,
            left: table.properties.margins.left,
            right: table.properties.margins.right,
            top: table.properties.margins.top,
            bottom: payload.value,
          };
          break;
      }
    } else {
      switch (payload.loc) {
        case "left":
          cell.properties.margins = {
            // marginUnitType: doc.properties.cellMargin.marginUnitType,
            left: payload.value,
            right: doc.properties.table.margins.right,
            top: doc.properties.table.margins.top,
            bottom: doc.properties.table.margins.bottom,
          };
          break;
        case "right":
          cell.properties.margins = {
            // marginUnitType: doc.properties.table.margins.marginUnitType,
            left: doc.properties.table.margins.left,
            right: payload.value,
            top: doc.properties.table.margins.top,
            bottom: doc.properties.table.margins.bottom,
          };
          break;
        case "top":
          cell.properties.margins = {
            // marginUnitType: doc.properties.table.margins.marginUnitType,
            left: doc.properties.table.margins.left,
            right: doc.properties.table.margins.right,
            top: payload.value,
            bottom: doc.properties.table.margins.bottom,
          };
          break;
        case "bottom":
          cell.properties.margins = {
            // marginUnitType: doc.properties.table.margins.marginUnitType,
            left: doc.properties.table.margins.left,
            right: doc.properties.table.margins.right,
            top: doc.properties.table.margins.top,
            bottom: payload.value,
          };
          break;
      }
    }
  },
  updateCellBorder: (state, { payload }) => {
    let cell;
    if (payload.layoutEle) {
      cell = returnHFEle(state, payload.cell, payload.layoutEle);
    } else {
      cell = returnEle(state, payload.cell);
    }
    if (!payload.value) {
      cell.properties.borders = true;
    } else {
      cell.properties.borders = false;
    }
  },
  setCellWidth: (state, { payload }) => {
    let cell;
    if (payload.layoutEle) {
      cell = returnHFEle(state, payload.cell, payload.layoutEle);
    } else {
      cell = returnEle(state, payload.cell);
    }
    if (cell.properties.width) {
      cell.properties.width[payload.loc] = payload.value;
    } else {
      cell.properties.width = {
        [payload.loc]: payload.value,
      };
    }
  },
  updateColSpan: (state, { payload }) => {
    let newCell;
    let newRow;
    let newTable;
    let cell;
    let row;
    let table;
    let CI;
    let RI;
    let TI;
    if (payload.layoutEle) {
      cell = returnHFEle(state, payload.path, payload.layoutEle);
      row = returnHFEle(state, payload.row, payload.layoutEle);
      table = returnHFEle(state, payload.table, payload.layoutEle);
      CI = payload.path[payload.path.length - 1].split("_")[0];
      RI = payload.row[payload.row.length - 1].split("_")[0];
      TI = payload.table[payload.table.length - 1].split("_")[0];
    } else {
      newCell = returnEI(state, payload.path);
      newRow = returnEI(state, payload.row);
      newTable = returnEI(state, payload.table);
      cell = newCell.ele;
      row = newRow.ele;
      table = newTable.ele;
      CI = newCell.index;
      RI = newRow.index;
      TI = newTable.index;
    }

    let NCS = parseFloat(payload.value);

    const doesColspanExists = (cellProps) => {
      if (cellProps.columnSpan) {
        return cellProps.columnSpan;
      }
      return -1;
    };
    const doesRowspanExists = (cellProps) => {
      if (cellProps.rowSpan) {
        return cellProps.rowSpan;
      }
      return -1;
    };

    const checkNextCellSpanning = (cellProps) => {
      if (cellProps.columnSpan || cellProps.rowSpan) {
        return false;
      }
      return true;
    };

    const findClosestNumber = (arr, num) => {
      // consoleFun(arr);
      let closest = null;
      let minDifference = Infinity;
      for (let n of arr) {
        if (n.name === num) {
          return n.name;
        }
        let difference = Math.abs(n.name - num);
        if (difference < minDifference) {
          minDifference = difference;
          closest = n.name;
        }
      }
      return closest;
    };

    let RS = doesRowspanExists(cell.properties);
    let CCS = doesColspanExists(cell.properties);

    if (RS === -1 && CCS === -1) {
      if (NCS > 1) {
        let count = -1;
        for (let i = CI + 1; i < NCS + CI; i++) {
          if (row.ele[i]) {
            if (!checkNextCellSpanning(row.ele[i].properties)) {
              count++;
              break;
            }
          } else {
            count++;
            break;
          }
        }
        if (count === -1) {
          if (NCS > 1) {
            cell.properties.columnSpan = NCS;
          } else {
            delete cell.properties.columnSpan;
          }
          let diff = NCS - 1;
          //let diff = NCS + CI - (CI + 1); // Why instead of NCS - 1?
          row.ele.splice(CI + 1, diff);
        }
      }
    } else if (CCS > 1 && RS === -1) {
      if (NCS > CCS) {
        let SD = NCS - CCS; // Span Differance
        let count = -1;
        for (let i = CI + 1; i < SD + CI + 1; i++) {
          if (row.ele[i]) {
            if (!checkNextCellSpanning(row.ele[i].properties)) {
              count++;
              break;
            }
          } else {
            count++;
            break;
          }
        }
        if (count === -1) {
          if (NCS > 1) {
            cell.properties.columnSpan = NCS;
          } else {
            delete cell.properties.columnSpan;
          }
          let diff = SD;
          row.ele.splice(CI + 1, diff);
        }
      } else if (NCS < CCS) {
        let SD = CCS - NCS;
        if (NCS > 1) {
          cell.properties.columnSpan = NCS;
        } else {
          delete cell.properties.columnSpan;
        }
        let arr = [];
        for (let i = 0; i < SD; i++) {
          let c = structuredClone(CELL);
          c.name = cell.name + NCS + i;
          arr.push(c);
        }
        row.ele.splice(CI + 1, 0, ...arr);
      }
    } else if (CCS === -1 && RS > 1) {
      if (NCS > 1) {
        let CN = cell.name;
        let count = -1;
        outerloop: for (let i = RI; i < RS + RI; i++) {
          for (let j = 0; j < table.ele[i].ele.length; j++) {
            if (table.ele[i].ele[j].name === CN + 1) {
              if (table.ele[i].ele[j]) {
                if (!checkNextCellSpanning(table.ele[i].ele[j].properties)) {
                  count++;
                  break outerloop;
                }
              } else {
                count++;
                break outerloop;
              }
              break;
            }
          }
        }
        if (count === -1) {
          if (NCS > 1) {
            cell.properties.columnSpan = NCS;
          } else {
            delete cell.properties.columnSpan;
          }
          let diff = NCS - 1;
          for (let i = RI; i < RS + RI; i++) {
            for (let j = 0; j < table.ele[i].ele.length; j++) {
              if (table.ele[i].ele[j].name === CN + 1) {
                table.ele[i].ele.splice(j, diff);
              }
            }
          }
        }
      }
    } else if (CCS > 1 && RS > 1) {
      if (NCS > CCS) {
        let SD = NCS - CCS;
        let count = -1;
        outerloop: for (let i = RI; i < RS + RI; i++) {
          for (let j = 0; j < table.ele[i].ele.length; j++) {
            // if (
            //   table.ele[i].ele[j].name <= cell.properties.columnSpan + SD &&
            //   table.ele[i].ele[j].name !== cell.name
            // ) {
            if (
              table.ele[i].ele[j].name >= CCS + cell.name &&
              table.ele[i].ele[j].name < NCS + cell.name
            ) {
              if (table.ele[i].ele[j]) {
                if (!checkNextCellSpanning(table.ele[i].ele[j].properties)) {
                  count++;
                  break outerloop;
                }
              } else {
                count++;
                break outerloop;
              }
              break;
            }
          }
        }
        if (count === -1) {
          let diff = SD;
          for (let i = RI; i < RS + RI; i++) {
            for (let j = 0; j < table.ele[i].ele.length; j++) {
              if (
                // table.ele[i].ele[j].name === cell.properties.columnSpan && // check this
                // table.ele[i].ele[j].name !== cell.name
                table.ele[i].ele[j].name ===
                CCS + cell.name
              ) {
                table.ele[i].ele.splice(j, diff);
              }
            }
          }
          if (NCS > 1) {
            cell.properties.columnSpan = NCS;
          } else {
            delete cell.properties.columnSpan;
          }
        }
      } else if (NCS < CCS) {
        let SD = CCS - NCS;
        for (let i = RI; i < RS + RI; i++) {
          let arr = [];
          for (let j = 0; j < SD; j++) {
            let c = structuredClone(CELL);
            // c.name = cell.properties.columnSpan - SD + j;
            c.name = cell.name + cell.properties.columnSpan - (j + 1);
            arr.push(c);
          }
          arr = arr.reverse();
          if (table.ele[i].ele.length === 0) {
            table.ele[i].ele.splice(1, 0, ...arr);
          } else {
            let NN = findClosestNumber(table.ele[i].ele, cell.name); // Nearest Number
            for (let j = 0; j < table.ele[i].ele.length; j++) {
              if (table.ele[i].ele[j].name === cell.name) {
                table.ele[i].ele.splice(j + 1, 0, ...arr);
                break;
              } else if (table.ele[i].ele[j].name === NN) {
                if (NN > cell.name) {
                  table.ele[i].ele.splice(j, 0, ...arr);
                } else {
                  table.ele[i].ele.splice(j + 1, 0, ...arr);
                }
                break;
              }
            }
          }
        }
        if (NCS > 1) {
          cell.properties.columnSpan = NCS;
        } else {
          delete cell.properties.columnSpan;
        }
      }
    }
  },
  updateRowSpan: (state, { payload }) => {
    const { ele: cell, index: CI } = returnEI(state, payload.path); // CI = Cell Index
    const { ele: row, index: RI } = returnEI(state, payload.row); // RI = Row Index
    const { ele: table, index: TI } = returnEI(state, payload.table); // TI = Table Index

    let NRS = parseFloat(payload.value);

    const doesRowspanExists = (cellProps) => {
      if (cellProps.rowSpan) {
        return cellProps.rowSpan;
      }
      return -1;
    };
    const doesColspanExists = (cellProps) => {
      if (cellProps.columnSpan) {
        return cellProps.columnSpan;
      }
      return -1;
    };

    const checkNextCellSpanning = (cellProps) => {
      if (cellProps.columnSpan || cellProps.rowSpan) {
        return false;
      }
      return true;
    };

    const findClosestNumber = (arr, num) => {
      // consoleFun(arr);
      let closest = null;
      let minDifference = Infinity;
      for (let n of arr) {
        if (n.name === num) {
          return n.name;
        }
        let difference = Math.abs(n.name - num);
        if (difference < minDifference) {
          minDifference = difference;
          closest = n.name;
        }
      }
      return closest;
    };

    let CS = doesColspanExists(cell.properties);
    let CRS = doesRowspanExists(cell.properties);

    if (CS === -1 && CRS === -1) {
      if (NRS > 1) {
        let count = -1;
        let CN = cell.name;
        outerloop: for (let i = RI; i < NRS + RI; i++) {
          let count2 = 0;
          for (let j = 0; j < table.ele[i].ele.length; j++) {
            if (table.ele[i].ele[j].name === CN) {
              count2++;
              if (!checkNextCellSpanning(table.ele[i].ele[j].properties)) {
                count++;
                break outerloop;
              }
              break;
            }
          }
          if (count2 === 0) {
            count++;
            break;
          }
        }
        if (count === -1) {
          if (NRS > 1) {
            cell.properties.rowSpan = NRS;
          } else {
            delete cell.properties.rowSpan;
          }
          for (let i = RI + 1; i < NRS + RI; i++) {
            table.ele[i].ele.map((C, j) => {
              if (C.name === CN) {
                table.ele[i].ele.splice(j, 1);
              }
            });
          }
        }
      }
    } else if (CRS > 1 && CS === -1) {
      if (NRS > CRS) {
        let SD = NRS - CRS; // Span Differance
        let count = -1;
        let CN = cell.name;
        outerloop: for (let i = RI + 1; i < SD + RI + 1; i++) {
          for (let j = 0; j < table.ele[i].ele.length; j++) {
            if (table.ele[i].ele[j].name === CN) {
              if (table.ele[i].ele[j]) {
                if (!checkNextCellSpanning(table.ele[i].ele[j].properties)) {
                  count++;
                  break outerloop;
                }
              } else {
                count++;
                break outerloop;
              }
              break;
            }
          }
        }

        if (count === -1) {
          if (NRS > 1) {
            cell.properties.rowSpan = NRS;
          } else {
            delete cell.properties.rowSpan;
          }
          for (let i = RI + 1; i < NRS + RI; i++) {
            table.ele[i].ele.map((C, j) => {
              if (C.name === CN) {
                table.ele[i].ele.splice(j, 1);
              }
            });
          }
        }
      } else if (NRS < CRS) {
        let SD = CRS - NRS; // Span Differance
        let CN = cell.name;
        let c = structuredClone(CELL);
        c.name = CN;
        // for (let i = RI + NRS; i < cell.properties.rowSpan; i++) {
        for (let i = RI + NRS; i < RI + cell.properties.rowSpan; i++) {
          if (table.ele[i].ele.length === 0) {
            table.ele[i].ele.splice(1, 0, c);
          } else {
            let NN = findClosestNumber(table.ele[i].ele, CN);
            for (let j = 0; j < table.ele[i].ele.length; j++) {
              if (table.ele[i].ele[j].name === CN) {
                table.ele[i].ele.splice(j + 1, 0, c);
                break;
              } else if (table.ele[i].ele[j].name === NN) {
                if (NN > CN) {
                  table.ele[i].ele.splice(j, 0, c);
                } else {
                  table.ele[i].ele.splice(j + 1, 0, c);
                }
                break;
              }
            }
          }
        }
        if (NRS > 1) {
          cell.properties.rowSpan = NRS;
        } else {
          delete cell.properties.rowSpan;
        }
      }
    } else if (CRS === -1 && CS > 1) {
      if (NRS > 1) {
        let count = -1;
        let CN = cell.name;
        outerloop: for (let i = RI + 1; i < RI + NRS; i++) {
          let count2 = 0;
          for (let j = 0; j < table.ele[i].ele.length; j++) {
            if (table.ele[i].ele[j].name === CN) {
              count2++;
              for (let k = 0; k < CS; k++) {
                if (table.ele[i].ele[k + j]) {
                  if (
                    !checkNextCellSpanning(table.ele[i].ele[k + j].properties)
                  ) {
                    count++;
                    break outerloop;
                  }
                } else {
                  count++;
                  break outerloop;
                }
              }
              break;
            }
          }
          if (count2 === 0) {
            count++;
            break;
          }
        }
        if (count === -1) {
          let diff = CS;
          for (let i = RI + 1; i < NRS + RI; i++) {
            for (let j = 0; j < table.ele[i].ele.length; j++) {
              if (table.ele[i].ele[j].name === CN) {
                table.ele[i].ele.splice(j, diff);
              }
            }
          }
          if (NRS > 1) {
            cell.properties.rowSpan = NRS;
          } else {
            delete cell.properties.rowSpan;
          }
        }
      }
    } else if (CRS > 1 && CS > 1) {
      if (NRS > CRS) {
        let SD = NRS - CRS; // Span Differance
        let count = -1;
        let CN = cell.name;
        outerloop: for (let i = RI + CRS; i < RI + NRS; i++) {
          let count2 = 0;
          for (let j = 0; j < table.ele[i].ele.length; j++) {
            if (table.ele[i].ele[j].name === CN) {
              count2++;
              for (let k = 0; k < CS; k++) {
                if (table.ele[i].ele[k + j]) {
                  if (
                    !checkNextCellSpanning(table.ele[i].ele[k + j].properties)
                  ) {
                    count++;
                    break outerloop;
                  }
                } else {
                  count++;
                  break outerloop;
                }
              }
            }
            break;
          }
          if (count2 === 0) {
            count++;
            break;
          }
        }
        if (count === -1) {
          let diff = CS;
          for (let i = RI + CRS; i < RI + NRS; i++) {
            for (let j = 0; j < table.ele[i].ele.length; j++) {
              if (table.ele[i].ele[j].name === CN) {
                table.ele[i].ele.splice(j, diff);
                break;
              }
            }
          }
          if (NRS > 1) {
            cell.properties.rowSpan = NRS;
          } else {
            delete cell.properties.rowSpan;
          }
        }
      } else if (NRS < CRS) {
        let CN = cell.name;
        for (let i = RI + NRS; i < RI + CRS; i++) {
          let arr = [];
          for (let j = 0; j < CS; j++) {
            let c = structuredClone(CELL);
            c.name = cell.name + CS - (j + 1);
            arr.push(c);
          }
          arr = arr.reverse();
          if (table.ele[i].ele.length === 0) {
            table.ele[i].ele.splice(1, 0, ...arr);
          } else {
            let NN = findClosestNumber(table.ele[i].ele, cell.name);
            for (let j = 0; j < table.ele[i].ele.length; j++) {
              if (table.ele[i].ele[j].name === NN) {
                if (NN > cell.name) {
                  table.ele[i].ele.splice(j, 0, ...arr);
                } else {
                  table.ele[i].ele.splice(j + 1, 0, ...arr);
                }
                break;
              }
            }
          }
          if (NRS > 1) {
            cell.properties.rowSpan = NRS;
          } else {
            delete cell.properties.rowSpan;
          }
        }
      }
    }
  },
  replaceTable: (state, { payload }) => {
    const ele = returnEle(state, payload.path);
    ele.ele.pop();
    let Rows = [];
    for (let i = 0; i < payload.rows; i++) {
      let Cols = [];
      for (let j = 0; j < payload.cols; j++) {
        let text = structuredClone(TEXT);
        let para = structuredClone(PARA);
        let cell = structuredClone(CELL);
        para.name = cell.ele.length;
        para.ele.push(text);
        cell.ele.push(para);
        Cols.push({
          ...cell,
          name: j,
        });
      }
      const row = structuredClone(ROW);
      Rows.push({
        ...row,
        ele: Cols,
        name: i,
      });
    }
    const table = structuredClone(TABLE);
    ele.ele = [
      {
        ...table,
        ele: Rows,
        name: payload.name ? payload.name : ele.ele.length,
        // nor: payload.rows,
        // noc: payload.cols,
      },
    ];
  },
};

export default cellProps;
