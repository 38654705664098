import React from "react";
import TextArea from "../../../../utils/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { updateFEF } from "../../../../slices/entryForm";
import { updateFFEF } from "../../../../slices/formsSlice";

const FTextarea = ({ ele, operator }) => {
  const dispatch = useDispatch();
  const { dev, category, name } = useSelector((state) => state.docs);
  const { filledEF } = useSelector((state) => state.entryForm);
  const { userInfo } = useSelector((state) => state.auth);
  return (
    <div>
      <h2 className="pb-1">{ele.placeholder}</h2>
      <TextArea
        className="h-[100px]"
        placeholder={ele.placeholder}
        value={filledEF[operator][ele.placeholder]}
        change={(e) => {
          let value = e.target.value;
          if (ele.caps) {
            value = value.toUpperCase();
          }
          dispatch(
            updateFEF({
              operator: operator,
              name: ele.placeholder,
              value: value,
            })
          );
          dispatch(
            updateFFEF({
              EF: `${category}$${name}_EF`,
              name: ele.placeholder,
              value: value,
              operator,
            })
          );
        }}
        click={() => {
          if (userInfo.role === "Manager") {
            let text = `${category}$${name}_EF['${operator}']['${ele.placeholder}']`;
            navigator.clipboard.writeText(text);
          }
        }}
      />
    </div>
  );
};

export default FTextarea;
