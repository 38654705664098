import React from "react";
import Input from "../../../../../utils/Input";
import { useSelector, useDispatch } from "react-redux";
import { updateFEF } from "../../../../../slices/entryForm";
import { updateFFEF } from "../../../../../slices/formsSlice";

const MINPUT = ({ cluster, ele, operator, i, j, k, l }) => {
  const dispatch = useDispatch();
  const { entryForm, filledEF } = useSelector((state) => state.entryForm);
  const { userInfo } = useSelector((state) => state.auth);
  const { dev, category, name } = useSelector((state) => state.docs);
  const { forms } = useSelector((state) => state.forms);

  let val;
  if (k !== undefined) {
    val = filledEF[operator]?.[cluster]?.[i]?.[j]?.[k]?.[l] || "";
  } else {
    val = filledEF[operator]?.[cluster]?.[i]?.[j] || "";
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="pb-1">{ele.placeholder}</h2>
        {ele.unit && <h2>[{ele.unit}]</h2>}
      </div>
      <Input
        maxLength={ele.maxLength ? parseFloat(ele.maxLength) : ""}
        type={ele.type}
        placeholder={ele.placeholder}
        value={val}
        change={(e) => {
          let value = e.target.value;
          if (ele.caps) {
            value = value.toUpperCase();
          }
          if (filledEF[operator] && filledEF[operator][cluster]) {
            if (k !== undefined) {
              dispatch(
                updateFEF({
                  cluster: cluster,
                  i,
                  j,
                  k,
                  l,
                  operator: operator,
                  name: ele.placeholder,
                  value: value,
                })
              );
              dispatch(
                updateFFEF({
                  cluster: cluster,
                  i,
                  j,
                  k,
                  l,
                  EF: `${category}$${name}_EF`,
                  name: ele.placeholder,
                  value: value,
                  operator,
                })
              );
            } else {
              dispatch(
                updateFEF({
                  cluster: cluster,
                  i,
                  j,
                  operator: operator,
                  name: ele.placeholder,
                  value: value,
                })
              );
              dispatch(
                updateFFEF({
                  cluster: cluster,
                  i,
                  j,
                  EF: `${category}$${name}_EF`,
                  name: ele.placeholder,
                  value: value,
                  operator,
                })
              );
            }
          }
        }}
        click={() => {
          if (userInfo.role === "Manager") {
            let text = `${category}$${name}_EF['${operator}']['${ele.placeholder}']`;
            navigator.clipboard.writeText(text);
          }
        }}
      />
    </div>
  );
};

export default MINPUT;
