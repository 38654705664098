import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Dropdown from "../../../../../utils/Dropdown";
import Input from "../../../../../utils/Input";
import Button from "../../../../../utils/Button";
import {
  setTableWidth,
  setTableCellMargin,
  updateTableBorder,
  setTableAlignment,
  addColumnToTable,
  addRowToTable,
  updateShading,
  // TableCell properties
  setTableCellAlignment,
  // TablePara properties
  setTableParaAlignment,
  // TableText properties
  setTableTextFont,
  setTableTextFontSize,
} from "../../../../../slices/docsSlice";
import {
  returnPropEle,
  returnPropHFEle,
  switchWidthType,
  switchVerAlignment,
} from "../CommFunctions";
import JsFunControls from "../Tools/jsFunControls";

import AddColumnToTable from "./components/AddColumnToTable";
import AddRowToTable from "./components/AddRowToTable";
import WidthType from "./components/WidthType";
import WidthSize from "./components/WidthSize";
import Border from "./components/Border";
import Alignment from "./components/Alignment";
import MarginLeft from "./components/MarginLeft";
import MarginRight from "./components/MarginRight";
import MarginTop from "./components/MarginTop";
import MarginBottom from "./components/MarginBottom";

import VerticalAlignment from "./components/cell/VerticalAlignment";

import ParaAlignment from "./components/para/ParaAlignment";

import Font from "./components/text/Font";
import TextSize from "./components/text/TextSize";
import SpacingBefore from "./components/para/SpacingBefore";
import SpacingAfter from "./components/para/SpacingAfter";

const TableProperties = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    docs,
    indexPath,
    SIP,
    RRIP_TB,
    RRIP_DOC,
    NIP_TB,
    NIP_DOC,
    dev,
    minimal,
  } = useSelector((state) => state.docs);
  const { tableName } = useSelector((state) => state.element);
  // const { productInfo } = useSelector((state) => state.product);
  const {
    fonts,
    fontSizes,
    widthTypes,
    alignments,
    cellAlignments,
    tableAlignments,
    isTrueOrFalse,
    shadingTypes,
  } = useSelector((state) => state.options);

  const [widthType, setWidthType] = useState();
  const [widthSize, setWidthSize] = useState();
  const [marginTop, setMarginTop] = useState();
  const [marginBottom, setMarginBottom] = useState();
  const [marginLeft, setMarginLeft] = useState();
  const [marginRight, setMarginRight] = useState();
  const [border, setBorder] = useState();
  const [alignment, setAlignment] = useState();

  // Row Properties

  // Cell Properties
  const [verticalAlign, setVerticalAlign] = useState();
  // Para Properties
  const [paraAlignment, setParaAlignment] = useState();
  const [spacingBefore, setSpacingBefore] = useState();
  const [spacingAfter, setSpacingAfter] = useState();
  const [spacingLine, setSpacingLine] = useState();
  // Text Properties
  const [font, setFont] = useState();
  const [fontSize, setFontSize] = useState();

  const [tableStatus, setTableStatus] = useState(dev ? "jsfun" : "properties");

  let SIPHF = SIP[2];

  useEffect(() => {
    if (docs.length === 0) {
      navigate(`${newPath}`);
    }
  }, [docs]);

  useEffect(() => {
    setTableStatus(dev ? "jsfun" : "properties");
  }, [indexPath, dev]);

  let dynEle = "docs";
  NIP_TB.split("_").forEach((item, i) => {
    dynEle =
      dynEle + `['${item}'].${i === NIP_TB.split("_").length - 1 ? "" : "ele"}`;
  });

  dynEle = dynEle.slice(0, -1);

  useEffect(() => {
    let table;
    if (SIPHF === "0_H" || SIPHF === "0_F") {
      table = RRIP_TB ? returnPropHFEle(docs, RRIP_TB, SIPHF) : undefined;
    } else {
      table = RRIP_TB ? returnPropEle(docs, RRIP_TB) : undefined;
    }
    const doc = returnPropEle(docs, RRIP_DOC);

    if (table) {
      if (table.properties.width) {
        setWidthType(table.properties.width.type);
        setWidthSize(table.properties.width.size);
      } else {
        setWidthType(doc.properties.table.width.type);
        setWidthSize(doc.properties.table.width.size);
      }
      if (table.properties.margins) {
        setMarginLeft(table.properties.margins.left);
        setMarginRight(table.properties.margins.right);
        setMarginTop(table.properties.margins.top);
        setMarginBottom(table.properties.margins.bottom);
      } else if (doc.properties.cell.margins) {
        setMarginLeft(doc.properties.cell.margins.left);
        setMarginRight(doc.properties.cell.margins.right);
        setMarginTop(doc.properties.cell.margins.top);
        setMarginBottom(doc.properties.cell.margins.bottom);
      } else {
        setMarginLeft(doc.properties.table.margins.left);
        setMarginRight(doc.properties.table.margins.right);
        setMarginTop(doc.properties.table.margins.top);
        setMarginBottom(doc.properties.table.margins.bottom);
      }

      // console.log(table.properties);

      if (table.properties.borders !== undefined) {
        setBorder(table.properties.borders);
      } else if (doc.properties.table.borders !== undefined) {
        setBorder(true);
      } else {
        setBorder(false);
      }
      if (table.properties.alignment) {
        setAlignment(table.properties.alignment);
      }

      // TableCell Properties
      if (table.properties.cell?.verticalAlign) {
        setVerticalAlign(table.properties.cell.verticalAlign);
      } else {
        setVerticalAlign(doc.properties.cell.verticalAlign);
      }

      // TablePara Properties
      if (table.properties.para?.alignment) {
        setParaAlignment(table.properties.para.alignment);
      } else {
        setParaAlignment(doc.properties.para.alignment);
      }

      if (table.properties.para?.spacing) {
        setSpacingBefore(table.properties.para.spacing.before);
        setSpacingAfter(table.properties.para.spacing.after);
        setSpacingLine(table.properties.para.spacing.line);
      } else {
        setSpacingBefore();
        setSpacingAfter();
        setSpacingLine();
      }

      // TableText Properties
      if (table.properties.text.font) {
        setFont(table.properties.text.font);
      } else {
        setFont(doc.properties.text.font);
      }
      if (table.properties.text.fontSize) {
        setFontSize(table.properties.text.fontSize);
      } else {
        setFontSize(doc.properties.text.fontSize);
      }
    }
  }, [docs, NIP_DOC, NIP_TB]);

  let activeStyle = "bg-cyan-950 text-light-500";

  return (
    <section className="psm bg-gray-100">
      {!dev ? (
        <div className="mb-2">
          <Outlet />
        </div>
      ) : null}

      {tableStatus === "properties" ? (
        <>
          <div>
            <h1 className="font-mono text-center pb-4">
              Table Properties [{tableName ? tableName : ""}]
            </h1>
            <div className="flex flex-col gap-4 pb-4">
              <div className="flex flex-wrap gap-4">
                <AddRowToTable SIPHF={SIPHF} />
                <AddColumnToTable SIPHF={SIPHF} />
              </div>
              <div className="flex flex-wrap gap-4">
                <WidthType SIPHF={SIPHF} />
                <WidthSize SIPHF={SIPHF} />
                <Border border={border} SIPHF={SIPHF} />
                <Alignment alignment={alignment} SIPHF={SIPHF} />
              </div>
              <div className={`${minimal ? "hidden" : "block"}`}>
                <div className="flex flex-wrap gap-4">
                  <MarginLeft
                    marginLeft={marginLeft}
                    setMarginLeft={(value) => setMarginLeft(value)}
                    SIPHF={SIPHF}
                  />
                  <MarginRight
                    marginRight={marginRight}
                    setMarginRight={(value) => setMarginRight(value)}
                    SIPHF={SIPHF}
                  />
                  <MarginTop
                    marginTop={marginTop}
                    setMarginTop={(value) => setMarginTop(value)}
                    SIPHF={SIPHF}
                  />
                  <MarginBottom
                    marginBottom={marginBottom}
                    setMarginBottom={(value) => setMarginBottom(value)}
                    SIPHF={SIPHF}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <h1 className="mb-1 bg-green-500 pl-2">Row Properties</h1>
            <div className="flex flex-wrap gap-4 pb-4"></div>
          </div> */}
          <div className={`${minimal ? "hidden" : "block"}`}>
            <h1 className="mb-1 bg-yellow-500 pl-2">Cell Properties</h1>
            <div className="flex flex-wrap gap-4 pb-4">
              <VerticalAlignment verticalAlign={verticalAlign} SIPHF={SIPHF} />
            </div>
          </div>
          <div className={`${minimal ? "hidden" : "block"}`}>
            <h1 className="mb-1 bg-teal-500 pl-2">Para Properties</h1>
            <div className="flex flex-wrap gap-4 pb-4">
              <ParaAlignment paraAlignment={paraAlignment} SIPHF={SIPHF} />
              <SpacingBefore
                spacingBefore={spacingBefore}
                setSpacingBefore={(value) => setSpacingBefore(value)}
                SIPHF={SIPHF}
              />
              <SpacingAfter
                spacingAfter={spacingAfter}
                setSpacingAfter={(value) => setSpacingAfter(value)}
                SIPHF={SIPHF}
              />
            </div>
          </div>
          <div className={`${minimal ? "hidden" : "block"}`}>
            <h1 className="mb-1 bg-purple-500 pl-2">Text Properties</h1>
            <div className="flex flex-wrap gap-4 pb-4">
              <Font font={font} SIPHF={SIPHF} />
              <TextSize
                fontSize={fontSize}
                setFontSize={(value) => setFontSize(value)}
                SIPHF={SIPHF}
              />
            </div>
          </div>
        </>
      ) : tableStatus === "jsfun" ? (
        <div className="flex flex-col">
          <JsFunControls path={RRIP_TB} dynEle={dynEle} />
        </div>
      ) : (
        <></>
      )}
    </section>
  );
};

export default TableProperties;
