import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTableWidth } from "../../../../../../slices/docsSlice";
import Dropdown from "../../../../../../utils/Dropdown";

const WidthType = ({ widthType, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TB } = useSelector((state) => state.docs);
  const { widthTypes } = useSelector((state) => state.options);
  return (
    <div>
      <h2 className="pb-1">Set Width Type</h2>
      <Dropdown
        className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
        value={widthType}
        options={widthTypes}
        target="option"
        name="WT"
        placeholder="width type"
        width={150}
        height={40}
        click={(value) => {
          dispatch(
            setTableWidth({
              loc: "type",
              table: RRIP_TB,
              value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
      />
    </div>
  );
};

export default WidthType;
