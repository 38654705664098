import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const RAG_URL = "http://127.0.0.1:1234";

const ragApi = createApi({
  reducerPath: "ragApi",
  baseQuery: fetchBaseQuery({ baseUrl: RAG_URL }),
  endpoints: (builder) => ({
    getRagFunc: builder.mutation({
      query: (data) => ({
        url: "/v1/completions",
        method: "POST",
        body: data,
        credentials: "omit",
      }),
    }),
  }),
});

export const { useGetRagFuncMutation } = ragApi;
