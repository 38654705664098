import React from "react";
import Input from "../../../../../../utils/Input";
import { useDispatch, useSelector } from "react-redux";
import { updateSpacing } from "../../../../../../slices/docsSlice";

const SpacingLine = ({ spacingLine, setSpacingLine, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_P, RRIP_DOC } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">Line</h2>
      <Input
        className="h-[40px] w-[100px]"
        value={spacingLine}
        blur={(e) =>
          dispatch(
            updateSpacing({
              loc: "line",
              para: RRIP_P,
              doc: RRIP_DOC,
              value: e.target.value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          )
        }
        change={(e) => setSpacingLine(e.target.value)}
        type="text"
      />
    </div>
  );
};

export default SpacingLine;
