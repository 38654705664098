import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCellWidth } from "../../../../../../../../slices/docsSlice";
import Input from "../../../../../../../../utils/Input";

const WidthSize = ({ widthSize, setWidthSize, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TC } = useSelector((state) => state.docs);
  return (
    <div className="mb-2">
      <h2 className="pb-1">Set Width Size</h2>
      <Input
        className="h-[40px] w-[150px]"
        value={widthSize}
        type="text"
        blur={(e) => {
          dispatch(
            setCellWidth({
              loc: "size",
              cell: RRIP_TC,
              value: e.target.value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
        change={(e) => setWidthSize(e.target.value)}
        // disabled={widthType === "NIL" ? true : false}
      />
    </div>
  );
};

export default WidthSize;
