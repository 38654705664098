import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setTableParaAlignment } from "../../../../../../../slices/docsSlice";
import Dropdown from "../../../../../../../utils/Dropdown";

const ParaAlignment = ({ paraAlignment, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TB } = useSelector((state) => state.docs);
  const { alignments } = useSelector((state) => state.options);
  return (
    <div>
      <h2 className="pb-1">Horizontal Alignment</h2>
      <Dropdown
        className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
        value={paraAlignment}
        options={alignments}
        target="option"
        placeholder="alignment"
        name="HL"
        width={150}
        height={40}
        click={(value) => {
          dispatch(
            setTableParaAlignment({
              table: RRIP_TB,
              value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
      />
    </div>
  );
};

export default ParaAlignment;
