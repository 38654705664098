import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTableCellAlignment } from "../../../../../../../slices/docsSlice";
import Dropdown from "../../../../../../../utils/Dropdown";

const VerticalAlignment = ({ verticalAlign, SIPHF }) => {
  const { RRIP_TB } = useSelector((state) => state.docs);
  const dispatch = useDispatch();
  const { cellAlignments } = useSelector((state) => state.options);
  return (
    <div>
      <h2 className="pb-1">Vertical Alignment</h2>
      <Dropdown
        className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
        value={verticalAlign}
        options={cellAlignments}
        target="option"
        placeholder="alignment"
        width={150}
        height={40}
        click={(value) => {
          dispatch(
            setTableCellAlignment({
              table: RRIP_TB,
              value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
      />
    </div>
  );
};

export default VerticalAlignment;
