import React from "react";
import EFInput from "./elements/Input";
import EFTextArea from "./elements/TextArea";
import EFDropDown from "./elements/DropDown";
import EFDate from "./elements/Date";
import EFMulti from "./elements/multi";
import CInput from "./elements/CInput";
import CTextArea from "./elements/CTextArea";
import CDropDown from "./elements/CDropDown";
import CDate from "./elements/CDate";
import CMulti from "./elements/CMulti";

const EFControls = ({ op, operator }) => {
  return (
    <div className="flex flex-wrap gap-4">
      {Object.keys(op).length > 0
        ? Object.keys(op)?.map((managerInput, i) => {
            let opInput = op[managerInput];
            // console.log(op, opInput);
            switch (opInput.element) {
              case "input":
                return <EFInput opInput={opInput} operator={operator} />;
              case "textarea":
                return <EFTextArea opInput={opInput} operator={operator} />;
              case "dropdown":
                return <EFDropDown opInput={opInput} operator={operator} />;
              case "date":
                return <EFDate opInput={opInput} operator={operator} />;
              case "conditional_input":
                return <CInput opInput={opInput} operator={operator} />;
              case "conditional_textarea":
                return <CTextArea opInput={opInput} operator={operator} />;
              case "conditional_dropdown":
                return <CDropDown opInput={opInput} operator={operator} />;
              case "conditional_date":
                return <CDate opInput={opInput} operator={operator} />;
              case "multi":
                return (
                  <EFMulti
                    index={`${i}`}
                    opInput={opInput}
                    operator={operator}
                  />
                );
              case "conditional_multi":
                return (
                  <CMulti
                    index={`${i}`}
                    opInput={opInput}
                    operator={operator}
                  />
                );
            }
          })
        : null}
    </div>
  );
};

export default EFControls;
