import { useState } from "react";
import { getTextStyle } from "./Styles/textStyle";
import { useDispatch, useSelector } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";
import { setIndexPath, removeEle } from "../../../../slices/docsSlice";
import { useNavigate } from "react-router-dom";
const CreateText = ({
  text,
  index,
  loc,
  para,
  cell,
  row,
  table,
  sec,
  doc,
  baseURL,
  SIPHF,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { RRIP_T, NIP_T, dev, category, name } = useSelector(
    (state) => state.docs
  );
  const [show, setShow] = useState(false);

  const toggleDropdown = (index) => {
    setShow((prevShowOptions) => {
      return {
        ...prevShowOptions,
        [index]: !prevShowOptions[index],
      };
    });
  };

  let splitLoc = loc.split("/");
  let splitIndex = index.split("_");
  let lastIndex = splitIndex[splitIndex.length - 1];

  const textStyle = getTextStyle({
    textProps: text?.properties,
    paraProps: para?.properties,
    cellProps: cell?.properties,
    rowProps: row?.properties,
    tableProps: table?.properties,
    sectionProps: sec?.properties,
    docProps: doc?.properties,
  });
  let textCopyABS = `${category}$${name}.document`;
  let paraCopyABS = `${category}$${name}.document`;
  let cellCopyABS = `${category}$${name}.document`;
  let rowCopyABS = `${category}$${name}.document`;
  let tableCopyABS = `${category}$${name}.document`;
  let textCopy = "CES";
  let paraCopy = "CES";
  let cellCopy = "CES";
  let rowCopy = "CES";
  let tableCopy = "CES";
  let tableText = "T";
  let rowText = "R";
  let cellText = "T";
  let ele = `${category}_EF`;
  if (dev) {
    if (false) {
      textCopy = "";
      paraCopy = "";
      cellCopy = "";
      rowCopy = "";
      tableCopy = "";
    }

    splitIndex.map((i, index) => {
      if (index > 2) {
        tableText = tableText + `[${i}].ele`;
      }
      if (index > 3) {
        rowText = rowText + `.ele.[${i}].ele`;
      }
      if (index > 4) {
        cellText = cellText + `[${i}].ele`;
      }
      if (index <= 2) {
        ele = ele + `[${i}].ele`;
      }

      if (index <= splitIndex.length - 5) {
        tableCopy = tableCopy + `[${i}].ele`;
        tableCopyABS = tableCopyABS + `[${i}].ele`;
      }
      if (index <= splitIndex.length - 4) {
        rowCopy = rowCopy + `[${i}].ele`;
        rowCopyABS = rowCopyABS + `[${i}].ele`;
      }
      if (index <= splitIndex.length - 3) {
        cellCopy = cellCopy + `[${i}].ele`;
        cellCopyABS = cellCopyABS + `[${i}].ele`;
      }
      if (index <= splitIndex.length - 2) {
        paraCopy = paraCopy + `[${i}].ele`;
        paraCopyABS = paraCopyABS + `[${i}].ele`;
      }
      textCopy = textCopy + `[${i}].ele`;
      textCopyABS = textCopyABS + `[${i}].ele`;
    });
    tableText = tableText.slice(0, -4);
    rowText = rowText.slice(0, -4);
    // cellText = cellText.slice(0, -4);

    tableText = tableText + ".t";
    // cellText = cellText + ".t";

    tableCopy = tableCopy.slice(0, -4);
    // tableCopy = tableCopy + ".t";
    rowCopy = rowCopy.slice(0, -4);
    cellCopy = cellCopy.slice(0, -4);
    paraCopy = paraCopy.slice(0, -4);
    textCopy = textCopy.slice(0, -4);
    textCopy = textCopy + ".t";
    tableCopyABS = tableCopyABS.slice(0, -4);
    // tableCopyABS = tableCopyABS + ".t";
    rowCopyABS = rowCopyABS.slice(0, -4);
    cellCopyABS = cellCopyABS.slice(0, -4);
    paraCopyABS = paraCopyABS.slice(0, -4);
    textCopyABS = textCopyABS.slice(0, -4);
    textCopyABS = textCopyABS + ".t";
  }

  return (
    <div className="relative flex h-auto" key={`${index}`}>
      <span
        id={`${index}`}
        name={`${loc}`}
        style={textStyle}
        className={`relative cursor-pointer whitespace-pre-wrap ${
          typeof text?.t === "number" ? "text-blue-600" : ""
        }`}
        onClick={(e) => {
          if (dev) {
            toggleDropdown(index);
          }
          e.preventDefault();
          e.stopPropagation();

          // Bug here
          if (!dev) {
            dispatch(setIndexPath(`${loc}`));
            if (SIPHF === "0_H" || SIPHF === "0_F") {
              navigate(
                `${baseURL}/${loc}${
                  // check length for HF
                  splitLoc.length > 4
                    ? `/SP/HFP/TBP/TR/TC/PP/TP/${lastIndex}`
                    : `/SP/HFP/PP/TP/${lastIndex}`
                }`
              );
            } else {
              navigate(
                `${baseURL}/${loc}${
                  splitLoc.length > 4
                    ? `/SP/TBP/TR/TC/PP/TP/${lastIndex}`
                    : `/SP/PP/TP/${lastIndex}`
                }`
              );
            }
          }
        }}
      >
        {NIP_T === `${index}` && (SIPHF !== "0_H" || SIPHF !== "0_F") ? (
          <div
            className={`${
              NIP_T.startsWith(`${index}`) ? "flex" : "hidden"
            } absolute left-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50 z-30 text-xs`}
            onClick={(e) => {
              e.preventDefault();
              dispatch(removeEle(RRIP_T));
              let newRRIP = [...RRIP_T].slice(0, -1);
              if (newRRIP.length === 3) {
                navigate(`${baseURL}/${newRRIP.join("/")}/SP/PP`);
              } else {
                navigate(`${baseURL}/${newRRIP.join("/")}/SP/TBP/TR/TC/PP`);
              }
            }}
          >
            <IoCloseSharp />
          </div>
        ) : null}

        {typeof text?.t === "number" ? text?.t.toFixed(2) : text?.t}
        {dev && show[index] ? (
          <div
            className={`absolute left-0 mt-0 bg-white border rounded-md shadow-lg z-[200] font-mono text-xs ${
              show[index] ? "block" : "hidden"
            }`}
          >
            <ul className="py-1">
              <li
                className="px-4 py-1 hover:bg-gray-200"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(tableText);
                }}
              >
                {tableText}
              </li>
              <li
                className="px-4 py-1 hover:bg-gray-200"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(cellText);
                }}
              >
                {cellText}
              </li>
              <li
                className="px-4 py-1 hover:bg-gray-200"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(textCopyABS);
                }}
              >
                {textCopyABS}
              </li>
              {/* <li
                className="px-4 py-1 hover:bg-gray-200"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(paraCopyABS);
                }}
              >
                {paraCopyABS}
              </li>
              <li
                className="px-4 py-1 hover:bg-gray-200"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(cellCopyABS);
                }}
              >
                {cellCopyABS}
              </li>
              <li
                className="px-4 py-1 hover:bg-gray-200"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(rowCopyABS);
                }}
              >
                {rowCopyABS}
              </li> */}
              <li
                className="px-4 py-1 hover:bg-gray-200"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(tableCopyABS);
                }}
              >
                {tableCopyABS}
              </li>

              <li
                className="px-4 py-1 hover:bg-gray-200"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(textCopy);
                }}
              >
                {textCopy}
              </li>
              {/* <li
                className="px-4 py-1 hover:bg-gray-200"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(paraCopy);
                }}
              >
                {paraCopy}
              </li>
              <li
                className="px-4 py-1 hover:bg-gray-200"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(cellCopy);
                }}
              >
                {cellCopy}
              </li>
              <li
                className="px-4 py-1 hover:bg-gray-200"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(rowCopy);
                }}
              >
                {rowCopy}
              </li> */}
              <li
                className="px-4 py-1 hover:bg-gray-200"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(tableCopy);
                }}
              >
                {tableCopy}
              </li>

              {/* <li
                className="px-4 py-1 hover:bg-gray-200"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(rowText);
                }}
              >
                {rowText}
              </li> */}

              {/* <li
                className="px-4 py-1 hover:bg-gray-200"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(ele);
                }}
              >
                {ele}
              </li> */}
            </ul>
          </div>
        ) : null}
      </span>
    </div>
  );
};

export default CreateText;
