import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { returnHFEle } from "../../../../../slices/Docs/functions";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  switchShadeType,
  returnPropEle,
  returnPropHFEle,
} from "../CommFunctions";
import JsFunControls from "../Tools/jsFunControls";

import Alignment from "./components/alignment";
import Border from "./components/border";
import PageBreak from "./components/pageBreak";

import AddText from "./components/addText";
import AddImage from "./components/addImage";
import AddSymbol from "./components/addSymbol";
import AddCheckbox from "./components/addCheckbox";

import NumReference from "./components/NumReference";
import NumLevel from "./components/NumLevel";

import ShadeColor from "./components/ShadeColor";
import ShadeFill from "./components/ShadeFill";
import ShadeType from "./components/ShadeType";

import LineBreak from "./components/LineBreak";

import SpacingBefore from "./components/SpacingBefore";
import SpacingAfter from "./components/SpacingAfter";
import SpacingLine from "./components/SpacingLine";

import IndentLeft from "./components/IndentLeft";
import IndentRight from "./components/IndentRight";
import IndentFirstLine from "./components/IndentFirstLine";
import IndentHanging from "./components/IndentHanging";

const ParagraphProperties = () => {
  const {
    docs,
    indexPath,
    SIP,
    RRIP_P,
    RRIP_TC,
    RRIP_TR,
    RRIP_TB,
    RRIP_S,
    RRIP_DOC,
    NIP_P,
    dev,
    minimal,
  } = useSelector((state) => state.docs);
  const { paraName } = useSelector((state) => state.element);

  const [alignment, setAlignment] = useState();
  const [thematicBreak, setThematicBreak] = useState();
  const [pageBreak, setPageBreak] = useState();
  const [border, setBorder] = useState();
  const [shadeColor, setShadeColor] = useState();
  const [shadeFill, setShadeFill] = useState();
  const [shadeType, setShadeType] = useState();
  const [spacingBefore, setSpacingBefore] = useState();
  const [spacingAfter, setSpacingAfter] = useState();
  const [spacingLine, setSpacingLine] = useState();
  const [leftIndent, setLeftIndent] = useState(); // Example default value
  const [rightIndent, setRightIndent] = useState();
  const [firstLineIndent, setFirstLineIndent] = useState();
  const [hangingIndent, setHangingIndent] = useState();
  const [reference, setReference] = useState();
  const [level, setLevel] = useState();

  const [paraStatus, setParaStatus] = useState(dev ? "jsfun" : "properties");

  useEffect(() => {
    setParaStatus(dev ? "jsfun" : "properties");
  }, [indexPath, dev]);

  const { pathname } = useLocation();
  let splitLoc = pathname.split("/");
  if (splitLoc.length >= 4) {
    let length = splitLoc.length - 3;
    splitLoc.splice(3, length);
  }
  const baseURL = splitLoc.join("/");

  let SIPHF = SIP[2];

  let dynEle = "docs";
  NIP_P.split("_").forEach((item, i) => {
    dynEle =
      dynEle + `['${item}'].${i === NIP_P.split("_").length - 1 ? "" : "ele"}`;
  });

  dynEle = dynEle.slice(0, -1);

  const docIndex = parseFloat(SIP[0].split("_")[0]);

  useEffect(() => {
    let para;
    let cell;
    let row;
    let table;
    let sec;
    let doc;
    if (SIPHF === "0_H" || SIPHF === "0_F") {
      para = returnPropHFEle(docs, RRIP_P, SIPHF);
      cell = RRIP_TC ? returnPropHFEle(docs, RRIP_TC, SIPHF) : undefined;
      row = RRIP_TR ? returnPropHFEle(docs, RRIP_TR, SIPHF) : undefined;
      table = RRIP_TB ? returnPropHFEle(docs, RRIP_TB, SIPHF) : undefined;
    } else {
      para = returnPropEle(docs, RRIP_P);
      cell = RRIP_TC ? returnPropEle(docs, RRIP_TC) : undefined;
      row = RRIP_TR ? returnPropEle(docs, RRIP_TR) : undefined;
      table = RRIP_TB ? returnPropEle(docs, RRIP_TB) : undefined;
    }
    doc = returnPropEle(docs, RRIP_DOC);

    if (para) {
      if (para?.properties?.alignment) {
        setAlignment(para.properties.alignment);
      } else if (table !== undefined && table.properties.para.alignment) {
        setAlignment(table.properties.para.alignment);
      } else {
        setAlignment(doc.properties.para.alignment);
      }

      if (para?.properties?.thematicBreak) {
        setThematicBreak(para.properties.thematicBreak);
      } else {
        setThematicBreak();
      }
      if (para.properties.pageBreak) {
        setPageBreak(para.properties.pageBreak);
      } else {
        setPageBreak();
      }
      if (para.properties.border) {
        setBorder(para.properties.border);
      } else {
        setBorder();
      }
      if (para.properties.indent) {
        if (para.properties.indent.left) {
          setLeftIndent(para.properties.indent.left);
        } else {
          setLeftIndent();
        }
        if (para.properties.indent.right) {
          setRightIndent(para.properties.indent.right);
        } else {
          setRightIndent();
        }
        if (para.properties.indent.firstLine) {
          setFirstLineIndent(para.properties.indent.firstLine);
        } else {
          setFirstLineIndent();
        }
        if (para.properties.indent.hanging) {
          setHangingIndent(para.properties.indent.hanging);
        } else {
          setHangingIndent();
        }
      }

      if (para.properties.numbering?.reference) {
        setReference(para.properties.numbering?.reference);
      } else {
        setReference();
      }
      if (para.properties.numbering?.level !== undefined) {
        setLevel(para.properties.numbering?.level);
      } else {
        setLevel();
      }

      if (para.properties.shading) {
        setShadeColor(`#${para.properties.shading.color.toLowerCase()}`);
        setShadeFill(`#${para.properties.shading.fill.toLowerCase()}`);
        setShadeType(switchShadeType(para.properties.shading.type));
      }
      // console.log(table);
      if (para.properties.spacing) {
        setSpacingBefore(para.properties.spacing.before);
        setSpacingAfter(para.properties.spacing.after);
        setSpacingLine(para.properties.spacing.line);
      } else if (table?.properties?.para) {
        if (table.properties.para?.spacing) {
          setSpacingBefore(table.properties.para.spacing.before);
          setSpacingAfter(table.properties.para.spacing.after);
        } else {
          setSpacingBefore();
          setSpacingAfter();
        }
      } else if (table === undefined) {
        setSpacingBefore(doc.properties.para.spacing.before);
        setSpacingAfter(doc.properties.para.spacing.after);
      }
    }
  }, [docs, NIP_P, RRIP_P, RRIP_TC, RRIP_TB, RRIP_P, RRIP_DOC, RRIP_S]);

  let activeStyle = "bg-cyan-950 text-light-500";

  return (
    <section className="psm morphous bg-color">
      {!dev ? (
        <div className="mb-2">
          <Outlet />
        </div>
      ) : null}

      {paraStatus === "properties" ? (
        <>
          {/* Get Properties */}
          {/* <div className="flex gap-4 pb-4 bg-">
            <button className="bg-cyan-950 text-light-500 h-[40px] w-[100px]">
              Para Props
            </button>
            <button className="bg-cyan-950 text-light-500 h-[40px] w-[100px]">
              Text Props
            </button>
          </div> */}
          {/* Add Text or Image */}

          {/* background alignment and line break */}
          <h1 className="font-mono text-center pb-4">
            Paragraph Properties [{paraName}]
          </h1>
          <div className="flex gap-4 pb-4">
            <Alignment alignment={alignment} RRIP_P={RRIP_P} SIPHF={SIPHF} />
            <Border border={border} RRIP_P={RRIP_P} SIPHF={SIPHF} />
            <PageBreak pageBreak={pageBreak} RRIP_P={RRIP_P} SIPHF={SIPHF} />

            <AddText RRIP_P={RRIP_P} docIndex={docIndex} SIPHF={SIPHF} />
            <AddImage
              RRIP_P={RRIP_P}
              SIPHF={SIPHF}
              SIP={SIP}
              baseURL={baseURL}
            />
            <AddCheckbox RRIP_P={RRIP_P} SIPHF={SIPHF} />
          </div>
          <div>
            <AddSymbol RRIP_P={RRIP_P} SIPHF={SIPHF} />
          </div>
          {/* Numbering */}
          <div className="flex gap-4 pb-4">
            <NumReference reference={reference} />
            <NumLevel level={level} />
          </div>
          {/* Shading */}
          <div className={`${minimal ? "hidden" : "block"}`}>
            <div className="flex gap-4 pb-4">
              <ShadeColor shadeColor={shadeColor} SIPHF={SIPHF} />
              <ShadeFill shadeFill={shadeFill} SIPHF={SIPHF} />
              <ShadeType shadeType={shadeType} SIPHF={SIPHF} />

              <LineBreak thematicBreak={thematicBreak} SIPHF={SIPHF} />
            </div>
          </div>
          {/* Pagebreak border and spacing */}
          <div className={`${minimal ? "hidden" : "block"}`}>
            <div className="flex flex-wrap gap-4 pb-4">
              <SpacingBefore
                spacingBefore={spacingBefore}
                setSpacingBefore={(value) => setSpacingBefore(value)}
                SIPHF={SIPHF}
              />
              <SpacingAfter
                spacingAfter={spacingAfter}
                setSpacingAfter={(value) => setSpacingAfter(value)}
                SIPHF={SIPHF}
              />
              <SpacingLine
                spacingLine={spacingLine}
                setSpacingLine={(value) => setSpacingLine(value)}
                SIPHF={SIPHF}
              />
            </div>
          </div>
          {/* Indentation */}
          <div className={`${minimal ? "hidden" : "block"}`}>
            <div className="pb-4">
              <h1 className="pb-1">
                * Intentation won't work if left and firstLine are added
                together. *
              </h1>
              <div className="flex flex-wrap gap-4">
                <IndentLeft
                  leftIndent={leftIndent}
                  setLeftIndent={(value) => setLeftIndent(value)}
                  SIPHF={SIPHF}
                />
                <IndentRight
                  rightIndent={rightIndent}
                  setRightIndent={(value) => setRightIndent(value)}
                  SIPHF={SIPHF}
                />
                <IndentFirstLine
                  firstLineIndent={firstLineIndent}
                  setFirstLineIndent={(value) => setFirstLineIndent(value)}
                  SIPHF={SIPHF}
                />
                <IndentHanging
                  hangingIndent={hangingIndent}
                  setHangingIndent={(value) => setHangingIndent(value)}
                  SIPHF={SIPHF}
                />
              </div>
            </div>
          </div>
        </>
      ) : paraStatus === "jsfun" ? (
        <div className="flex flex-col">
          <JsFunControls path={RRIP_P} dynEle={dynEle} />
        </div>
      ) : (
        <></>
      )}
    </section>
  );
};

export default ParagraphProperties;
