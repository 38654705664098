import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSecBorderStyle } from "../../../../../../slices/docsSlice";
import Dropdown from "../../../../../../utils/Dropdown";

const PBRStyle = ({ pageBorderRightStyle }) => {
  const dispatch = useDispatch();
  const { RRIP_S } = useSelector((state) => state.docs);
  const { borderStyles } = useSelector((state) => state.options);
  return (
    <div>
      <h2 className="pb-1">PB Right style</h2>
      <Dropdown
        width={250}
        height={40}
        options={borderStyles}
        target="option"
        value={pageBorderRightStyle}
        click={(value) => {
          dispatch(
            setSecBorderStyle({
              loc: "pageBorderRight",
              section: RRIP_S,
              value,
            })
          );
        }}
      />
    </div>
  );
};

export default PBRStyle;
