import React, { useEffect, useState } from "react";
import Input from "../../../../utils/Input";
import { useDispatch, useSelector } from "react-redux";
import { updateFEF, updateNoOfRows } from "../../../../slices/entryForm";
import { updateFFEF, updateFNoOfRows } from "../../../../slices/formsSlice";

import MINPUT from "./multiInputs/INPUT";
import MTEXTAREA from "./multiInputs/TEXTAREA";
import MDROPDOWN from "./multiInputs/DROPDOWN";
import MDATE from "./multiInputs/DATE";
import NesMulti from "./nesMulti";

const FMulti = ({ ele, operator }) => {
  const { category, name } = useSelector((state) => state.docs);
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { filledEF } = useSelector((state) => state.entryForm);
  const initialRows =
    filledEF && filledEF[operator] && filledEF[operator][ele.cluster]
      ? filledEF[operator][ele.cluster].length
      : 1;
  const [rows, setRows] = useState(initialRows);
  const [arrStructure, setArrStructure] = useState([[]]);

  useEffect(() => {
    let arr = [];
    Array.from({ length: rows }).forEach((_, index) => {
      arr.push([]);
    });
    setArrStructure(arr);
  }, [rows]);

  return (
    <div className="w-full bg-red-200 rounded p-2">
      <h2 className="pb-1">No. of {ele.cluster}s</h2>
      <div className="pb-2">
        <Input
          type="number"
          value={rows}
          change={(e) => {
            if (parseFloat(e.target.value) > 0) {
              dispatch(
                updateNoOfRows({
                  operator,
                  cluster: ele.cluster,
                  rows: parseFloat(e.target.value),
                })
              );
              dispatch(
                updateFNoOfRows({
                  EF: `${category}$${name}_EF`,
                  operator,
                  cluster: ele.cluster,
                  rows: parseFloat(e.target.value),
                })
              );

              setRows(e.target.value);
            }
          }}
        />
      </div>
      <div className="flex flex-col gap-4">
        {arrStructure.map((ite, i) => {
          return (
            <div className="w-full flex flex-wrap gap-4 bg-blue-200 rounded p-2">
              {ele.row.map((item, j) => {
                switch (item.element) {
                  case "input":
                    return (
                      <MINPUT
                        cluster={ele.cluster}
                        ele={item}
                        operator={operator}
                        i={i}
                        j={j}
                      />
                    );
                  case "textarea":
                    return (
                      <MTEXTAREA
                        cluster={ele.cluster}
                        ele={item}
                        operator={operator}
                        i={i}
                        j={j}
                      />
                    );
                  case "dropdown":
                    return (
                      <MDROPDOWN
                        cluster={ele.cluster}
                        ele={item}
                        operator={operator}
                        i={i}
                        j={j}
                      />
                    );
                  case "date":
                    return (
                      <MDATE
                        cluster={ele.cluster}
                        ele={item}
                        operator={operator}
                        i={i}
                        j={j}
                      />
                    );
                  case "multi":
                    return (
                      <NesMulti
                        cluster={ele.cluster}
                        ele={ele}
                        item={item}
                        operator={operator}
                        i={i}
                        j={j}
                      />
                    );
                }
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FMulti;
