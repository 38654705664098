import { TABLE, ROW, CELL, PARA, TEXT } from "../../../../slices/Docs/elements";

const textStructure = ({ V, TP }) => {
  let text = structuredClone(TEXT);
  if (TP) {
    text.properties = TP;
  }
  text.t = V;
  return text;
};

const paraStructure = ({ V, PP, TP }) => {
  let para = structuredClone(PARA);
  if (PP) {
    para.properties = PP;
  }
  switch (typeof V) {
    case "string":
      para.ele.push(textStructure({ V, TP }));
      break;
    case "number":
      para.ele.push(textStructure({ V, TP }));
      break;
    case "object":
      para.ele = V.map((R) => {
        return textStructure({ V: R.V, TP: R.TP });
      });
      break;
  }
  return para;
};

const cellStructure = ({ V, CP, PP, TP }) => {
  let cell = structuredClone(CELL);
  if (CP) {
    cell.properties = CP;
  }
  switch (typeof V) {
    case "string":
      cell.ele.push(paraStructure({ V, PP, TP }));
      break;
    case "number":
      cell.ele.push(paraStructure({ V, PP, TP }));
      break;
    case "object":
      cell.ele = V.map((R) => {
        return paraStructure({ V: R.V, PP: R.PP, TP: R.TP });
      });
      break;
  }
  return cell;
};

export const CC = ({ V, CP, PP, TP }) => {
  return cellStructure({ V, CP, PP, TP });
};

export const CR = (cells) => {
  try {
    let row = {
      ele: [],
      properties: {},
      name: "",
      type: "row",
    };
    cells.map(({ V, CP, PP, TP }, i) => {
      row.ele.push(cellStructure({ V, CP, PP, TP }));
    });
    return row;
  } catch (error) {
    console.error("Error in CR function:", error);
  }
};

export const CT = ({ V, TP }) => {
  return textStructure({ V, TP });
};

export const CP = ({ V, PP, TP }) => {
  return paraStructure({ V, PP, TP });
};

export const CTB = () => {
  let table = structuredClone(TABLE);
  return table;
};

export const NTW = (num) => {
  const units = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teens = [
    "",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const thousands = ["", "Thousand", "Lakh", "Crore"];

  if (num === 0) return "Zero Rupees";

  let [rupeePart, paisaPart] = num.toString().split(".");
  paisaPart = paisaPart ? parseInt(paisaPart.padEnd(2, "0").slice(0, 2)) : 0;

  let words = "";

  function convertToWords(n, suffix) {
    if (n === 0) return "";
    let str = "";

    if (n > 99) {
      str += units[Math.floor(n / 100)] + " Hundred ";
      n = n % 100;
    }

    if (n > 10 && n < 20) {
      str += teens[n - 10] + " ";
    } else {
      str += tens[Math.floor(n / 10)] + " ";
      str += units[n % 10] + " ";
    }

    return str + suffix + " ";
  }

  let crore = Math.floor(rupeePart / 10000000);
  words += convertToWords(crore, "Crore");
  rupeePart %= 10000000;

  let lakh = Math.floor(rupeePart / 100000);
  words += convertToWords(lakh, "Lakh");
  rupeePart %= 100000;

  let thousand = Math.floor(rupeePart / 1000);
  words += convertToWords(thousand, "Thousand");
  rupeePart %= 1000;

  let hundred = Math.floor(rupeePart / 100);
  words += convertToWords(hundred, "Hundred");
  rupeePart %= 100;

  if (rupeePart > 0) {
    if (words !== "") words += "and ";
    words += convertToWords(rupeePart, "");
  }

  words = words.trim() + " Only";

  if (paisaPart > 0) {
    words += " and " + convertToWords(paisaPart, "Paisa");
  }

  return words.trim();
};
