import React from "react";
import Input from "../../../../../../../utils/Input";
import { useDispatch, useSelector } from "react-redux";
import { setTableParaSpacing } from "../../../../../../../slices/docsSlice";

const SpacingAfter = ({ spacingAfter, setSpacingAfter, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TB, RRIP_DOC } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">Spacing After</h2>
      <Input
        className="h-[40px] w-[100px]"
        value={spacingAfter}
        blur={(e) =>
          dispatch(
            setTableParaSpacing({
              loc: "after",
              table: RRIP_TB,
              doc: RRIP_DOC,
              value: e.target.value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          )
        }
        change={(e) => setSpacingAfter(e.target.value)}
        type="text"
      />
    </div>
  );
};

export default SpacingAfter;
