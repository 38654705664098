import React, { useState, useEffect } from "react";
import MINPUT from "./multiInputs/INPUT";
import MTEXTAREA from "./multiInputs/TEXTAREA";
import MDATE from "./multiInputs/DATE";
import MDROPDOWN from "./multiInputs/DROPDOWN";
import Input from "../../../../utils/Input";
import { useDispatch, useSelector } from "react-redux";
import { updateNesNoOfRows } from "../../../../slices/entryForm";
import { updateNesFNoOfRows } from "../../../../slices/formsSlice";

const NesMulti = ({ cluster, ele, item, operator, i, j }) => {
  const { category, name } = useSelector((state) => state.docs);
  const dispatch = useDispatch();
  const { filledEF } = useSelector((state) => state.entryForm);

  const initialRows =
    filledEF &&
    filledEF[operator] &&
    filledEF[operator][ele.cluster] &&
    filledEF[operator][ele.cluster][i] &&
    filledEF[operator][ele.cluster][i][j]
      ? filledEF[operator][ele.cluster][i][j].length
      : 1;

  const [rows1, setRows1] = useState(initialRows);
  const [arrStructure1, setArrStructure1] = useState([[]]);

  useEffect(() => {
    let arr = [];
    Array.from({ length: rows1 }).forEach((_, index) => {
      arr.push([]);
    });
    setArrStructure1(arr);
  }, [rows1]);

  return (
    <div className="w-full bg-green-200 rounded p-2">
      <h2 className="pb-1">No. of {item.cluster}s</h2>
      <div className="pb-2">
        <Input
          type="number"
          value={rows1}
          change={(e) => {
            if (parseFloat(e.target.value) > 0) {
              dispatch(
                updateNesNoOfRows({
                  i,
                  j,
                  operator,
                  cluster,
                  rows: parseFloat(e.target.value),
                })
              );
              dispatch(
                updateNesFNoOfRows({
                  EF: `${category}$${name}_EF`,
                  i,
                  j,
                  operator,
                  cluster,
                  rows: parseFloat(e.target.value),
                })
              );
              setRows1(e.target.value);
            }
          }}
        />
      </div>
      <div className="flex flex-col gap-4">
        {arrStructure1.map((ite, k) => {
          return (
            <div className="w-fit flex flex-wrap gap-4 bg-yellow-200 rounded p-2">
              {item.row.map((nesItem, l) => {
                switch (nesItem.element) {
                  case "input":
                    return (
                      <MINPUT
                        cluster={cluster}
                        ele={nesItem}
                        operator={operator}
                        i={i}
                        j={j}
                        k={k}
                        l={l}
                      />
                    );
                  case "textarea":
                    return (
                      <MTEXTAREA
                        cluster={cluster}
                        ele={nesItem}
                        operator={operator}
                        i={i}
                        j={j}
                        k={k}
                        l={l}
                      />
                    );
                  case "dropdown":
                    return (
                      <MDROPDOWN
                        cluster={cluster}
                        ele={nesItem}
                        operator={operator}
                        i={i}
                        j={j}
                        k={k}
                        l={l}
                      />
                    );
                  case "date":
                    return (
                      <MDATE
                        cluster={cluster}
                        ele={nesItem}
                        operator={operator}
                        i={i}
                        j={j}
                        k={k}
                        l={l}
                      />
                    );
                  case "multi":
                    return;
                }
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NesMulti;
