import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Input from "../../../../../../../utils/Input";
import {
  addPara,
  addTable,
  replaceTable,
} from "../../../../../../../slices/docsSlice";
import { returnPropEle, returnPropHFEle } from "../../../CommFunctions";

import ShadeColor from "./components/ShadeColor";
import ShadeFill from "./components/ShadeFill";
import ShadeType from "./components/ShadeType";

import MarginLeft from "./components/MarginLeft";
import MarginRight from "./components/MarginRight";
import MarginTop from "./components/MarginTop";
import MarginBottom from "./components/MarginBottom";

import ColumnSpan from "./components/ColumnSpan";
import RowSpan from "./components/RowSpan";

import WidthType from "./components/WidthType";
import WidthSize from "./components/WidthSize";
import Border from "./components/Border";

const CellProperties = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    docs,
    indexPath,
    SIP,
    RRIP_TC,
    RRIP_TR,
    RRIP_TB,
    RRIP_DOC,
    NIP_TC,
    NIP_TR,
    NIP_TB,
    NIP_DOC,
    minimal,
  } = useSelector((state) => state.docs);
  const { cellName } = useSelector((state) => state.element);
  // const { productInfo } = useSelector((state) => state.product);
  const { shadingTypes, widthTypes, isTrueOrFalse } = useSelector(
    (state) => state.options
  );
  const [shadeColor, setShadeColor] = useState();
  const [shadeFill, setShadeFill] = useState();
  const [shadeType, setShadeType] = useState();
  const [marginTop, setMarginTop] = useState();
  const [marginBottom, setMarginBottom] = useState();
  const [marginLeft, setMarginLeft] = useState();
  const [marginRight, setMarginRight] = useState();
  const [verticalAlign, setVerticalAlign] = useState();
  const [widthType, setWidthType] = useState();
  const [widthSize, setWidthSize] = useState();
  const [columnSpan, setColumnSpan] = useState();
  const [rowSpan, setRowSpan] = useState();
  const [border, setBorder] = useState();

  const [rows, setRows] = useState();
  const [cols, setCols] = useState();

  let SIPHF = SIP[2];

  let NIP_TC_Arr = NIP_TC.split("_");

  useEffect(() => {
    let doc;
    let table;
    let row;
    let cell;
    if (SIPHF === "0_H" || SIPHF === "0_F") {
      table = RRIP_TB ? returnPropHFEle(docs, RRIP_TB, SIPHF) : undefined;
      row = RRIP_TR ? returnPropHFEle(docs, RRIP_TR, SIPHF) : undefined;
      cell = RRIP_TC ? returnPropHFEle(docs, RRIP_TC, SIPHF) : undefined;
    } else {
      table = RRIP_TB ? returnPropEle(docs, RRIP_TB) : undefined;
      row = RRIP_TR ? returnPropEle(docs, RRIP_TR) : undefined;
      cell = RRIP_TC ? returnPropEle(docs, RRIP_TC) : undefined;
    }
    doc = returnPropEle(docs, RRIP_DOC);

    if (cell) {
      if (cell.properties.shading) {
        setShadeColor(`#${cell.properties.shading.color.toLowerCase()}`);
        setShadeFill(`#${cell.properties.shading.fill.toLowerCase()}`);
        setShadeType(cell.properties.shading.type);
      }

      if (cell.properties.margins) {
        setMarginLeft(cell.properties.margins.left);
        setMarginRight(cell.properties.margins.right);
        setMarginTop(cell.properties.margins.top);
        setMarginBottom(cell.properties.margins.bottom);
      } else if (table && table.properties.margins) {
        setMarginLeft(table.properties.margins.left);
        setMarginRight(table.properties.margins.right);
        setMarginTop(table.properties.margins.top);
        setMarginBottom(table.properties.margins.bottom);
      } else if (doc.properties.cell.margins) {
        setMarginLeft(doc.properties.cell.margins.left);
        setMarginRight(doc.properties.cell.margins.right);
        setMarginTop(doc.properties.cell.margins.top);
        setMarginBottom(doc.properties.cell.margins.bottom);
      } else {
        setMarginLeft(doc.properties.table.margins.left);
        setMarginRight(doc.properties.table.margins.right);
        setMarginTop(doc.properties.table.margins.top);
        setMarginBottom(doc.properties.table.margins.bottom);
      }

      if (cell.properties.verticalAlign) {
        setVerticalAlign(cell.properties.verticalAlign);
      } else {
        setVerticalAlign(doc.properties.cell.verticalAlign);
      }

      if (cell.properties.borders !== undefined) {
        setBorder(cell.properties.borders);
      } else if (table?.properties?.borders !== undefined) {
        setBorder(table.properties.borders);
      } else if (doc.properties.table.borders !== undefined) {
        setBorder(true);
      } else {
        setBorder(false);
      }

      if (cell.properties.width) {
        setWidthType(cell.properties.width.type);
        setWidthSize(cell.properties.width.size);
      } else if (row.properties.width) {
        setWidthType(row.properties.width.type);
        setWidthSize(row.properties.width.size);
      } else {
        setWidthType("");
        setWidthSize("");
      }

      if (cell.properties.columnSpan) {
        setColumnSpan(cell.properties.columnSpan);
      } else {
        setColumnSpan();
      }
      if (cell.properties.rowSpan) {
        setRowSpan(cell.properties.rowSpan);
      } else {
        setRowSpan();
      }
    }
  }, [docs, NIP_TC, NIP_TR, NIP_TB, NIP_DOC]);

  return (
    <div className="psm morphous bg-gray-100">
      {/* </RetractableDiv> */}
      <div className="mb-2">
        <Outlet />
      </div>
      <h1 className="font-mono text-center pb-4">
        Cell Properties [{cellName}]
        {/* [{`${eval(dynName)}_${NIP_TC_Arr[NIP_TC_Arr.length - 1]}`}] */}
      </h1>
      {/* <RetractableDiv
        show={false}
        name="Cell Properties"
        highLight={`${eval(dynName)}_${NIP_TC_Arr[NIP_TC_Arr.length - 1]}`}
      > */}
      <div className={`${minimal ? "hidden" : "block"}`}>
        <div className="flex gap-4 pb-4">
          <ShadeColor shadeColor={shadeColor} SIPHF={SIPHF} />
          <ShadeFill shadeFill={shadeFill} SIPHF={SIPHF} />
          <ShadeType shadeType={shadeType} SIPHF={SIPHF} />
        </div>
        <div className="flex gap-4 pb-4">
          <MarginLeft
            marginLeft={marginLeft}
            setMarginLeft={(value) => setMarginLeft(value)}
            SIPHF={SIPHF}
          />
          <MarginRight
            marginRight={marginRight}
            setMarginRight={(value) => setMarginRight(value)}
            SIPHF={SIPHF}
          />
          <MarginTop
            marginTop={marginTop}
            setMarginTop={(value) => setMarginTop(value)}
            SIPHF={SIPHF}
          />
          <MarginBottom
            marginBottom={marginBottom}
            setMarginBottom={(value) => setMarginBottom(value)}
            SIPHF={SIPHF}
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="flex gap-4">
          <ColumnSpan
            columnSpan={columnSpan}
            setColumnSpan={(value) => setColumnSpan(value)}
            SIPHF={SIPHF}
          />
          <RowSpan
            rowSpan={rowSpan}
            setRowSpan={(value) => setRowSpan(value)}
            SIPHF={SIPHF}
          />
        </div>
        <WidthType widthType={widthType} SIPHF={SIPHF} />
        <WidthSize
          widthSize={widthSize}
          setWidthSize={(value) => setWidthSize(value)}
          SIPHF={SIPHF}
        />
        <Border border={border} SIPHF={SIPHF} />
      </div>

      <div className="pb-4">
        <h2 className="pb-1">Add Para or Table</h2>
        <div className="flex gap-4">
          <button
            className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
            onClick={() => {
              dispatch(
                addPara({
                  path: RRIP_TC,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
            // disabled={!eleName}
          >
            Para
          </button>
          <div>
            <Input
              className="h-[40px] w-[100px]"
              value={rows}
              change={(e) => setRows(e.target.value)}
              placeholder="Rows"
              type="number"
            />
          </div>

          <div>
            <Input
              className="h-[40px] w-[100px]"
              value={cols}
              change={(e) => setCols(e.target.value)}
              placeholder="Cols"
              type="number"
            />
          </div>

          <button
            className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
            onClick={() => {
              dispatch(
                replaceTable({
                  path: RRIP_TC,
                  rows: parseFloat(rows),
                  cols: parseFloat(cols),
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
            // disabled={!eleName}
          >
            Replace Table
          </button>

          <button
            className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
            onClick={() => {
              dispatch(
                addTable({
                  path: RRIP_TC,
                  rows: parseFloat(rows),
                  cols: parseFloat(cols),
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
            // disabled={!eleName}
          >
            Table
          </button>
        </div>
      </div>
    </div>
  );
};

export default CellProperties;
