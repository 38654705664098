import React from "react";
import Dropdown from "../../../../../../utils/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import { updateShading } from "../../../../../../slices/docsSlice";

const ShadeType = ({ shadeType, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_P } = useSelector((state) => state.docs);
  const { shadingTypes } = useSelector((state) => state.options);
  return (
    <div>
      <h2 className="pb-1">Shade Type</h2>
      <Dropdown
        placeholder="Shade Type"
        value={shadeType}
        options={shadingTypes}
        name="Shade Type"
        target="option"
        width={200}
        height={40}
        click={(value) => {
          dispatch(
            updateShading({
              loc: "type",
              path: RRIP_P,
              value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
      />
    </div>
  );
};

export default ShadeType;
