import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSecType } from "../../../../../../slices/docsSlice";
import Dropdown from "../../../../../../utils/Dropdown";

const SectionType = ({ type }) => {
  const dispatch = useDispatch();
  const { RRIP_S, minimal } = useSelector((state) => state.docs);
  const { sectionTypes } = useSelector((state) => state.options);
  return (
    <div className={`${minimal ? "hidden" : "block"}`}>
      <h2 className="pb-1">Type</h2>
      <Dropdown
        width={150}
        height={40}
        options={sectionTypes}
        target="option"
        value={type}
        click={(value) => {
          dispatch(
            setSecType({
              section: RRIP_S,
              value,
            })
          );
        }}
        style={type ? { backgroundColor: "#083344", color: "#f8f9fa" } : {}}
      />
    </div>
  );
};

export default SectionType;
