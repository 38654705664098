import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Dropdown from "../../../../../../utils/Dropdown";
import Input from "../../../../../../utils/Input";
import RetractableDiv from "../../../../../../components/RetractableDiv";
import {
  setRowHeight,
  setRowCellWidth,
} from "../../../../../../slices/docsSlice";
import {
  returnPropEle,
  returnPropHFEle,
  switchWidthType,
} from "../../CommFunctions";

const RowProperties = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    docs,
    indexPath,
    SIP,
    RRIP_TR,
    RRIP_TB,
    RRIP_DOC,
    NIP_TR,
    NIP_TB,
    NIP_DOC,
    minimal,
  } = useSelector((state) => state.docs);
  const { rowName } = useSelector((state) => state.element);
  const { rowHeightRules, widthTypes } = useSelector((state) => state.options);

  // Row Properties
  const [heightValue, setHeightValue] = useState();
  const [heightRule, setHeightRule] = useState();

  // Cell Properties
  const [widthType, setWidthType] = useState();
  const [widthSize, setWidthSize] = useState();

  let SIPHF = SIP[2];

  const { pathname } = useLocation();
  let splitLoc = pathname.split("/");
  if (splitLoc.length >= 4) {
    let length = splitLoc.length - 3;
    splitLoc.splice(3, length);
  }
  const baseURL = splitLoc.join("/");

  let newIndexPathArr = NIP_TR.split("_");

  useEffect(() => {
    const doc = returnPropEle(docs, RRIP_DOC);
    let row;
    if (SIPHF === "0_H" || SIPHF === "0_F") {
      row = RRIP_TR ? returnPropHFEle(docs, RRIP_TR, SIPHF) : undefined;
    } else {
      row = RRIP_TR ? returnPropEle(docs, RRIP_TR) : undefined;
    }
    if (row) {
      if (row.properties?.height) {
        setHeightRule(row.properties.height.rule);
        setHeightValue(row.properties.height.value);
      } else if (doc.properties.row.height) {
        setHeightRule(doc.properties.row.height.rule);
        setHeightValue(doc.properties.row.height.value);
      } else {
        setHeightRule("");
        setHeightValue("");
      }
      if (row.properties?.cell?.width) {
        setWidthType(row.properties.cell.width.type);
        setWidthSize(row.properties.cell.width.size);
      }
    }
  }, [docs, RRIP_TR]);

  return (
    <div className="psm morphous bg-color">
      <div className="mb-2">
        <Outlet />
      </div>
      <div>
        <h1 className="font-mono text-center pb-4">
          Row Properties [{rowName}]
        </h1>
        <div className="pb-4">
          <div className={`${minimal ? "hidden" : "block"}`}>
            <h1 className="pb-1 font-mono">
              Height Units [mm, cm, in, pt, pc, pi]
            </h1>
          </div>
          <div className="flex flex-wrap gap-x-5 gap-y-3 mt-1">
            <div className="mb-2">
              <p className="mb-2">Height</p>
              <Input
                className="h-[40px] w-[150px]"
                value={heightValue}
                type="text"
                blur={(e) => {
                  dispatch(
                    setRowHeight({
                      loc: "value",
                      row: RRIP_TR,
                      value: e.target.value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
                change={(e) => setHeightValue(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <p className="mb-2">Height Rule</p>
              <Dropdown
                width={150}
                height={40}
                value={heightRule}
                options={rowHeightRules}
                target="option"
                name="RHR"
                placeholder="height rule"
                click={(value) => {
                  dispatch(
                    setRowHeight({
                      loc: "rule",
                      row: RRIP_TR,
                      value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`${minimal ? "hidden" : "block"}`}>
        <h1 className="mb-1 bg-yellow-500 pl-2">Cell Properties</h1>
        <div className="pb-4">
          <h1 className="pb-1 font-mono">
            Width Units [mm, 55.87cm (MAX), in, pt, pc, pi, %]
          </h1>
          <div className="flex gap-4">
            {/* <p className="mb-2">Set Default Cell Width</p> */}
            <div className="flex flex-wrap gap-x-5 gap-y-3 mt-1">
              <div>
                <h2 className="pb-1">Set Width Type</h2>
                <Dropdown
                  value={widthType}
                  options={widthTypes}
                  target="option"
                  placeholder="width type"
                  name="WT"
                  width={150}
                  height={40}
                  click={(value) => {
                    dispatch(
                      setRowCellWidth({
                        loc: "type",
                        row: RRIP_TR,
                        value,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    );
                  }}
                />
              </div>
              <div>
                <h2 className="pb-1">Set Width Size</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  value={widthSize}
                  type="text"
                  blur={(e) => {
                    dispatch(
                      setRowCellWidth({
                        loc: "size",
                        row: RRIP_TR,
                        value: e.target.value,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    );
                  }}
                  change={(e) => setWidthSize(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RowProperties;
