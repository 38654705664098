import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Loader from "../../components/Loader";
import Input from "../../utils/Input";
import Table from "../../utils/Table/Table";
import TableRow from "../../utils/Table/TableRow";
import TableCell from "../../utils/Table/TableCell";

import {
  useCreateUserMutation,
  useGetAgencyUsersQuery,
  usePermissionAcceptMutation,
  useUpdateByAuthorityMutation,
  useDeleteUserMutation,
} from "../../slices/userApiSlice";
import { useGetPasswordRequestsQuery } from "../../slices/passwordApiSlice";
import { setUser } from "../../slices/userSlice";
import Pagination from "../../utils/Table/Pagination";
// import { updatePasswordRequests } from "../../slices/authSlice";

const Managers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");

  const [isUpdate, setIsUpdate] = useState(false);

  const { userInfo } = useSelector((state) => state.auth);
  const { pageNumber } = useParams();

  const [createUser, { isLoading }] = useCreateUserMutation();
  const [deleteUser, { isLoading: deleteUserLoading }] =
    useDeleteUserMutation();

  const {
    data: users,
    refetch: refetchUsers,
    isLoading: usersLoading,
  } = useGetAgencyUsersQuery({ pageNumber });

  const [permissionAccept, { isPermissionAccept }] =
    usePermissionAcceptMutation();

  const {
    data: passwordRequests,
    refetch: refetchPassReq,
    isLoading: passReqLoading,
  } = useGetPasswordRequestsQuery();

  const [updateByAuthority, { isLoading: byAuthorityLoading }] =
    useUpdateByAuthorityMutation();

  // const {
  //   data: passwordList,
  //   refetch: refreshPasswordRefetch,
  //   isLoading: refreshPasswordLoading,
  // } = useRefreshPasswordListQuery();

  // useEffect(() => {
  //   if (passwordList !== undefined) {
  //     dispatch(updatePasswordRequests(passwordList));
  //   }
  // }, [passwordList]);

  const permissionAcceptHandler = async (email) => {
    try {
      const res = await permissionAccept({ email }).unwrap();
      if (res.status) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const submitHandler = async () => {
    if (!isUpdate) {
      try {
        const res = await createUser({
          name,
          email,
          phoneNo,
          password,
        }).unwrap();
        toast.success(res.message);
        setName("");
        setEmail("");
        setPassword("");
        setPhoneNo("");
        refetchUsers();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    } else {
      try {
        const res = await updateByAuthority({
          email,
          password,
        }).unwrap();
        toast.success(res.message);
        setName("");
        setEmail("");
        setPhoneNo("");
        setPassword("");
        refetchPassReq();
        setIsUpdate(false);
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const deleteUserHandler = async (userId, userName) => {
    if (window.confirm("Are you sure you want to delete user " + userName)) {
      try {
        const res = await deleteUser(userId).unwrap();
        toast.success(res.message);
        refetchUsers();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <div className="psm flex gap-2">
      <div className="wh-80">
        <h2 className="font-mono pb-2">Managers</h2>
        {usersLoading ? (
          <Loader />
        ) : (
          <>
            <Table>
              <TableRow isHeader={true}>
                <TableCell isHeader={true}>S.No</TableCell>
                <TableCell isHeader={true}>Name</TableCell>
                <TableCell isHeader={true}>Email</TableCell>
                <TableCell isHeader={true}>isPermitted</TableCell>
                <TableCell isHeader={true}>Phone No</TableCell>
                <TableCell isHeader={true}>Password Request</TableCell>
                <TableCell isHeader={true} className="w-[200px]">
                  Assign Documents
                </TableCell>
                <TableCell isHeader={true} className={`w-[44px] text-center`}>
                  X
                </TableCell>
              </TableRow>
              {users?.users?.map((user, sno) => {
                return (
                  <TableRow
                    className={(sno + 1) % 2 === 0 ? "bg-gray-100" : null}
                  >
                    <TableCell>{sno + 1}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>

                    <TableCell
                      className={`cursor-pointer ${
                        user.isPermitted ? "bg-success-500" : "bg-danger-500"
                      }`}
                      click={async () => {
                        try {
                          await permissionAcceptHandler(user.email);
                          refetchUsers();
                        } catch (err) {
                          toast.error(err?.data?.message || err.error);
                        }
                      }}
                    >
                      {user.isPermitted ? "Permitted" : "Not Permitted"}
                    </TableCell>
                    <TableCell>{user.phoneNo}</TableCell>
                    {passReqLoading ? (
                      <Loader />
                    ) : (
                      <TableCell
                        className={
                          passwordRequests?.some(
                            (request) => request.email === user.email
                          )
                            ? "bg-warning-500 cursor-pointer"
                            : null
                        }
                        click={() => {
                          setName(user.name);
                          setEmail(user.email);
                          setPhoneNo(user.phoneNo);
                          setIsUpdate(!isUpdate);
                        }}
                      >
                        {passwordRequests?.some(
                          (request) => request.email === user.email
                        )
                          ? "Select user"
                          : null}
                      </TableCell>
                    )}
                    <TableCell
                      className="cursor-pointer bg-cyan-950 text-light-500 text-center"
                      click={() => {
                        dispatch(setUser(user));
                        navigate("/manager/Assign");
                      }}
                    >
                      Assign
                    </TableCell>
                    <TableCell
                      className={`cursor-pointer bg-danger-500 font-bold text-light-500 text-center`}
                      click={() => deleteUserHandler(user._id, user.name)}
                    >
                      X
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
            {users ? (
              <Pagination
                currentPage={users.page}
                totalPages={users.pages}
                onPageChange={(value) => {
                  navigate(`/manager/users/${value}`);
                }}
              />
            ) : null}
          </>
        )}
      </div>
      <div className="wh-20">
        <h2 className="font-mono mb-3">
          {isUpdate ? "Update" : "Create"} User
        </h2>
        <div className="wh-100 sm:w-[350px]">
          <div className="mb-5">
            <Input
              id="name"
              value={name}
              placeholder="Enter your name"
              type="text"
              change={(e) => setName(e.target.value)}
              disabled={isUpdate ? true : false}
            />
          </div>
          <div className="mb-5">
            <Input
              id="email"
              value={email}
              placeholder="Enter your email"
              type="email"
              change={(e) => setEmail(e.target.value)}
              disabled={isUpdate ? true : false}
            />
          </div>
          <div className="mb-5">
            <Input
              id="phone no"
              value={phoneNo}
              placeholder="Enter your phone no"
              type="text"
              change={(e) => setPhoneNo(e.target.value)}
            />
          </div>
          <div className="mb-5">
            <Input
              id="password"
              value={password}
              placeholder="Enter your password"
              type="text"
              change={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-5 flex items-center gap-4">
            <button
              className="px-5 py-3 bg-cyan-950 text-light-500 font-mono rounded"
              onClick={() => submitHandler()}
            >
              {isUpdate ? "Update" : "Create"} User
            </button>
            {/* {byAuthorityLoading && <Loader />} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Managers;
