import React, { useState, useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";
import Input from "../../../../utils/Input";
import { useDispatch } from "react-redux";
import {
  removeUserInput,
  updateUserCondition,
  updateDefaultValue,
} from "../../../../slices/entryForm";

import CodeMirror from "@uiw/react-codemirror";
import { autocompletion } from "@codemirror/autocomplete";
import { javascript } from "@codemirror/lang-javascript";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";

const CDate = ({ opInput, operator }) => {
  const [dv, setDv] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setDv(opInput.condition);
  }, [opInput]);

  return (
    <div className="flex flex-col gap-2 bg-green-300 p-2 rounded w-full relative">
      <div
        className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
        onClick={() =>
          dispatch(
            removeUserInput({
              name: opInput.placeholder,
              operator: operator,
            })
          )
        }
      >
        <IoCloseSharp />
      </div>
      <div>
        <CodeMirror
          className="text-sm"
          value={dv}
          theme={vscodeDark}
          extensions={[javascript({ jsx: true }), autocompletion()]}
          onChange={(value) => {
            setDv(value);
          }}
          onBlur={() => {
            dispatch(
              updateUserCondition({
                operator: operator,
                name: opInput.placeholder,
                condition: dv,
              })
            );
          }}
        />
        {/* <h2 className="pb-1">Condition</h2>
        <Input
          type="text"
          className="w-full"
          value={dv}
          change={(e) => setDv(e.target.value)}
          blur={(e) => {
            dispatch(
              updateUserCondition({
                operator: operator,
                name: opInput.placeholder,
                condition: e.target.value,
              })
            );
          }}
        /> */}
      </div>
      <div className="flex flex-wrap items-center gap-4">
        <div>
          <h2 className="pb-1">
            {opInput.placeholder} ({opInput.element})
          </h2>
          <Input
            type="date"
            className="h-[40px]"
            placeholder="default value"
            value={opInput.defaultValue}
            change={(e) => {
              dispatch(
                updateDefaultValue({
                  name: opInput.placeholder,
                  operator: operator,
                  value: e.target.value,
                })
              );
            }}
          />
        </div>
        <div>
          <h2 className="pb-1">Reset Date</h2>
          <button
            className="w-[100px] h-[40px] bg-cyan-950 text-light-500"
            onClick={() => {
              dispatch(
                updateDefaultValue({
                  name: opInput.placeholder,
                  operator: operator,
                  value: "",
                })
              );
            }}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default CDate;
