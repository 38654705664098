import React from "react";
import Input from "../../../../../../../utils/Input";
import { useDispatch, useSelector } from "react-redux";
import { setTableParaSpacing } from "../../../../../../../slices/docsSlice";

const SpacingBefore = ({ spacingBefore, setSpacingBefore, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TB, RRIP_DOC } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">Spacing Before</h2>
      <Input
        className="h-[40px] w-[100px]"
        value={spacingBefore}
        blur={(e) =>
          dispatch(
            setTableParaSpacing({
              loc: "before",
              table: RRIP_TB,
              doc: RRIP_DOC,
              value: e.target.value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          )
        }
        change={(e) => setSpacingBefore(e.target.value)}
        type="text"
      />
    </div>
  );
};

export default SpacingBefore;
