import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSecMargin } from "../../../../../../slices/docsSlice";
import Input from "../../../../../../utils/Input";

const MarginRight = ({ marginRight, setMarginRight }) => {
  const dispatch = useDispatch();
  const { RRIP_S } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">M Right</h2>
      <Input
        className="h-[40px] w-[150px]"
        value={marginRight}
        blur={(e) =>
          dispatch(
            setSecMargin({
              section: RRIP_S,
              loc: "right",
              value: e.target.value,
            })
          )
        }
        change={(e) => setMarginRight(e.target.value)}
        type="text"
      />
    </div>
  );
};

export default MarginRight;
