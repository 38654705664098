import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sideBar: true,
  functionBar: false,
};

const utilSlice = createSlice({
  name: "util",
  initialState,
  reducers: {
    toggleSidebar: (state, { payload }) => {
      state.sideBar = !state.sideBar;
    },
    toggleFunctionBar: (state, { payload }) => {
      state.functionBar = !state.functionBar;
    },
  },
});

export const { toggleSidebar, toggleFunctionBar } = utilSlice.actions;

export default utilSlice.reducer;
