import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import {
  addUserInput,
  updateInputType,
  updateInputLength,
  updateInputCaps,
  updateInputUnit,
  updateDefaultValue,
  removeUserInput,
} from "../../../../slices/entryForm";
import Input from "../../../../utils/Input";
import Dropdown from "../../../../utils/Dropdown";
import TextArea from "../../../../utils/TextArea";
import Button from "../../../../utils/Button";

const EFMulti = ({ index, opInput, operator }) => {
  const dispatch = useDispatch();

  const handleAddInput = (element) => {
    const inputKey = prompt("Enter Input Key: ");
    dispatch(
      addUserInput({
        cluster: opInput.cluster,
        name: inputKey,
        operator: operator,
        element: element,
      })
    );
  };

  const handleAddMultipleInputs = () => {
    const inputKey = prompt("Enter Input Key: ");
    let noOfVal = prompt("No. of Inputs");
    let valArr = [];
    for (let i = 0; i < noOfVal; i++) {
      let val = prompt(`Input ${i + 1}`);
      valArr.push(val);
    }
    dispatch(
      addUserInput({
        cluster: opInput.cluster,
        name: inputKey,
        operator: operator,
        element: "dropdown",
        options: valArr,
      })
    );
  };

  const nestedAddInput = (element, nesCluster, index) => {
    // console.log(nesCluster);
    const inputKey = prompt("Enter Input Key: ");
    dispatch(
      addUserInput({
        cluster: opInput.cluster,
        nesCluster: nesCluster.cluster,
        index,
        name: inputKey,
        operator: operator,
        element: element,
      })
    );
  };

  const nestedAddMultipleInputs = (nesCluster, index) => {
    const inputKey = prompt("Enter Input Key: ");
    let noOfVal = prompt("No. of Inputs");
    let valArr = [];
    for (let i = 0; i < noOfVal; i++) {
      let val = prompt(`Input ${i + 1}`);
      valArr.push(val);
    }
    dispatch(
      addUserInput({
        cluster: opInput.cluster,
        nesCluster: nesCluster.cluster,
        index,
        name: inputKey,
        operator: operator,
        element: "dropdown",
        options: valArr,
      })
    );
  };

  // console.log(opInput);

  return (
    <div className="bg-purple-200 p-2 rounded flex flex-col gap-2 relative border border-4 border-light-100">
      <div
        className="flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50"
        onClick={() =>
          dispatch(
            removeUserInput({
              name: opInput.cluster,
              operator: operator,
            })
          )
        }
      >
        <IoCloseSharp />
      </div>
      <h2 className="pb-1">
        {opInput.cluster} ({opInput.element})
      </h2>
      <div className="flex gap-4 flex-wrap">
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => handleAddInput("input")}
        >
          Add Input
        </button>
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => handleAddInput("textarea")}
        >
          Add Text Area
        </button>
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={handleAddMultipleInputs}
        >
          Add Drop Down
        </button>
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => handleAddInput("date")}
        >
          Add Date Picker
        </button>
        {index.split("_").length === 1 ? (
          <button
            className="bg-cyan-950 text-light-500 px-4 py-2"
            onClick={() => handleAddInput("multi")}
          >
            Add Multiple Inputs
          </button>
        ) : null}
      </div>
      <div className="flex flex-wrap gap-4">
        {opInput.row.map((item, i) => {
          const opMulInput = item;
          switch (opMulInput.element) {
            case "input":
              return (
                <div
                  key={i}
                  className="flex flex-col flex-wrap items-start gap-2 bg-red-200 p-2 rounded relative"
                >
                  <div className="flex gap-2">
                    <div
                      className="flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50"
                      onClick={() =>
                        dispatch(
                          removeUserInput({
                            index: i,
                            cluster: opInput.cluster,
                            operator: operator,
                          })
                        )
                      }
                    >
                      <IoCloseSharp />
                    </div>
                    <div>
                      <h2 className="pb-1">
                        {opMulInput.placeholder} ({opMulInput.element})
                      </h2>
                      <Input
                        className="h-[40px]"
                        type="text"
                        placeholder="default value"
                        value={opMulInput.defaultValue}
                        change={(e) =>
                          dispatch(
                            updateDefaultValue({
                              index: i,
                              cluster: opInput.cluster,
                              operator: operator,
                              value: e.target.value,
                            })
                          )
                        }
                      />
                    </div>
                    <div>
                      <h2 className="pb-1">Unit</h2>
                      <Dropdown
                        name="Unit"
                        width={150}
                        height={40}
                        value={opMulInput.unit}
                        options={["none", "₹", "L", "Cr", "%"]}
                        target="option"
                        click={(value) => {
                          dispatch(
                            updateInputUnit({
                              index: i,
                              cluster: opInput.cluster,
                              operator: operator,
                              value: value,
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex gap-2 items-end">
                    <div>
                      <h2 className="pb-1">Type</h2>
                      <Dropdown
                        name="Type"
                        width={150}
                        height={40}
                        value={opMulInput.type}
                        options={["text", "number"]}
                        target="option"
                        click={(value) =>
                          dispatch(
                            updateInputType({
                              index: i,
                              cluster: opInput.cluster,
                              operator,
                              value: value,
                            })
                          )
                        }
                      />
                    </div>
                    <div>
                      <h2 className="pb-1">Length</h2>
                      <Input
                        className="w-[80px] h-[40px]"
                        type="number"
                        value={opMulInput.maxLength ? opMulInput.maxLength : ""}
                        change={(e) =>
                          dispatch(
                            updateInputLength({
                              index: i,
                              cluster: opInput.cluster,
                              operator,
                              value: e.target.value,
                            })
                          )
                        }
                      />
                    </div>
                    <div>
                      <Button
                        name="All Caps"
                        value={opMulInput.caps}
                        click={(value) => {
                          dispatch(
                            updateInputCaps({
                              index: i,
                              cluster: opInput.cluster,
                              operator,
                              value: value,
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            case "textarea":
              return (
                <div
                  key={i}
                  className="flex flex-wrap items-center gap-4 bg-blue-200 p-2 rounded relative"
                >
                  <div
                    className="flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50"
                    onClick={() =>
                      dispatch(
                        removeUserInput({
                          index: i,
                          cluster: opInput.cluster,
                          operator: operator,
                        })
                      )
                    }
                  >
                    <IoCloseSharp />
                  </div>
                  <div>
                    <h2 className="pb-1">
                      {opMulInput.placeholder} ({opMulInput.element})
                    </h2>
                    <TextArea
                      className="h-[100px]"
                      type="text"
                      placeholder="default value"
                      value={opMulInput.defaultValue}
                      change={(e) => {
                        let value = e.target.value;
                        if (opMulInput.caps) {
                          value = value.toUpperCase();
                        }
                        dispatch(
                          updateDefaultValue({
                            index: i,
                            cluster: opInput.cluster,
                            operator: operator,
                            value: value,
                          })
                        );
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      name="All Caps"
                      value={opMulInput.caps}
                      click={(value) => {
                        dispatch(
                          updateInputCaps({
                            index: i,
                            cluster: opInput.cluster,
                            operator: operator,
                            value: value,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              );
            case "dropdown":
              return (
                <div
                  key={i}
                  className="flex flex-wrap items-center gap-4 bg-yellow-200 p-2 rounded relative"
                >
                  <div
                    className="flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50"
                    onClick={() =>
                      dispatch(
                        removeUserInput({
                          index: i,
                          cluster: opInput.cluster,
                          operator: operator,
                        })
                      )
                    }
                  >
                    <IoCloseSharp />
                  </div>
                  <div>
                    <h2 className="pb-1">
                      {opMulInput.placeholder} ({opMulInput.element})
                    </h2>
                    <Input
                      type="text"
                      placeholder="default value"
                      value={opMulInput.defaultValue}
                      disabled
                    />
                  </div>
                  <div>
                    <h2 className="pb-1">Change Default Value</h2>
                    <Dropdown
                      name="Default Value"
                      width={200}
                      height={40}
                      value={opMulInput.defaultValue}
                      options={opMulInput.options}
                      target="option"
                      click={(value) =>
                        dispatch(
                          updateDefaultValue({
                            index: i,
                            cluster: opInput.cluster,
                            operator: operator,
                            value: value,
                          })
                        )
                      }
                    />
                  </div>
                </div>
              );
            case "date":
              const [dd, mm, yy] = opMulInput.defaultValue.split("-");
              let inputDate = `${yy}-${mm}-${dd}`;
              return (
                <div
                  key={i}
                  className="flex flex-wrap items-center gap-4 bg-green-200 p-2 rounded relative"
                >
                  <div
                    className="flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50"
                    onClick={() =>
                      dispatch(
                        removeUserInput({
                          index: i,
                          cluster: opInput.cluster,
                          operator: operator,
                        })
                      )
                    }
                  >
                    <IoCloseSharp />
                  </div>
                  <div>
                    <h2 className="pb-1">
                      {opMulInput.placeholder} ({opMulInput.element})
                    </h2>
                    <Input
                      type="date"
                      className="h-[40px]"
                      placeholder="default value"
                      value={inputDate}
                      change={(e) => {
                        const [year, month, day] = e.target.value.split("-");
                        let date = `${day}-${month}-${year}`;
                        dispatch(
                          updateDefaultValue({
                            index: i,
                            cluster: opInput.cluster,
                            operator: operator,
                            value: date,
                          })
                        );
                      }}
                    />
                  </div>
                  <div>
                    <h2 className="pb-1">Reset Date</h2>
                    <button
                      className="w-[100px] h-[40px] bg-cyan-950 text-light-500"
                      onClick={() => {
                        dispatch(
                          updateDefaultValue({
                            index: i,
                            cluster: opInput.cluster,
                            operator: operator,
                            value: "",
                          })
                        );
                      }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              );
            case "multi":
              // console.log(i);
              return (
                <div className="bg-orange-200 p-2 rounded flex flex-col gap-2 relative">
                  <div
                    className="flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50"
                    onClick={() =>
                      dispatch(
                        removeUserInput({
                          cluster: opInput.cluster,
                          operator: operator,
                          index: i,
                        })
                      )
                    }
                  >
                    <IoCloseSharp />
                  </div>
                  <h2 className="pb-1">
                    {opMulInput.cluster} ({opMulInput.element})
                  </h2>
                  <div className="flex gap-4 flex-wrap">
                    <button
                      className="bg-cyan-950 text-light-500 px-4 py-2"
                      onClick={() => nestedAddInput("input", opMulInput, i)}
                    >
                      Add Input
                    </button>
                    <button
                      className="bg-cyan-950 text-light-500 px-4 py-2"
                      onClick={() => nestedAddInput("textarea", opMulInput, i)}
                    >
                      Add Text Area
                    </button>
                    <button
                      className="bg-cyan-950 text-light-500 px-4 py-2"
                      onClick={() => nestedAddMultipleInputs(opMulInput, i)}
                    >
                      Add Drop Down
                    </button>
                    <button
                      className="bg-cyan-950 text-light-500 px-4 py-2"
                      onClick={() => nestedAddInput("date", opMulInput, i)}
                    >
                      Add Date Picker
                    </button>
                  </div>
                  <div className="flex flex-wrap gap-4">
                    {opMulInput.row.map((mulItem, j) => {
                      switch (mulItem.element) {
                        case "input":
                          return (
                            <div
                              key={`${i}_${j}`}
                              className="flex flex-col flex-wrap items-start gap-2 bg-red-200 p-2 rounded relative"
                            >
                              <div className="flex gap-2">
                                <div
                                  className="flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50"
                                  onClick={() =>
                                    dispatch(
                                      removeUserInput({
                                        index: i,
                                        index1: j,
                                        cluster: opInput.cluster,
                                        operator: operator,
                                      })
                                    )
                                  }
                                >
                                  <IoCloseSharp />
                                </div>
                                <div>
                                  <h2 className="pb-1">
                                    {mulItem.placeholder} ({mulItem.element})
                                  </h2>
                                  <Input
                                    className="h-[40px]"
                                    type="text"
                                    placeholder="default value"
                                    value={mulItem.defaultValue}
                                    change={(e) =>
                                      dispatch(
                                        updateDefaultValue({
                                          index: i,
                                          index1: j,
                                          cluster: opInput.cluster,
                                          operator: operator,
                                          value: e.target.value,
                                        })
                                      )
                                    }
                                  />
                                </div>
                                <div>
                                  <h2 className="pb-1">Unit</h2>
                                  <Dropdown
                                    name="Unit"
                                    width={150}
                                    height={40}
                                    value={mulItem.unit}
                                    options={["none", "₹", "L", "Cr", "%"]}
                                    target="option"
                                    click={(value) => {
                                      dispatch(
                                        updateInputUnit({
                                          index: i,
                                          index1: j,
                                          cluster: opInput.cluster,
                                          operator: operator,
                                          value: value,
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-2 items-end">
                                <div>
                                  <h2 className="pb-1">Type</h2>
                                  <Dropdown
                                    name="Type"
                                    width={200}
                                    height={40}
                                    value={mulItem.type}
                                    options={["text", "number"]}
                                    target="option"
                                    click={(value) =>
                                      dispatch(
                                        updateInputType({
                                          index: i,
                                          index1: j,
                                          cluster: opInput.cluster,
                                          operator,
                                          value: value,
                                        })
                                      )
                                    }
                                  />
                                </div>
                                <div>
                                  <h2 className="pb-1">Length</h2>
                                  <Input
                                    className="w-[100px] h-[40px]"
                                    type="number"
                                    value={
                                      mulItem.maxLength ? mulItem.maxLength : ""
                                    }
                                    change={(e) =>
                                      dispatch(
                                        updateInputLength({
                                          index: i,
                                          index1: j,
                                          cluster: opInput.cluster,
                                          operator,
                                          value: e.target.value,
                                        })
                                      )
                                    }
                                  />
                                </div>
                                <div>
                                  <Button
                                    name="All Caps"
                                    value={mulItem.caps}
                                    click={(value) => {
                                      dispatch(
                                        updateInputCaps({
                                          index: i,
                                          index1: j,
                                          cluster: opInput.cluster,
                                          operator,
                                          value: value,
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        case "textarea":
                          return (
                            <div
                              key={`${i}_${j}`}
                              className="flex flex-wrap items-center gap-4 bg-blue-200 p-2 rounded relative"
                            >
                              <div
                                className="flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50"
                                onClick={() =>
                                  dispatch(
                                    removeUserInput({
                                      index: i,
                                      index1: j,
                                      cluster: opInput.cluster,
                                      operator: operator,
                                    })
                                  )
                                }
                              >
                                <IoCloseSharp />
                              </div>
                              <div>
                                <h2 className="pb-1">
                                  {mulItem.placeholder} ({mulItem.element})
                                </h2>
                                <TextArea
                                  className="h-[100px]"
                                  type="text"
                                  placeholder="default value"
                                  value={mulItem.defaultValue}
                                  change={(e) => {
                                    let value = e.target.value;
                                    if (mulItem.caps) {
                                      value = value.toUpperCase();
                                    }
                                    dispatch(
                                      updateDefaultValue({
                                        index: i,
                                        index1: j,
                                        cluster: opInput.cluster,
                                        operator: operator,
                                        value: value,
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div>
                                <Button
                                  name="All Caps"
                                  value={mulItem.caps}
                                  click={(value) => {
                                    dispatch(
                                      updateInputCaps({
                                        index: i,
                                        index1: j,
                                        cluster: opInput.cluster,
                                        operator: operator,
                                        value: value,
                                      })
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          );
                        case "dropdown":
                          return (
                            <div
                              key={`${i}_${j}`}
                              className="flex flex-wrap items-center gap-4 bg-yellow-200 p-2 rounded relative"
                            >
                              <div
                                className="flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50"
                                onClick={() =>
                                  dispatch(
                                    removeUserInput({
                                      index: i,
                                      index1: j,
                                      cluster: opInput.cluster,
                                      operator: operator,
                                    })
                                  )
                                }
                              >
                                <IoCloseSharp />
                              </div>
                              <div>
                                <h2 className="pb-1">
                                  {mulItem.placeholder} ({mulItem.element})
                                </h2>
                                <Input
                                  type="text"
                                  placeholder="default value"
                                  value={mulItem.defaultValue}
                                  disabled
                                />
                              </div>
                              <div>
                                <h2 className="pb-1">Change Default Value</h2>
                                <Dropdown
                                  name="Default Value"
                                  width={200}
                                  height={40}
                                  value={mulItem.defaultValue}
                                  options={mulItem.options}
                                  target="option"
                                  click={(value) =>
                                    dispatch(
                                      updateDefaultValue({
                                        index: i,
                                        index1: j,
                                        cluster: opInput.cluster,
                                        operator: operator,
                                        value: value,
                                      })
                                    )
                                  }
                                />
                              </div>
                            </div>
                          );
                        case "date":
                          const [dd, mm, yy] = mulItem.defaultValue.split("-");
                          let inputDate = `${yy}-${mm}-${dd}`;
                          return (
                            <div
                              key={`${i}_${j}`}
                              className="flex flex-wrap items-center gap-4 bg-green-200 p-2 rounded relative"
                            >
                              <div
                                className="flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50"
                                onClick={() =>
                                  dispatch(
                                    removeUserInput({
                                      index: i,
                                      index1: j,
                                      cluster: opInput.cluster,
                                      operator: operator,
                                    })
                                  )
                                }
                              >
                                <IoCloseSharp />
                              </div>
                              <div>
                                <h2 className="pb-1">
                                  {mulItem.placeholder} ({mulItem.element})
                                </h2>
                                <Input
                                  type="date"
                                  className="h-[40px]"
                                  placeholder="default value"
                                  value={inputDate}
                                  change={(e) => {
                                    const [year, month, day] =
                                      e.target.value.split("-");
                                    let date = `${day}-${month}-${year}`;
                                    dispatch(
                                      updateDefaultValue({
                                        index: i,
                                        index1: j,
                                        cluster: opInput.cluster,
                                        operator: operator,
                                        value: date,
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div>
                                <h2 className="pb-1">Reset Date</h2>
                                <button
                                  className="w-[100px] h-[40px] bg-cyan-950 text-light-500"
                                  onClick={() => {
                                    dispatch(
                                      updateDefaultValue({
                                        index: i,
                                        index1: j,
                                        cluster: opInput.cluster,
                                        operator: operator,
                                        value: "",
                                      })
                                    );
                                  }}
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          );
                      }
                    })}
                  </div>
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
};

export default EFMulti;
