import React from "react";
import Input from "../../../../../../utils/Input";
import { useDispatch, useSelector } from "react-redux";
import { updateParaIndent } from "../../../../../../slices/docsSlice";

const IndentFirstLine = ({ firstLineIndent, setFirstLineIndent, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_P } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">Indent FirstLine</h2>
      <Input
        className="h-[40px] w-[150px]"
        type="text"
        value={firstLineIndent}
        blur={(e) =>
          dispatch(
            updateParaIndent({
              loc: "firstLine",
              value: e.target.value,
              para: RRIP_P,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          )
        }
        change={(e) => setFirstLineIndent(e.target.value)}
      />
    </div>
  );
};

export default IndentFirstLine;
