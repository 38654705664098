import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCellBorder } from "../../../../../../../../slices/docsSlice";
import Button from "../../../../../../../../utils/Button";

const Border = ({ border, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TC } = useSelector((state) => state.docs);
  return (
    <div className="min-w-[50px]">
      <h2 className="pb-1">Border</h2>
      <Button
        name="Border"
        value={border}
        click={() => {
          dispatch(
            updateCellBorder({
              cell: RRIP_TC,
              value: border,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
      />
    </div>
  );
};

export default Border;
