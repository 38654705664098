import React from "react";
import Dropdown from "../../../../../../utils/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { setParaNumbering } from "../../../../../../slices/docsSlice";

const NumLevel = ({ level }) => {
  const dispatch = useDispatch();
  const { RRIP_P } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">Level 0 to 9</h2>
      <Dropdown
        width={150}
        height={40}
        name="level"
        options={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
        value={level}
        target="option"
        click={(value) => {
          dispatch(
            setParaNumbering({
              loc: "level",
              para: RRIP_P,
              value: value,
            })
          );
        }}
      />
    </div>
  );
};

export default NumLevel;
