import React from "react";
import Input from "../../../../../../utils/Input";
import { useSelector, useDispatch } from "react-redux";
import { updateShading } from "../../../../../../slices/docsSlice";

const ShadeFill = ({ shadeFill, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_P } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">Text Color</h2>
      <div className="flex items-center gap-2">
        <Input
          className="h-[40px] w-[100px] p-0 shadow-none"
          value={shadeFill}
          blur={(e) => {
            dispatch(
              updateShading({
                loc: "fill",
                path: RRIP_P,
                value: e.target.value,
                layoutEle:
                  SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
              })
            );
          }}
          // change={(e) => setShadeFill(e.target.value)}
          type="color"
        />
      </div>
    </div>
  );
};

export default ShadeFill;
