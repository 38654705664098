import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSecBorderColor } from "../../../../../../slices/docsSlice";
import Input from "../../../../../../utils/Input";

const PBTColor = ({ pageBorderTopColor }) => {
  const dispatch = useDispatch();
  const { RRIP_S } = useSelector((state) => state.docs);
  return (
    <div>
      <h2 className="pb-1">PB Top color</h2>
      <Input
        className="h-[40px] w-[100px] p-0 shadow-none"
        value={pageBorderTopColor}
        blur={(e) => {
          dispatch(
            setSecBorderColor({
              loc: "pageBorderTop",
              section: RRIP_S,
              value: e.target.value,
            })
          );
        }}
        // change={(e) => setPageBorderTopColor(e.target.value)}
        type="color"
      />
    </div>
  );
};

export default PBTColor;
