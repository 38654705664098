import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import produceClone from "../../../../components/Lodash"; // DO NOT REMOVE
import { setDocs, setRunStatus } from "../../../../slices/docsSlice";
import {
  setFormsRunStatus,
  setFormDocument,
  setSingleForm,
} from "../../../../slices/formsSlice";
import _ from "lodash";
import { useGetBenchmarksQuery } from "../../../../externalDependency/LanceTechServices/slices/benchmarkApiSlice";
import { setBenchmark } from "../../../../externalDependency/LanceTechServices/slices/benchmark";

import { CC, CR, CT, CP, CTB, NTW } from "./Functions";
import { ROW } from "../../../../slices/Docs/elements";

const RunFunctions = () => {
  const dispatch = useDispatch();
  const { docs, name, category, runStatus, SIP } = useSelector(
    (state) => state.docs
  );
  const { filledEF } = useSelector((state) => state.entryForm);
  const { userForms, forms } = useSelector((state) => state.forms);
  const { internalDependency } = useSelector(
    (state) => state.internalDependency
  );

  const { data: benchmarkData, refetch, isLoading } = useGetBenchmarksQuery();

  useEffect(() => {
    if (benchmarkData) {
      dispatch(setBenchmark(benchmarkData[0]?.benchmark));
      dispatch(
        setSingleForm({
          name: "Benchmark",
          value: benchmarkData[0]?.benchmark,
        })
      );
    }
  }, [benchmarkData]);

  // Global Functions
  window["GF"] = {
    num: (val) => {
      const num = parseFloat(val);
      if (isNaN(num) || !isFinite(num)) {
        return 0;
      }
      return num;
    },
    dn: (val) => {
      return parseFloat(val).toFixed(2);
    },
    tc: (val) => {
      if (typeof val !== "number") {
        return 0;
      } else if (isNaN(val)) {
        return 0;
      } else if (val === Infinity) {
        return 0;
      }
      return val;
    },
    RE: ({ E, V }) => {
      E["ele"] = V.ele;
      E["properties"] = V.properties;
    },
    CD: () => {
      let d = new Date();
      let day = d.getDate().toString().padStart(2, "0");
      let month = (d.getMonth() + 1).toString().padStart(2, "0");
      let year = d.getFullYear();

      let CD = `${day}-${month}-${year}`;
      return CD;
    },
    PER: (val) => {
      val = val.replace("%", "");
      return parseFloat(val) / 100;
    },
    CT,
    CP,
    CC,
    CR,
    CTB,
    NTW,
    ROW,
  };

  // window["num"] = (val) => {
  //   const num = parseFloat(val);
  //   if (isNaN(num) || !isFinite(num)) {
  //     return 0;
  //   }
  //   return num;
  // };

  // window["dn"] = (val) => {
  //   return parseFloat(val).toFixed(2);
  // };

  // window["tc"] = (val) => {
  //   if (typeof val !== "number") {
  //     return 0;
  //   } else if (isNaN(val)) {
  //     return 0;
  //   } else if (val === Infinity) {
  //     return 0;
  //   }
  //   return val;
  // };

  // window["RE"] = ({ E, V }) => {
  //   E["ele"] = V.ele;
  //   E["properties"] = V.properties;
  // };

  // window["CD"] = () => {
  //   let d = new Date();
  //   let day = d.getDate().toString().padStart(2, "0");
  //   let month = (d.getMonth() + 1).toString().padStart(2, "0");
  //   let year = d.getFullYear();

  //   let CD = `${day}-${month}-${year}`;
  //   return CD;
  // };

  // window["CT"] = CT;
  // window["CP"] = CP;
  // window["CC"] = CC;
  // window["CR"] = CR;
  // window["CTB"] = CTB;
  // window["NTW"] = NTW;

  let newForms = produceClone(forms);
  Object.keys(newForms).forEach((form) => {
    if (!form.includes("$")) {
      if (!window["GF"]) {
        window["GF"] = {
          [form]: newForms[form],
        };
      }
      window["GF"][form] = newForms[form];
    } else {
      window[form] = newForms[form];
    }
  });

  const generateReport = (SIP) => {
    let NIP;
    if (SIP && SIP.length === 3) {
      NIP = SIP.map((index) => index.split("_")[0]).join("_");
    }

    let CES = produceClone(docs);

    outerLoop: for (let i1 = 0; i1 < CES.length; i1++) {
      let doc = CES[i1];
      if (!doc.bulkFiles) {
        for (let i2 = 0; i2 < doc.ele.length; i2++) {
          let sec = doc.ele[i2];
          for (let i3 = 0; i3 < sec.ele.length; i3++) {
            let E = sec.ele[i3];
            let USER = filledEF.user;
            let MANAGER = filledEF.manager;

            if (E.jsFun) {
              // Don't remove this console.log
              console.log(`${i1}_${i2}_${i3}`);
              let func = eval("(" + E.jsFun + ")");
              func(E, E.ele, USER, MANAGER, {
                path: `CES[${i1}].ele[${i2}].ele[${i3}]`,
              });
            }

            if (NIP === `${i1}_${i2}_${i3}`) {
              break outerLoop;
            }
          }
        }
      } else {
        let func = eval("(" + doc.jsFun + ")");
        func(doc);
      }
    }

    dispatch(setDocs(CES));
    dispatch(
      setFormDocument({
        name: `${category}$${name}`,
        value: CES,
      })
    );
  };

  // console.log(forms);

  return (
    <div className="flex items-center gap-4">
      <button
        className="bg-cyan-950 text-light-500 px-2 py-1"
        onClick={() => {
          internalDependency.map((doc, i) => {
            if (category === doc.category && name === doc.name) {
              dispatch(
                setFormsRunStatus({
                  name: `${category}$${name}`,
                  value: true,
                })
              );
              dispatch(setRunStatus(true));
            }
          });
          generateReport(SIP);
        }}
      >
        Run Index
      </button>
      <button
        className="bg-cyan-950 text-light-500 px-2 py-1"
        onClick={() => {
          internalDependency.map((doc, i) => {
            if (category === doc.category && name === doc.name) {
              dispatch(
                setFormsRunStatus({
                  name: `${category}$${name}`,
                  value: true,
                })
              );
              dispatch(setRunStatus(true));
            }
          });
          generateReport();
        }}
      >
        Run
      </button>

      {runStatus ? (
        <button
          className="bg-warning-500 text-black px-2 py-1"
          onClick={() => {
            internalDependency.map((doc, i) => {
              if (category === doc.category && name === doc.name) {
                dispatch(
                  setFormsRunStatus({
                    name: `${category}$${name}`,
                    value: false,
                  })
                );
                dispatch(setRunStatus(false));

                let newDoc = produceClone(doc.document);
                newDoc.map((d) => {
                  if (d.F) {
                    Object.keys(d.F).map((f) => {
                      d.F[f] = eval("(" + d.F[f] + ")");
                    });
                  }
                });

                dispatch(setDocs(newDoc));
                dispatch(
                  setFormDocument({
                    name: `${category}$${name}`,
                    value: doc.document,
                  })
                );
              }
            });
          }}
        >
          Clear
        </button>
      ) : null}
    </div>
  );
};

export default RunFunctions;
