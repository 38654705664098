import React from "react";
import Dropdown from "../../../../../../utils/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { setParaNumbering } from "../../../../../../slices/docsSlice";
import { useGetNumberingQuery } from "../../../../../../slices/numberingApiSlice";

const NumReference = ({ reference }) => {
  const dispatch = useDispatch();
  const { RRIP_P } = useSelector((state) => state.docs);
  const { data: numberingTypes, refetch, isLoading } = useGetNumberingQuery();

  return (
    <div>
      <h2 className="pb-1">Select Reference</h2>
      <Dropdown
        height={40}
        options={numberingTypes}
        name="Reference"
        value={reference}
        target="option.reference"
        click={(value) => {
          dispatch(
            setParaNumbering({
              loc: "reference",
              para: RRIP_P,
              value: value.reference,
            })
          );
        }}
        style={
          reference !== "" && reference !== undefined
            ? { backgroundColor: "#035afc", color: "white" }
            : null
        }
      />
    </div>
  );
};

export default NumReference;
